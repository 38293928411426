import { Link } from "react-router-dom";
import avatar from "../images/avatar1Kolet.png";

export const Header = () => {
  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-US", {
    month: "long", // Output full month name
    year: "numeric", // Output numeric year
  });
  return (
    <>
      {/* BIG SCREENS FROM 1280PX */}
      <div className=" 100:hidden xl:flex w-full h-[131px] py-[35px] gap-[40px] justify-end items-center">
        <div className=" flex items-center justify-between gap-[10px]">
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 2V3.5M4.5 2V3.5" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.99662 10.25H9.00338M8.99662 13.25H9.00338M11.9933 10.25H12M6 10.25H6.00673M6 13.25H6.00673" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.625 6.5H15.375" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M1.875 9.6824C1.875 6.41446 1.875 4.78046 2.81409 3.76523C3.75318 2.75 5.26462 2.75 8.2875 2.75H9.7125C12.7354 2.75 14.2469 2.75 15.1859 3.76523C16.125 4.78046 16.125 6.41446 16.125 9.6824V10.0676C16.125 13.3356 16.125 14.9695 15.1859 15.9848C14.2469 17 12.7354 17 9.7125 17H8.2875C5.26462 17 3.75318 17 2.81409 15.9848C1.875 14.9695 1.875 13.3356 1.875 10.0676V9.6824Z"
              stroke="#333333"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M2.25 6.5H15.75" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <h3 className=" text-[16px] tracking-[0.9px] font-montserrat text-[#333] font-normal ">{formattedDate}</h3>
        </div>
        <div className=" flex justify-between gap-[20px] items-center">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.52992 15.2696C2.31727 16.6636 3.268 17.6312 4.43205 18.1134C8.89481 19.9622 15.1052 19.9622 19.5679 18.1134C20.732 17.6312 21.6827 16.6636 21.4701 15.2696C21.3394 14.4129 20.6932 13.6995 20.2144 13.0029C19.5873 12.0793 19.525 11.0718 19.5249 10C19.5249 5.85786 16.1559 2.5 12 2.5C7.84413 2.5 4.47513 5.85786 4.47513 10C4.47503 11.0718 4.41272 12.0793 3.78561 13.0029C3.30684 13.6995 2.66061 14.4129 2.52992 15.2696Z"
              stroke="#333333"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M8 19.5C8.45849 21.2252 10.0755 22.5 12 22.5C13.9245 22.5 15.5415 21.2252 16 19.5" stroke="#333333" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <circle cx="18.2" cy="6.09998" r="5" fill="#E52C2C" />
          </svg>
          <Link to="/profileuser">
            <div className="w-fit h-[61px] rounded-[100px] border-[0.7px] pr-[30px] pl-[15px] gap-[10px] border-solid border-[#BDC3C7] flex items-center ">
              <img src={avatar} alt="User" />
              <div className="flex flex-col items-start gap-[5px]">
                <h3 className="text-[18px] text-[#333] font-raleway font-semibold tracking-[0.9px]">Candy Obi</h3>
                <h5 className=" font-montserrat text-[#999] font-normal text-[12px] tracking-[0.9px]">johndoe@gmail.com</h5>
              </div>
            </div>
          </Link>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1279PX */}
      <div className="100:flex xl:hidden  w-full h-[131px] py-[35px] gap-[20px] flex justify-end items-center">
        <div className=" flex items-center justify-between gap-[10px]">
          <svg width="14" height="15" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 2V3.5M4.5 2V3.5" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.99662 10.25H9.00338M8.99662 13.25H9.00338M11.9933 10.25H12M6 10.25H6.00673M6 13.25H6.00673" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.625 6.5H15.375" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M1.875 9.6824C1.875 6.41446 1.875 4.78046 2.81409 3.76523C3.75318 2.75 5.26462 2.75 8.2875 2.75H9.7125C12.7354 2.75 14.2469 2.75 15.1859 3.76523C16.125 4.78046 16.125 6.41446 16.125 9.6824V10.0676C16.125 13.3356 16.125 14.9695 15.1859 15.9848C14.2469 17 12.7354 17 9.7125 17H8.2875C5.26462 17 3.75318 17 2.81409 15.9848C1.875 14.9695 1.875 13.3356 1.875 10.0676V9.6824Z"
              stroke="#333333"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M2.25 6.5H15.75" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <h3 className=" text-[12px] tracking-[0.9px] font-montserrat text-[#333] font-normal ">{formattedDate}</h3>
        </div>
        <div className=" flex justify-between gap-[20px] items-center">
          <svg width="22" height="23" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.52992 15.2696C2.31727 16.6636 3.268 17.6312 4.43205 18.1134C8.89481 19.9622 15.1052 19.9622 19.5679 18.1134C20.732 17.6312 21.6827 16.6636 21.4701 15.2696C21.3394 14.4129 20.6932 13.6995 20.2144 13.0029C19.5873 12.0793 19.525 11.0718 19.5249 10C19.5249 5.85786 16.1559 2.5 12 2.5C7.84413 2.5 4.47513 5.85786 4.47513 10C4.47503 11.0718 4.41272 12.0793 3.78561 13.0029C3.30684 13.6995 2.66061 14.4129 2.52992 15.2696Z"
              stroke="#333333"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M8 19.5C8.45849 21.2252 10.0755 22.5 12 22.5C13.9245 22.5 15.5415 21.2252 16 19.5" stroke="#333333" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <circle cx="18.2" cy="6.09998" r="5" fill="#E52C2C" />
          </svg>
          <Link to="/profileuser">
            <div className="w-[55px] h-[55px] rounded-[100px] border-[0.7px]  gap-[10px] border-solid border-[#BDC3C7] flex justify-center items-center ">
              <img className="w-[30px] h-[30px]" src={avatar} alt="User" />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};
