import SideBarUser from "./SideBarUser";
import { Link } from "react-router-dom";
import { Header } from "./Header";
import { AdminFooter } from "./AdminFooter";
import { DownloadPrompt } from "./DownloadPrompt";
import { useState } from "react";

export const DashboardUser = () => {
  const [modal, setModal] = useState(true);
  return (
    <>
      <div className=" flex  justify-between">
        <SideBarUser />
        {/* BIG SCREENS FROM 1280PX */}
        <div className=" 100:hidden   pl-[290px] pb-[50px] h-fit w-full  pr-[40px] xl:flex flex-col  justify-between">
          <Header />
          {/* Hero section */}
          <div className=" w-full mb-[25px] h-[200px] p-[45px] flex justify-between items-center bg-[#3498DB] bg-opacity-10 rounded-[20px] ">
            <div className="flex w-[55%] 2xl:w-[550px] flex-col gap-[10px] justify-between">
              <h1 className="text-[#3498DB] tracking-[0.9px] text-[36px] font-raleway font-medium">
                Hello <span className=" font-semibold">Candy!</span>
              </h1>
              <p className="text-[16px] leading-[30px] tracking-[0.9px] text-[#333] font-montserrat font-normal ">Effortlessly manage, track, and secure your transactions with Kolet Financial Hub. Your financial journey starts here.</p>
            </div>
            <button className="w-[252px] text-center h-[44px] rounded-[4px] bg-[#3498DB] text-[#fff] tracking-[0.9px] text-[18px] font-semibold font-raleway ">Make a Payment</button>
          </div>
          {/* Tables start here */}
          <div className="w-full  gap-[15px] flex flex-col justify-between">
            {/* Table 1 */}
            <div className=" w-full p-[20px] h-fit flex flex-col gap-[15px] rounded-[20px] bg-white ">
              <div className="flex items-center justify-between w-full">
                <h4 className=" font-raleway font-semibold text-[18px] text-[#333] tracking-[0.9px]  ">Recent Transactions</h4>
                <Link to="/transactionhistoryuser">
                  <h5 className=" font-raleway font-semibold text-[14px] text-[#3498DB] tracking-[0.9px]  ">View All</h5>
                </Link>
              </div>
              <table className=" w-full">
                <thead>
                  <tr className="  text-[14px] text-[#999] tracking-[0.9px] font-medium font-montserrat w-full h-[57px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>ID</th>
                    <th className="">Name</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th className=" ">Status</th>
                    <th className="">Reciept</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>01</th>
                    <th className="">Joe Biden</th>
                    <th>December 19, 2023</th>
                    <th>₦4000</th>
                    <th className="  items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#2ECC71] bg-opacity-10 bg-[#2ECC71] ">Successful</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]   gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>02</th>
                    <th className="">Amarachi Nkemdilichi</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#E74C3C] bg-opacity-10 bg-[#E74C3C] ">Canceled</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>03</th>
                    <th className="">Liji Joem</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#F39C12] bg-opacity-10 bg-[#F39C12] ">Pending</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* Table 2 & 3 Container */}
            <div className="flex justify-between items-center gap-[15px]">
              <div className=" w-full p-[20px] h-fit pb-[40px] flex flex-col gap-[20px] rounded-[20px] bg-white ">
                <div className="flex justify-between w-full">
                  <h4 className=" font-raleway font-semibold text-[18px] text-[#333] tracking-[0.9px]  ">Payment History</h4>
                  <div className="flex justify-between items-center gap-[10px]">
                    <h3 className=" font-montserrat font-normal text-[#999] tracking-[0.9px] text-[12px]">Sort:</h3>
                    <div>
                      <select className="w-[73px] text-[12px] tracking-[0.9px] font-montserrat font-normal text-[#999] outline-none rounded-[20px] pl-[10px] py-[5px] bg-[#F5F5F5] h-[25px]" name="calendar" id="calendar">
                        <option className=" border-b-[0.7px]" value="Year">
                          Year
                        </option>
                        <option value="Month">Month</option>
                        <option value="Week">Week</option>
                      </select>
                    </div>
                  </div>
                </div>
                <table className=" w-full">
                  <thead>
                    <tr className="  text-[14px] text-[#999] tracking-[0.9px] font-medium font-montserrat w-full h-[57px] border-b-[0.7px] border-b-[#BDC3C7] ">
                      <th className="">Name</th>
                      <th>Details</th>
                      <th>Total</th>
                      <th>Export</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                      <th>Shopy</th>
                      <th className="">Events & Foil</th>
                      <th>₦4000</th>{" "}
                      <th className="w-[100px] pl-[40px]">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.875 12C14.2543 12.3686 15.75 13.3498 15.75 13.875M15.75 13.875C15.75 14.4002 14.2543 15.3814 13.875 15.75M15.75 13.875H9.75" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M8.25 16.5H8.04547C5.59955 16.5 4.3766 16.5 3.5273 15.9016C3.28397 15.7302 3.06794 15.5269 2.88578 15.2978C2.25 14.4985 2.25 13.3474 2.25 11.0455V9.13635C2.25 6.91399 2.25 5.8028 2.60171 4.91531C3.16711 3.48856 4.36285 2.36316 5.87877 1.83101C6.82172 1.5 8.00235 1.5 10.3637 1.5C11.713 1.5 12.3876 1.5 12.9264 1.68915C13.7927 1.99324 14.4759 2.63633 14.799 3.4516C15 3.95874 15 4.59371 15 5.86364V9.75"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2.25 9C2.25 7.61933 3.36929 6.5 4.75 6.5C5.24934 6.5 5.83803 6.5875 6.32353 6.45741C6.75489 6.34182 7.09182 6.00489 7.20741 5.57352C7.3375 5.08803 7.25 4.49934 7.25 4C7.25 2.61929 8.36933 1.5 9.75 1.5"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </th>
                    </tr>
                    <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                      <th>Shopy</th>
                      <th className="">Events & Foil</th>
                      <th>₦4000</th>{" "}
                      <th className="w-[100px] pl-[40px]">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.875 12C14.2543 12.3686 15.75 13.3498 15.75 13.875M15.75 13.875C15.75 14.4002 14.2543 15.3814 13.875 15.75M15.75 13.875H9.75" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M8.25 16.5H8.04547C5.59955 16.5 4.3766 16.5 3.5273 15.9016C3.28397 15.7302 3.06794 15.5269 2.88578 15.2978C2.25 14.4985 2.25 13.3474 2.25 11.0455V9.13635C2.25 6.91399 2.25 5.8028 2.60171 4.91531C3.16711 3.48856 4.36285 2.36316 5.87877 1.83101C6.82172 1.5 8.00235 1.5 10.3637 1.5C11.713 1.5 12.3876 1.5 12.9264 1.68915C13.7927 1.99324 14.4759 2.63633 14.799 3.4516C15 3.95874 15 4.59371 15 5.86364V9.75"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2.25 9C2.25 7.61933 3.36929 6.5 4.75 6.5C5.24934 6.5 5.83803 6.5875 6.32353 6.45741C6.75489 6.34182 7.09182 6.00489 7.20741 5.57352C7.3375 5.08803 7.25 4.49934 7.25 4C7.25 2.61929 8.36933 1.5 9.75 1.5"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className=" w-full p-[20px] h-fit flex flex-col gap-[40px] rounded-[20px] bg-white ">
                <div className="flex justify-between w-full">
                  <h4 className=" font-raleway font-semibold text-[18px] text-[#333] tracking-[0.9px]  ">Reports</h4>
                  <Link to="/reportsuser">
                    <h5 className=" font-raleway font-semibold text-[14px] text-[#3498DB] tracking-[0.9px]  ">View All</h5>
                  </Link>
                </div>
                <div className="flex  gap-[10px] items-center">
                  <div className="flex text-[12px] text-[#999] font-montserrat font-medium tracking-[0.9px] flex-col items-start gap-[40px]">
                    <h4>Department Store</h4>
                    <h4>Grocries</h4>
                    <h4>Resturantes</h4>
                    <h4>Bills</h4>
                  </div>
                  <div className="flex text-[10px]  text-[#333] font-montserrat font-normal tracking-[0.9px] flex-col items-start gap-[40px]">
                    <div className="flex justify-between items-center gap-[10px]">
                      <div className=" rounded-r-[2px] h-[20px] w-[375px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                      <h4>₦50,000</h4>
                    </div>
                    <div className="flex justify-between items-center gap-[10px]">
                      <div className=" rounded-r-[2px] h-[20px] w-[200px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                      <h4>₦40,000</h4>
                    </div>
                    <div className="flex justify-between items-center gap-[10px]">
                      <div className=" rounded-r-[2px] h-[20px] w-[150px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                      <h4>₦30,000</h4>
                    </div>{" "}
                    <div className="flex justify-between items-center gap-[10px]">
                      <div className=" rounded-r-[2px] h-[20px] w-[75px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                      <h4>₦20,000</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
        {/* SMALL SCREENS FROM 100PX TO 1279PX */}
        <div className="100:flex xl:hidden  pl-[115px] pb-[50px] h-fit w-full  pr-[20px]  flex-col justify-between">
          <Header />
          {/* Hero Section */}
          <div className=" w-full mb-[25px] h-fit p-[30px] flex gap-[30px] flex-col justify-between  bg-[#3498DB] bg-opacity-10 rounded-[20px] ">
            <div className="flex w-full flex-col gap-[10px] justify-between">
              <h1 className="text-[#3498DB] whitespace-nowrap tracking-[0.9px] text-[30px] font-raleway font-medium">
                Hello <span className=" font-semibold">Candy!</span>
              </h1>
              <p className="text-[13px] leading-[30px] tracking-[0.9px] text-[#333] font-montserrat font-normal ">Effortlessly manage, track, and secure your transactions with Kolet Financial Hub. Your financial journey starts here.</p>
            </div>
            <button className="w-[180px] text-center h-[40px] rounded-[4px] bg-[#3498DB] text-[#fff] tracking-[0.9px] text-[16px] font-semibold font-raleway ">Make a Payment</button>
          </div>
          {/* Tables start here */}
          <div className="w-full  gap-[15px]  flex flex-col justify-between">
            {/* Table 1 */}
            <div className=" w-full p-[20px] overflow-x-scroll h-fit flex flex-col gap-[15px] rounded-[20px] bg-white ">
              <div className="flex flex-wrap items-center justify-between w-full">
                <h4 className=" font-raleway font-semibold text-[16px] text-[#333] tracking-[0.9px]  ">Recent Transactions</h4>
                <Link to="/transactionhistoryuser">
                  <h5 className=" font-raleway font-semibold text-[12px] text-[#3498DB] tracking-[0.9px]  ">View All</h5>
                </Link>
              </div>

              <table className=" w-[1061px] ">
                <thead>
                  <tr className="  text-[14px] text-[#999] tracking-[0.9px] font-medium font-montserrat w-full h-[57px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>ID</th>
                    <th className="">Name</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th className=" ">Status</th>
                    <th className="">Reciept</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>01</th>
                    <th className="">Joe Biden</th>
                    <th>December 19, 2023</th>
                    <th>₦4000</th>
                    <th className="  items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#2ECC71] bg-opacity-10 bg-[#2ECC71] ">Successful</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]   gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>02</th>
                    <th className="">Amarachi Nkemdilichi</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#E74C3C] bg-opacity-10 bg-[#E74C3C] ">Canceled</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>03</th>
                    <th className="">Liji Joem</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#F39C12] bg-opacity-10 bg-[#F39C12] ">Pending</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* Table 2 */}
            <div className=" w-full p-[20px] overflow-x-scroll h-fit flex flex-col gap-[15px] rounded-[20px] bg-white ">
              <div className="flex justify-between items-center w-full">
                <h4 className=" font-raleway font-semibold text-[16px] text-[#333] tracking-[0.9px]  ">Payment History</h4>
                {/* <div className="flex justify-between items-center gap-[10px]">
                  <h3 className=" font-montserrat font-normal text-[#999] tracking-[0.9px] text-[12px]">Sort:</h3> */}
                <div>
                  <select className="w-[73px] text-[12px] tracking-[0.9px] font-montserrat font-normal text-[#999] outline-none rounded-[20px] pl-[10px] py-[5px] bg-[#F5F5F5] h-[25px]" name="calendar" id="calendar">
                    <option className=" border-b-[0.7px]" value="Year">
                      Year
                    </option>
                    <option value="Month">Month</option>
                    <option value="Week">Week</option>
                  </select>
                </div>
                {/* </div> */}
              </div>
              <table className=" w-[503px] sm:w-full">
                <thead>
                  <tr className="  text-[14px] text-[#999] tracking-[0.9px] font-medium font-montserrat w-full h-[57px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th className="">Name</th>
                    <th>Details</th>
                    <th>Total</th>
                    <th>Export</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>Shopy</th>
                    <th className="">Events & Foil</th>
                    <th>₦4000</th>{" "}
                    <th className="w-[100px] pl-[40px]">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.875 12C14.2543 12.3686 15.75 13.3498 15.75 13.875M15.75 13.875C15.75 14.4002 14.2543 15.3814 13.875 15.75M15.75 13.875H9.75" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        <path
                          d="M8.25 16.5H8.04547C5.59955 16.5 4.3766 16.5 3.5273 15.9016C3.28397 15.7302 3.06794 15.5269 2.88578 15.2978C2.25 14.4985 2.25 13.3474 2.25 11.0455V9.13635C2.25 6.91399 2.25 5.8028 2.60171 4.91531C3.16711 3.48856 4.36285 2.36316 5.87877 1.83101C6.82172 1.5 8.00235 1.5 10.3637 1.5C11.713 1.5 12.3876 1.5 12.9264 1.68915C13.7927 1.99324 14.4759 2.63633 14.799 3.4516C15 3.95874 15 4.59371 15 5.86364V9.75"
                          stroke="#3498DB"
                          strokeWidth="1.125"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.25 9C2.25 7.61933 3.36929 6.5 4.75 6.5C5.24934 6.5 5.83803 6.5875 6.32353 6.45741C6.75489 6.34182 7.09182 6.00489 7.20741 5.57352C7.3375 5.08803 7.25 4.49934 7.25 4C7.25 2.61929 8.36933 1.5 9.75 1.5"
                          stroke="#3498DB"
                          strokeWidth="1.125"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>Shopy</th>
                    <th className="">Events & Foil</th>
                    <th>₦4000</th>{" "}
                    <th className="w-[100px] pl-[40px]">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.875 12C14.2543 12.3686 15.75 13.3498 15.75 13.875M15.75 13.875C15.75 14.4002 14.2543 15.3814 13.875 15.75M15.75 13.875H9.75" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        <path
                          d="M8.25 16.5H8.04547C5.59955 16.5 4.3766 16.5 3.5273 15.9016C3.28397 15.7302 3.06794 15.5269 2.88578 15.2978C2.25 14.4985 2.25 13.3474 2.25 11.0455V9.13635C2.25 6.91399 2.25 5.8028 2.60171 4.91531C3.16711 3.48856 4.36285 2.36316 5.87877 1.83101C6.82172 1.5 8.00235 1.5 10.3637 1.5C11.713 1.5 12.3876 1.5 12.9264 1.68915C13.7927 1.99324 14.4759 2.63633 14.799 3.4516C15 3.95874 15 4.59371 15 5.86364V9.75"
                          stroke="#3498DB"
                          strokeWidth="1.125"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.25 9C2.25 7.61933 3.36929 6.5 4.75 6.5C5.24934 6.5 5.83803 6.5875 6.32353 6.45741C6.75489 6.34182 7.09182 6.00489 7.20741 5.57352C7.3375 5.08803 7.25 4.49934 7.25 4C7.25 2.61929 8.36933 1.5 9.75 1.5"
                          stroke="#3498DB"
                          strokeWidth="1.125"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* Table 3 */}
            <div className=" w-full p-[20px] h-fit flex flex-col overflow-x-scroll gap-[40px] rounded-[20px] bg-white ">
              <div className="flex justify-between w-full">
                <h4 className=" font-raleway font-semibold text-[16px] text-[#333] tracking-[0.9px]  ">Reports</h4>
                <Link to="/reportsuser">
                  <h5 className=" font-raleway font-semibold text-[12px] text-[#3498DB] tracking-[0.9px]  ">View All</h5>
                </Link>
              </div>
              <div className="flex w-[603px] pr-[50px] gap-[10px] items-center">
                <div className="flex text-[12px]  whitespace-nowrap text-[#999] font-montserrat font-medium tracking-[0.9px] flex-col items-start gap-[40px]">
                  <h4>Department Store</h4>
                  <h4>Grocries</h4>
                  <h4>Resturantes</h4>
                  <h4>Bills</h4>
                </div>
                <div className="flex text-[10px]  text-[#333] font-montserrat font-normal tracking-[0.9px] flex-col items-start gap-[40px]">
                  <div className="flex justify-between items-center gap-[10px]">
                    <div className=" rounded-r-[2px] h-[20px] w-[375px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                    <h4>₦50,000</h4>
                  </div>
                  <div className="flex justify-between items-center gap-[10px]">
                    <div className=" rounded-r-[2px] h-[20px] w-[200px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                    <h4>₦40,000</h4>
                  </div>
                  <div className="flex justify-between items-center gap-[10px]">
                    <div className=" rounded-r-[2px] h-[20px] w-[150px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                    <h4>₦30,000</h4>
                  </div>{" "}
                  <div className="flex justify-between items-center gap-[10px]">
                    <div className=" rounded-r-[2px] h-[20px] w-[75px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                    <h4>₦20,000</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
      {modal && <DownloadPrompt setModal={setModal} />}
    </>
  );
};
