import first from "../images/first.png";
import second from "../images/second.png";
import third from "../images/third.png";
import fourth from "../images/fourth.png";
import rating from "../images/Ratings.svg";

function Testimonial() {
  return (
    <>
      {/* BIG SCREENS FROM 1280PX */}
      <div className=" 100:hidden py-[33px] float-left gap-[70px]  xl:flex flex-col px-[50px] w-full justify-between mt-[60px]">
        <div className="text-left flex   flex-col gap-[20px] justify-between">
          <h2 className=" text-[#333]  text-[48px] font-raleway tracking-[0.9px] font-semibold">What Our Users Are Saying </h2>
          <p className="text-[22px] w-[650px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[38px] ">Embark on a Seamless Financial Journey with KOLeT: Where Convenience Meets Security</p>
          <h4 className="text-[20px] text-[#2980B9] font-raleway font-medium italic ">Join KOLeT Today and Transform Your Finances!</h4>
        </div>
        <div className=" w-full scroll-container   overflow-hidden gap-[30px] flex justify-between items-center ">
          <div className="   scroll-icons gap-[30px] flex justify-between items-center ">
            <div className="w-[394px] h-[324px] bg-[#fff] rounded-[20px] border-solid border-[1px] p-[30px]  border-[#2980B9]  ">
              <div className="flex w-[334px] flex-col justify-between gap-[10px] ">
                <div className=" grid gap-[5px]">
                  <img src={first} alt="User 1" />
                  <h4 className="text-[13px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Kelly Adam</h4>
                </div>
                <h3 className="text-[20px] leading-[30px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Game-Changer for My Daily Transactions!</h3>
                <p className="text-[13px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[23px] ">KOLeT has transformed how I handle payments. The simplicity and speed are unmatched.</p>
                <div className="flex w-[140px] justify-between items-center gap-[10px]">
                  <img className="" src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                </div>
              </div>
            </div>
            <div className="w-[394px] h-[324px] bg-[#fff] rounded-[20px] border-solid border-[1px] p-[30px]  border-[#2980B9]  ">
              <div className="flex w-[334px] flex-col  justify-between gap-[10px] ">
                <div className=" grid gap-[5px]">
                  <img src={second} alt="User 2" />
                  <h4 className="text-[13px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Jay Klhoe</h4>
                </div>
                <h3 className="text-[20px]  leading-[30px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Revolutionized My Payment Experience!</h3>
                <p className="text-[13px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[23px] ">KOLeT has transformed my payment experience. It's intuitive, efficient, and a game-changer in the world of finance apps.</p>
                <div className="flex w-[140px] justify-between items-center gap-[10px]">
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.04894 1.42705C7.3483 0.505739 8.6517 0.50574 8.95106 1.42705L10.0206 4.71885C10.1545 5.13087 10.5385 5.40983 10.9717 5.40983H14.4329C15.4016 5.40983 15.8044 6.64945 15.0207 7.21885L12.2205 9.25329C11.87 9.50793 11.7234 9.9593 11.8572 10.3713L12.9268 13.6631C13.2261 14.5844 12.1717 15.3506 11.388 14.7812L8.58778 12.7467C8.2373 12.4921 7.7627 12.4921 7.41221 12.7467L4.61204 14.7812C3.82833 15.3506 2.77385 14.5844 3.0732 13.6631L4.14277 10.3713C4.27665 9.9593 4.12999 9.50793 3.7795 9.25329L0.979333 7.21885C0.195619 6.64945 0.598395 5.40983 1.56712 5.40983H5.02832C5.46154 5.40983 5.8455 5.13087 5.97937 4.71885L7.04894 1.42705Z"
                      fill="#f5f5f5"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-[394px] h-[324px] bg-[#fff] rounded-[20px] border-solid border-[1px] p-[30px]  border-[#2980B9]  ">
              <div className="flex w-[334px] flex-col justify-between gap-[10px] ">
                <div className=" grid gap-[5px]">
                  <img src={third} alt="User 3" />
                  <h4 className="text-[13px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">John Doe</h4>
                </div>
                <h3 className="text-[20px] leading-[30px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">A Trusted Companion for Everyday Transactions</h3>
                <p className="text-[13px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[23px] ">KOLeT is my go-to for daily transactions. Secure setup, diverse payment options - a trusted companion in my financial journey.</p>
                <div className="flex w-[140px] justify-between items-center gap-[10px]">
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                </div>
              </div>
            </div>
            <div className="w-[394px] h-[324px] bg-[#fff] rounded-[20px] border-solid border-[1px] p-[30px]  border-[#2980B9]  ">
              <div className="flex w-[334px] flex-col justify-between gap-[10px] ">
                <div className=" grid gap-[5px]">
                  <img src={fourth} alt="User 4" />
                  <h4 className="text-[13px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Will Jay</h4>
                </div>
                <h3 className="text-[20px] leading-[30px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Effortless and Efficient - KOLeT Stands Out!</h3>
                <p className="text-[13px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[23px] ">The seamless experience with KOLeT is unmatched. Setting up my account to making quick payments - effortless and efficient.</p>
                <div className="flex w-[140px] justify-between items-center gap-[10px]">
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                </div>
              </div>
            </div>
          </div>
          <div className="scroll-icons gap-[30px] flex justify-between items-center ">
            <div className="w-[394px] h-[324px] bg-[#fff] rounded-[20px] border-solid border-[1px] p-[30px]  border-[#2980B9]  ">
              <div className="flex w-[334px] flex-col justify-between gap-[10px] ">
                <div className=" grid gap-[5px]">
                  <img src={first} alt="User 1" />
                  <h4 className="text-[13px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Kelly Adam</h4>
                </div>
                <h3 className="text-[20px] leading-[30px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Game-Changer for My Daily Transactions!</h3>
                <p className="text-[13px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[23px] ">KOLeT has transformed how I handle payments. The simplicity and speed are unmatched.</p>
                <div className="flex w-[140px] justify-between items-center gap-[10px]">
                  <img className="" src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                </div>
              </div>
            </div>
            <div className="w-[394px] h-[324px] bg-[#fff] rounded-[20px] border-solid border-[1px] p-[30px]  border-[#2980B9]  ">
              <div className="flex w-[334px] flex-col  justify-between gap-[10px] ">
                <div className=" grid gap-[5px]">
                  <img src={second} alt="User 2" />
                  <h4 className="text-[13px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Jay Klhoe</h4>
                </div>
                <h3 className="text-[20px]  leading-[30px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Revolutionized My Payment Experience!</h3>
                <p className="text-[13px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[23px] ">KOLeT has transformed my payment experience. It's intuitive, efficient, and a game-changer in the world of finance apps.</p>
                <div className="flex w-[140px] justify-between items-center gap-[10px]">
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.04894 1.42705C7.3483 0.505739 8.6517 0.50574 8.95106 1.42705L10.0206 4.71885C10.1545 5.13087 10.5385 5.40983 10.9717 5.40983H14.4329C15.4016 5.40983 15.8044 6.64945 15.0207 7.21885L12.2205 9.25329C11.87 9.50793 11.7234 9.9593 11.8572 10.3713L12.9268 13.6631C13.2261 14.5844 12.1717 15.3506 11.388 14.7812L8.58778 12.7467C8.2373 12.4921 7.7627 12.4921 7.41221 12.7467L4.61204 14.7812C3.82833 15.3506 2.77385 14.5844 3.0732 13.6631L4.14277 10.3713C4.27665 9.9593 4.12999 9.50793 3.7795 9.25329L0.979333 7.21885C0.195619 6.64945 0.598395 5.40983 1.56712 5.40983H5.02832C5.46154 5.40983 5.8455 5.13087 5.97937 4.71885L7.04894 1.42705Z"
                      fill="#f5f5f5"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-[394px] h-[324px] bg-[#fff] rounded-[20px] border-solid border-[1px] p-[30px]  border-[#2980B9]  ">
              <div className="flex w-[334px] flex-col justify-between gap-[10px] ">
                <div className=" grid gap-[5px]">
                  <img src={third} alt="User 3" />
                  <h4 className="text-[13px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">John Doe</h4>
                </div>
                <h3 className="text-[20px] leading-[30px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">A Trusted Companion for Everyday Transactions</h3>
                <p className="text-[13px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[23px] ">KOLeT is my go-to for daily transactions. Secure setup, diverse payment options - a trusted companion in my financial journey.</p>
                <div className="flex w-[140px] justify-between items-center gap-[10px]">
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                </div>
              </div>
            </div>
            <div className="w-[394px] h-[324px] bg-[#fff] rounded-[20px] border-solid border-[1px] p-[30px]  border-[#2980B9]  ">
              <div className="flex w-[334px] flex-col justify-between gap-[10px] ">
                <div className=" grid gap-[5px]">
                  <img src={fourth} alt="User 4" />
                  <h4 className="text-[13px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Will Jay</h4>
                </div>
                <h3 className="text-[20px] leading-[30px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Effortless and Efficient - KOLeT Stands Out!</h3>
                <p className="text-[13px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[23px] ">The seamless experience with KOLeT is unmatched. Setting up my account to making quick payments - effortless and efficient.</p>
                <div className="flex w-[140px] justify-between items-center gap-[10px]">
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1279PX */}
      <div className=" 100:flex py-[33px] float-left gap-[70px]  xl:hidden flex-col px-[30px] w-full justify-between mt-[60px]">
        <div className="text-left flex sm:w-[550px]  flex-col gap-[20px] justify-between">
          <h2 className=" text-[#333]  text-[32px] font-raleway tracking-[0.9px] font-semibold">What Our Users Are Saying </h2>
          <p className="text-[18px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[38px] ">Embark on a Seamless Financial Journey with KOLeT: Where Convenience Meets Security</p>
          <h4 className="text-[14px] text-[#2980B9] font-raleway font-medium italic ">Join KOLeT Today and Transform Your Finances!</h4>
        </div>
        <div className=" w-full scroll-container   overflow-hidden gap-[30px] flex justify-between items-center ">
          <div className="   scroll-icons gap-[30px] flex justify-between items-center ">
            <div className="w-[334px] phone:w-[394px] h-[324px] bg-[#fff] rounded-[20px] border-solid border-[1px] p-[30px]  border-[#2980B9]  ">
              <div className="flex w-[290px] phone:w-[330px] flex-col justify-between gap-[10px] ">
                <div className=" grid gap-[5px]">
                  <img src={first} alt="User 1" />
                  <h4 className="text-[13px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Kelly Adam</h4>
                </div>
                <h3 className="text-[20px] leading-[30px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Game-Changer for My Daily Transactions!</h3>
                <p className="text-[13px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[23px] ">KOLeT has transformed how I handle payments. The simplicity and speed are unmatched.</p>
                <div className="flex w-[140px] justify-between items-center gap-[10px]">
                  <img className="" src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                </div>
              </div>
            </div>
            <div className="w-[334px] phone:w-[394px] h-[324px] bg-[#fff] rounded-[20px] border-solid border-[1px] p-[30px]  border-[#2980B9]  ">
              <div className="flex w-[290px] phone:w-[330px] flex-col  justify-between gap-[10px] ">
                <div className=" grid gap-[5px]">
                  <img src={second} alt="User 2" />
                  <h4 className="text-[13px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Jay Klhoe</h4>
                </div>
                <h3 className="text-[20px]  leading-[30px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Revolutionized My Payment Experience!</h3>
                <p className="text-[13px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[23px] ">KOLeT has transformed my payment experience. It's intuitive, efficient, and a game-changer in the world of finance apps.</p>
                <div className="flex w-[140px] justify-between items-center gap-[10px]">
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.04894 1.42705C7.3483 0.505739 8.6517 0.50574 8.95106 1.42705L10.0206 4.71885C10.1545 5.13087 10.5385 5.40983 10.9717 5.40983H14.4329C15.4016 5.40983 15.8044 6.64945 15.0207 7.21885L12.2205 9.25329C11.87 9.50793 11.7234 9.9593 11.8572 10.3713L12.9268 13.6631C13.2261 14.5844 12.1717 15.3506 11.388 14.7812L8.58778 12.7467C8.2373 12.4921 7.7627 12.4921 7.41221 12.7467L4.61204 14.7812C3.82833 15.3506 2.77385 14.5844 3.0732 13.6631L4.14277 10.3713C4.27665 9.9593 4.12999 9.50793 3.7795 9.25329L0.979333 7.21885C0.195619 6.64945 0.598395 5.40983 1.56712 5.40983H5.02832C5.46154 5.40983 5.8455 5.13087 5.97937 4.71885L7.04894 1.42705Z"
                      fill="#f5f5f5"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-[334px] phone:w-[394px] h-[324px] bg-[#fff] rounded-[20px] border-solid border-[1px] p-[30px]  border-[#2980B9]  ">
              <div className="flex w-[290px] phone:w-[330px] flex-col justify-between gap-[10px] ">
                <div className=" grid gap-[5px]">
                  <img src={third} alt="User 3" />
                  <h4 className="text-[13px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">John Doe</h4>
                </div>
                <h3 className="text-[20px] leading-[30px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">A Trusted Companion for Everyday Transactions</h3>
                <p className="text-[13px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[23px] ">KOLeT is my go-to for daily transactions. Secure setup, diverse payment options - a trusted companion in my financial journey.</p>
                <div className="flex w-[140px] justify-between items-center gap-[10px]">
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                </div>
              </div>
            </div>
            <div className="w-[334px] phone:w-[394px] h-[324px] bg-[#fff] rounded-[20px] border-solid border-[1px] p-[30px]  border-[#2980B9]  ">
              <div className="flex w-[290px] phone:w-[330px] flex-col justify-between gap-[10px] ">
                <div className=" grid gap-[5px]">
                  <img src={fourth} alt="User 4" />
                  <h4 className="text-[13px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Will Jay</h4>
                </div>
                <h3 className="text-[20px] leading-[30px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Effortless and Efficient - KOLeT Stands Out!</h3>
                <p className="text-[13px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[23px] ">The seamless experience with KOLeT is unmatched. Setting up my account to making quick payments - effortless and efficient.</p>
                <div className="flex w-[140px] justify-between items-center gap-[10px]">
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                </div>
              </div>
            </div>
          </div>
          <div className="scroll-icons gap-[30px] flex justify-between items-center ">
            <div className="w-[334px] phone:w-[394px] h-[324px] bg-[#fff] rounded-[20px] border-solid border-[1px] p-[30px]  border-[#2980B9]  ">
              <div className="flex w-[290px] phone:w-[330px] flex-col justify-between gap-[10px] ">
                <div className=" grid gap-[5px]">
                  <img src={first} alt="User 1" />
                  <h4 className="text-[13px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Kelly Adam</h4>
                </div>
                <h3 className="text-[20px] leading-[30px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Game-Changer for My Daily Transactions!</h3>
                <p className="text-[13px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[23px] ">KOLeT has transformed how I handle payments. The simplicity and speed are unmatched.</p>
                <div className="flex w-[140px] justify-between items-center gap-[10px]">
                  <img className="" src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                </div>
              </div>
            </div>
            <div className="w-[334px] phone:w-[394px] h-[324px] bg-[#fff] rounded-[20px] border-solid border-[1px] p-[30px]  border-[#2980B9]  ">
              <div className="flex w-[290px] phone:w-[330px] flex-col  justify-between gap-[10px] ">
                <div className=" grid gap-[5px]">
                  <img src={second} alt="User 2" />
                  <h4 className="text-[13px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Jay Klhoe</h4>
                </div>
                <h3 className="text-[20px]  leading-[30px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Revolutionized My Payment Experience!</h3>
                <p className="text-[13px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[23px] ">KOLeT has transformed my payment experience. It's intuitive, efficient, and a game-changer in the world of finance apps.</p>
                <div className="flex w-[140px] justify-between items-center gap-[10px]">
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.04894 1.42705C7.3483 0.505739 8.6517 0.50574 8.95106 1.42705L10.0206 4.71885C10.1545 5.13087 10.5385 5.40983 10.9717 5.40983H14.4329C15.4016 5.40983 15.8044 6.64945 15.0207 7.21885L12.2205 9.25329C11.87 9.50793 11.7234 9.9593 11.8572 10.3713L12.9268 13.6631C13.2261 14.5844 12.1717 15.3506 11.388 14.7812L8.58778 12.7467C8.2373 12.4921 7.7627 12.4921 7.41221 12.7467L4.61204 14.7812C3.82833 15.3506 2.77385 14.5844 3.0732 13.6631L4.14277 10.3713C4.27665 9.9593 4.12999 9.50793 3.7795 9.25329L0.979333 7.21885C0.195619 6.64945 0.598395 5.40983 1.56712 5.40983H5.02832C5.46154 5.40983 5.8455 5.13087 5.97937 4.71885L7.04894 1.42705Z"
                      fill="#f5f5f5"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-[334px] phone:w-[394px] h-[324px] bg-[#fff] rounded-[20px] border-solid border-[1px] p-[30px]  border-[#2980B9]  ">
              <div className="flex w-[290px] phone:w-[330px] flex-col justify-between gap-[10px] ">
                <div className=" grid gap-[5px]">
                  <img src={third} alt="User 3" />
                  <h4 className="text-[13px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">John Doe</h4>
                </div>
                <h3 className="text-[20px] leading-[30px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">A Trusted Companion for Everyday Transactions</h3>
                <p className="text-[13px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[23px] ">KOLeT is my go-to for daily transactions. Secure setup, diverse payment options - a trusted companion in my financial journey.</p>
                <div className="flex w-[140px] justify-between items-center gap-[10px]">
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                </div>
              </div>
            </div>
            <div className="w-[334px] phone:w-[394px] h-[324px] bg-[#fff] rounded-[20px] border-solid border-[1px] p-[30px]  border-[#2980B9]  ">
              <div className="flex w-[290px] phone:w-[330px] flex-col justify-between gap-[10px] ">
                <div className=" grid gap-[5px]">
                  <img src={fourth} alt="User 4" />
                  <h4 className="text-[13px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Will Jay</h4>
                </div>
                <h3 className="text-[20px] leading-[30px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Effortless and Efficient - KOLeT Stands Out!</h3>
                <p className="text-[13px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[23px] ">The seamless experience with KOLeT is unmatched. Setting up my account to making quick payments - effortless and efficient.</p>
                <div className="flex w-[140px] justify-between items-center gap-[10px]">
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                  <img src={rating} alt="ratings" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonial;
