// import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./Component/LandingPage/HomePage";
import { SignUp } from "./Component/Login&Register/SignUp";
import { Login } from "./Component/Login&Register/Login";
import { ForgotPassword } from "./Component/ForgotPassword/ForgotPassword";
import CreatePasswordForgotPassword from "./Component/ForgotPassword/CreatePasswordForgotPassword";
import { OtpForgotPassword } from "./Component/ForgotPassword/OtpForgotPassword";
import { DashboardUser } from "./Component/AdminUser/DashboardUser";
import PaymentHistory from "./Component/AdminUser/PaymentHistory";
import TransactionUser from "./Component/AdminUser/TransactionUser";
import { ReportsUser } from "./Component/AdminUser/ReportsUser";
import { ProfileUser } from "./Component/AdminUser/ProfileUser";
import "./App.css";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<HomePage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/otp" element={<OtpForgotPassword />} />
          <Route path="/createpassword" element={<CreatePasswordForgotPassword />} />
          <Route path="/dashboarduser" element={<DashboardUser />} />
          <Route path="/paymenthistory" element={<PaymentHistory />} />
          <Route path="/transactionhistoryuser" element={<TransactionUser />} />
          <Route path="/reportsuser" element={<ReportsUser />} />
          <Route path="/profileuser" element={<ProfileUser />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
