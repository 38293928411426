import feature1 from "../images/phone1.png";
import feature2 from "../images/phone2.png";
import feature3 from "../images/phhone3.png";

function Features() {
  return (
    <>
      {/* BIG SCREENS FROM 1280PX */}
      <div className=" 100:hidden xl:flex w-full justify-center items-center mt-[60px]">
        <div className="w-[90%] gap-[70px] flex flex-col justify-between items-center">
          <h2 className=" text-[#333] w-[650px] text-center leading-[70px] text-[48px] font-raleway tracking-[0.9px] font-semibold">
            Discover the Power-Packed Features of <span className="text-[#3498DB]">KOLeT:</span>
          </h2>
          <div className="flex gap-[40px] justify-between items-center">
            {/* Box 1 */}
            <div className="w-[385px]  px-[30px] pt-[50px] h-[600px] gap-[29px] rounded-[20px] bg-[#3498DB] flex flex-col justify-between items-center ">
              <div className="text-center gap-[10px] flex flex-col justify-between items-center">
                <h4 className="text-[30px] text-[#f5f5f5] font-raleway font-semibold tracking-[0.9px] ">One-Tap Payments</h4>
                <p className="text-[14px] leading-[25px] text-[#ECF0F1] trackin-[0.9px] font-montserrat font-normal ">Secure payments with a tap. KOLeT simplifies transactions for ultimate convenience.</p>
              </div>
              <img src={feature1} alt="Feature 1" />
            </div>
            {/* Box 2 */}
            <div className="w-[385px]  px-[30px] pt-[50px] h-[600px] gap-[29px] rounded-[20px] bg-[#3498DB] flex flex-col justify-between items-center ">
              <div className="text-center gap-[10px] flex flex-col justify-between items-center">
                <h4 className="text-[30px] text-[#f5f5f5] font-raleway font-semibold tracking-[0.9px] ">Real-time Alerts</h4>
                <p className="text-[14px] leading-[25px] text-[#ECF0F1] trackin-[0.9px] font-montserrat font-normal ">Instant transaction alerts for peace of mind. Stay updated, always.</p>
              </div>
              <img src={feature2} alt="Feature 2" />
            </div>
            {/* Box 3 */}
            <div className="w-[385px]  px-[30px] pt-[50px] h-[600px] gap-[29px] rounded-[20px] bg-[#3498DB] flex flex-col justify-between items-center ">
              <div className="text-center gap-[10px] flex flex-col justify-between items-center">
                <h4 className="text-[30px] text-[#f5f5f5] font-raleway font-semibold tracking-[0.9px] ">Contactless NFC</h4>
                <p className="text-[14px] leading-[25px] text-[#ECF0F1] trackin-[0.9px] font-montserrat font-normal ">Tap, pay, go! KOLeT embraces NFC for lightning-fast transactions.</p>
              </div>
              <img src={feature3} alt="Feature 3" />
            </div>
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1279PX */}
      <div className=" 100:flex  xl:hidden mt-[60px] w-full justify-center items-center">
        <div className=" w-[90%] gap-[60px] flex justify-between items-center flex-col">
          <h2 className=" text-[#333] sm:w-[450px]  text-center leading-[45px] text-[28px] font-raleway tracking-[0.9px] font-semibold">
            Discover the Power-Packed Features of <span className="text-[#3498DB]">KOLeT:</span>
          </h2>
          <div className="flex flex-wrap  gap-[40px] justify-center items-center">
            {/* Box 1 */}
            <div className="w-[330px]  px-[30px] pt-[50px] h-[600px] gap-[29px] rounded-[20px] bg-[#3498DB] flex flex-col justify-between items-center ">
              <div className="text-center gap-[10px] flex flex-col justify-between items-center">
                <h4 className="text-[24px] text-[#f5f5f5] font-raleway font-semibold tracking-[0.9px] ">One-Tap Payments</h4>
                <p className="text-[12px] leading-[25px] text-[#ECF0F1] trackin-[0.9px] font-montserrat font-normal ">Secure payments with a tap. KOLeT simplifies transactions for ultimate convenience.</p>
              </div>
              <img loading="lazy" src={feature1} alt="Feature 1" />
            </div>
            {/* Box 2 */}
            <div className="w-[330px]  px-[30px] pt-[50px] h-[600px] gap-[29px] rounded-[20px] bg-[#3498DB] flex flex-col justify-between items-center ">
              <div className="text-center gap-[10px] flex flex-col justify-between items-center">
                <h4 className="text-[24px] text-[#f5f5f5] font-raleway font-semibold tracking-[0.9px] ">Real-time Alerts</h4>
                <p className="text-[12px] leading-[25px] text-[#ECF0F1] trackin-[0.9px] font-montserrat font-normal ">Instant transaction alerts for peace of mind. Stay updated, always.</p>
              </div>
              <img src={feature2} alt="Feature 2" />
            </div>
            {/* Box 3 */}
            <div className="w-[330px]  px-[30px] pt-[50px] h-[600px] gap-[29px] rounded-[20px] bg-[#3498DB] flex flex-col justify-between items-center ">
              <div className="text-center gap-[10px] flex flex-col justify-between items-center">
                <h4 className="text-[24px] text-[#f5f5f5] font-raleway font-semibold tracking-[0.9px] ">Contactless NFC</h4>
                <p className="text-[12px] leading-[25px] text-[#ECF0F1] trackin-[0.9px] font-montserrat font-normal ">Tap, pay, go! KOLeT embraces NFC for lightning-fast transactions.</p>
              </div>
              <img src={feature3} alt="Feature 3" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
