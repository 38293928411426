export const DownloadPrompt = ({ setModal }) => {
  return (
    <>
      <div className=" z-[20]   top-0 left-0 flex items-center justify-center bg-black bg-opacity-65 fixed  w-full h-screen">
        {/* BIG SCREENS FROM 1280PX */}
        <div className=" 100:hidden  w-[803px] xl:flex flex-col items-center justify-between gap-[40px] px-[60px] py-[70px] h-[537px] rounded-[20px] bg-[#f5f5f5]">
          <div className=" font-raleway w-full tracking-[0.9px] flex justify-end font-medium text-[16px] text-[#3498DB] ">
            {" "}
            <h4 className=" cursor-pointer float-right " onClick={() => setModal(false)}>
              Skip
            </h4>
          </div>
          <div className="flex flex-col text-center gap-[40px] items-center">
            <h2 className=" leading-[50px] font-raleway font-semibold text-[#333] text-[32px] tracking-[0.9px] ">
              Unlock More with the <span className="text-[#3498DB]">KOLeT </span> Mobile App!
            </h2>
            <p className=" text-[20px] text-[#999] leading-[45px] font-montserrat font-normal ">
              Elevate Your Financial Experience! Download the KOLeT app for a user-friendly interface, real-time updates, and exclusive features. Choose your platform below!
            </p>
            <div className=" flex justify-between gap-[20px] items-center">
              <button className=" w-[220px] py-[10px] gap-[10px] h-[63px] rounded-[4px] bg-[#333] flex justify-center items-center px-[20px]   ">
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_941_3680)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.1063 5.28931C21.1999 4.02182 21.9371 2.25647 21.7354 0.5C20.1594 0.56 18.2528 1.50672 17.1228 2.77271C16.1081 3.89619 15.2223 5.69135 15.4604 7.41333C17.2184 7.54382 19.0127 6.55828 20.1063 5.28931ZM24.0485 16.4375C24.0925 20.9779 28.2045 22.4883 28.25 22.5078C28.2166 22.6143 27.5932 24.6599 26.084 26.7749C24.7781 28.6019 23.4236 30.4214 21.2894 30.4604C19.1932 30.4979 18.5182 29.2695 16.1202 29.2695C13.7237 29.2695 12.9744 30.4213 10.9904 30.4978C8.93059 30.5713 7.36071 28.5212 6.04565 26.7002C3.35485 22.9757 1.2996 16.1751 4.06016 11.5852C5.43135 9.30674 7.88097 7.86168 10.5414 7.82568C12.5633 7.78818 14.473 9.12939 15.7091 9.12939C16.9453 9.12939 19.266 7.51691 21.705 7.75391C22.7258 7.79441 25.5926 8.14828 27.4324 10.7297C27.2838 10.8182 24.0121 12.6426 24.0485 16.4375Z"
                      fill="#F5F5F5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_941_3680">
                      <rect width="30" height="30" fill="white" transform="translate(0.5 0.5)" />
                    </clipPath>
                  </defs>
                </svg>
                <div className=" flex flex-col items-start gap-[3px]  ">
                  <h4 className=" text-[#fff] font-montserrat font-medium text-[10px] tracking-[0.9px]  ">Download on the</h4>
                  <h2 className="text-[#fff] font-raleway font-semibold text-[24px] tracking-[1px] ">App Store</h2>
                </div>
              </button>
              <button className=" w-[220px] py-[10px] gap-[10px] h-[63px] rounded-[4px] bg-[#333] flex justify-center items-center px-[10px]   ">
                <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_941_3689)">
                    <path d="M22.4045 10.7905C18.9195 8.84121 13.3259 5.71119 4.64974 0.85323C4.0648 0.46692 3.39179 0.416295 2.8103 0.613112L17.6961 15.4989L22.4045 10.7905Z" fill="#32BBFF" />
                    <path
                      d="M2.81034 0.613159C2.70135 0.650073 2.5953 0.694605 2.49387 0.748394C1.85174 1.09574 1.37988 1.76412 1.37988 2.60945V28.3884C1.37988 29.2337 1.85168 29.9021 2.49387 30.2494C2.59512 30.3031 2.70112 30.3478 2.80998 30.385L17.6961 15.4989L2.81034 0.613159Z"
                      fill="#32BBFF"
                    />
                    <path d="M17.6962 15.4989L2.81006 30.385C3.39172 30.5832 4.06473 30.5365 4.64985 30.1446C13.0638 25.433 18.5944 22.3401 22.1197 20.3743C22.2179 20.3191 22.314 20.2652 22.4091 20.2118L17.6962 15.4989Z" fill="#32BBFF" />
                    <path d="M1.37988 15.4989V28.3884C1.37988 29.2337 1.85168 29.9021 2.49387 30.2494C2.59512 30.3031 2.70112 30.3478 2.80998 30.385L17.6961 15.4989H1.37988Z" fill="#2C9FD9" />
                    <path d="M4.6498 0.853198C3.95517 0.394526 3.13603 0.407592 2.4939 0.748374L17.4702 15.7247L22.4045 10.7904C18.9195 8.84118 13.3259 5.71116 4.6498 0.853198Z" fill="#29CC5E" />
                    <path d="M17.4703 15.2731L2.4939 30.2494C3.13609 30.5901 3.95517 30.6098 4.6498 30.1445C13.0638 25.433 18.5943 22.3401 22.1197 20.3743C22.2178 20.3191 22.314 20.2652 22.409 20.2118L17.4703 15.2731Z" fill="#D93F21" />
                    <path
                      d="M28.6202 15.4989C28.6202 14.7847 28.2598 14.0638 27.5455 13.6641C27.5455 13.6641 26.2096 12.9187 22.1149 10.6284L17.2444 15.4989L22.1196 20.3742C26.1694 18.1004 27.5454 17.3337 27.5454 17.3337C28.2598 16.934 28.6202 16.2132 28.6202 15.4989Z"
                      fill="#FFD500"
                    />
                    <path d="M27.5455 17.3337C28.2598 16.934 28.6202 16.2132 28.6202 15.4989H17.2444L22.1196 20.3742C26.1695 18.1004 27.5455 17.3337 27.5455 17.3337Z" fill="#FFAA00" />
                  </g>
                  <defs>
                    <clipPath id="clip0_941_3689">
                      <rect width="30" height="30" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                  </defs>
                </svg>

                <div className=" flex flex-col items-start gap-[3px]  ">
                  <h4 className=" text-[#fff] font-montserrat font-medium text-[10px] tracking-[0.9px]  ">GET IT ON</h4>
                  <h2 className="text-[#fff] whitespace-nowrap font-raleway font-semibold text-[24px] tracking-[1px] ">Google Play</h2>
                </div>
              </button>
            </div>
          </div>
        </div>
        {/* SMALL SCREENS FROM 100PX TO 1279PX */}
        <div className=" 100:flex  w-[80%] md:w-[680px]  xl:hidden flex-col items-center justify-between gap-[40px] px-[30px] py-[70px] h-fit rounded-[20px] bg-[#f5f5f5]">
          <div className=" font-raleway w-full tracking-[0.9px] flex justify-end font-medium text-[16px] text-[#3498DB] ">
            {" "}
            <h4 className=" cursor-pointer float-right " onClick={() => setModal(false)}>
              Skip
            </h4>
          </div>
          <div className="flex w-full flex-col text-center gap-[30px] items-center">
            <h2 className=" font-raleway leading-[50px] font-semibold text-[#333] text-[26px] md:text-[30px] tracking-[0.9px] ">
              Unlock More with the <span className="text-[#3498DB]">KOLeT </span> Mobile App!
            </h2>
            <p className=" text-[18px] text-[#999] leading-[45px] font-montserrat font-normal ">
              Elevate Your Financial Experience! Download the KOLeT app for a user-friendly interface, real-time updates, and exclusive features. Choose your platform below!
            </p>
            <div className=" 100:flex sm:hidden justify-between gap-[10px] items-center">
              <button className=" w-[150px] py-[10px] gap-[5px] h-[63px] rounded-[4px] bg-[#333] flex justify-center items-center px-[10px]   ">
                <svg width="15" height="15" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_941_3680)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.1063 5.28931C21.1999 4.02182 21.9371 2.25647 21.7354 0.5C20.1594 0.56 18.2528 1.50672 17.1228 2.77271C16.1081 3.89619 15.2223 5.69135 15.4604 7.41333C17.2184 7.54382 19.0127 6.55828 20.1063 5.28931ZM24.0485 16.4375C24.0925 20.9779 28.2045 22.4883 28.25 22.5078C28.2166 22.6143 27.5932 24.6599 26.084 26.7749C24.7781 28.6019 23.4236 30.4214 21.2894 30.4604C19.1932 30.4979 18.5182 29.2695 16.1202 29.2695C13.7237 29.2695 12.9744 30.4213 10.9904 30.4978C8.93059 30.5713 7.36071 28.5212 6.04565 26.7002C3.35485 22.9757 1.2996 16.1751 4.06016 11.5852C5.43135 9.30674 7.88097 7.86168 10.5414 7.82568C12.5633 7.78818 14.473 9.12939 15.7091 9.12939C16.9453 9.12939 19.266 7.51691 21.705 7.75391C22.7258 7.79441 25.5926 8.14828 27.4324 10.7297C27.2838 10.8182 24.0121 12.6426 24.0485 16.4375Z"
                      fill="#F5F5F5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_941_3680">
                      <rect width="30" height="30" fill="white" transform="translate(0.5 0.5)" />
                    </clipPath>
                  </defs>
                </svg>
                <div className=" flex flex-col items-start gap-[3px]  ">
                  <h4 className=" text-[#fff] font-montserrat font-medium text-[8px] tracking-[0.9px]  ">Download on the</h4>
                  <h2 className="text-[#fff] font-raleway font-semibold text-[18px] tracking-[1px] ">App Store</h2>
                </div>
              </button>
              <button className=" w-[150px] py-[10px] gap-[5px] h-[63px] rounded-[4px] bg-[#333] flex justify-center items-center px-[5px]   ">
                <svg width="14" height="14" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_941_3689)">
                    <path d="M22.4045 10.7905C18.9195 8.84121 13.3259 5.71119 4.64974 0.85323C4.0648 0.46692 3.39179 0.416295 2.8103 0.613112L17.6961 15.4989L22.4045 10.7905Z" fill="#32BBFF" />
                    <path
                      d="M2.81034 0.613159C2.70135 0.650073 2.5953 0.694605 2.49387 0.748394C1.85174 1.09574 1.37988 1.76412 1.37988 2.60945V28.3884C1.37988 29.2337 1.85168 29.9021 2.49387 30.2494C2.59512 30.3031 2.70112 30.3478 2.80998 30.385L17.6961 15.4989L2.81034 0.613159Z"
                      fill="#32BBFF"
                    />
                    <path d="M17.6962 15.4989L2.81006 30.385C3.39172 30.5832 4.06473 30.5365 4.64985 30.1446C13.0638 25.433 18.5944 22.3401 22.1197 20.3743C22.2179 20.3191 22.314 20.2652 22.4091 20.2118L17.6962 15.4989Z" fill="#32BBFF" />
                    <path d="M1.37988 15.4989V28.3884C1.37988 29.2337 1.85168 29.9021 2.49387 30.2494C2.59512 30.3031 2.70112 30.3478 2.80998 30.385L17.6961 15.4989H1.37988Z" fill="#2C9FD9" />
                    <path d="M4.6498 0.853198C3.95517 0.394526 3.13603 0.407592 2.4939 0.748374L17.4702 15.7247L22.4045 10.7904C18.9195 8.84118 13.3259 5.71116 4.6498 0.853198Z" fill="#29CC5E" />
                    <path d="M17.4703 15.2731L2.4939 30.2494C3.13609 30.5901 3.95517 30.6098 4.6498 30.1445C13.0638 25.433 18.5943 22.3401 22.1197 20.3743C22.2178 20.3191 22.314 20.2652 22.409 20.2118L17.4703 15.2731Z" fill="#D93F21" />
                    <path
                      d="M28.6202 15.4989C28.6202 14.7847 28.2598 14.0638 27.5455 13.6641C27.5455 13.6641 26.2096 12.9187 22.1149 10.6284L17.2444 15.4989L22.1196 20.3742C26.1694 18.1004 27.5454 17.3337 27.5454 17.3337C28.2598 16.934 28.6202 16.2132 28.6202 15.4989Z"
                      fill="#FFD500"
                    />
                    <path d="M27.5455 17.3337C28.2598 16.934 28.6202 16.2132 28.6202 15.4989H17.2444L22.1196 20.3742C26.1695 18.1004 27.5455 17.3337 27.5455 17.3337Z" fill="#FFAA00" />
                  </g>
                  <defs>
                    <clipPath id="clip0_941_3689">
                      <rect width="30" height="30" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                  </defs>
                </svg>

                <div className=" flex flex-col items-start gap-[3px]  ">
                  <h4 className=" text-[#fff] font-montserrat font-medium text-[8px] tracking-[0.9px]  ">GET IT ON</h4>
                  <h2 className="text-[#fff] whitespace-nowrap font-raleway font-semibold text-[18px] tracking-[1px] ">Google Play</h2>
                </div>
              </button>
            </div>
            <div className=" sm:flex 100:hidden justify-between gap-[20px] items-center">
              <button className=" w-[220px] py-[10px] gap-[10px] h-[63px] rounded-[4px] bg-[#333] flex justify-center items-center px-[20px]   ">
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_941_3680)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.1063 5.28931C21.1999 4.02182 21.9371 2.25647 21.7354 0.5C20.1594 0.56 18.2528 1.50672 17.1228 2.77271C16.1081 3.89619 15.2223 5.69135 15.4604 7.41333C17.2184 7.54382 19.0127 6.55828 20.1063 5.28931ZM24.0485 16.4375C24.0925 20.9779 28.2045 22.4883 28.25 22.5078C28.2166 22.6143 27.5932 24.6599 26.084 26.7749C24.7781 28.6019 23.4236 30.4214 21.2894 30.4604C19.1932 30.4979 18.5182 29.2695 16.1202 29.2695C13.7237 29.2695 12.9744 30.4213 10.9904 30.4978C8.93059 30.5713 7.36071 28.5212 6.04565 26.7002C3.35485 22.9757 1.2996 16.1751 4.06016 11.5852C5.43135 9.30674 7.88097 7.86168 10.5414 7.82568C12.5633 7.78818 14.473 9.12939 15.7091 9.12939C16.9453 9.12939 19.266 7.51691 21.705 7.75391C22.7258 7.79441 25.5926 8.14828 27.4324 10.7297C27.2838 10.8182 24.0121 12.6426 24.0485 16.4375Z"
                      fill="#F5F5F5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_941_3680">
                      <rect width="30" height="30" fill="white" transform="translate(0.5 0.5)" />
                    </clipPath>
                  </defs>
                </svg>
                <div className=" flex flex-col items-start gap-[3px]  ">
                  <h4 className=" text-[#fff] font-montserrat font-medium text-[10px] tracking-[0.9px]  ">Download on the</h4>
                  <h2 className="text-[#fff] font-raleway font-semibold text-[24px] tracking-[1px] ">App Store</h2>
                </div>
              </button>
              <button className=" w-[220px] py-[10px] gap-[10px] h-[63px] rounded-[4px] bg-[#333] flex justify-center items-center px-[10px]   ">
                <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_941_3689)">
                    <path d="M22.4045 10.7905C18.9195 8.84121 13.3259 5.71119 4.64974 0.85323C4.0648 0.46692 3.39179 0.416295 2.8103 0.613112L17.6961 15.4989L22.4045 10.7905Z" fill="#32BBFF" />
                    <path
                      d="M2.81034 0.613159C2.70135 0.650073 2.5953 0.694605 2.49387 0.748394C1.85174 1.09574 1.37988 1.76412 1.37988 2.60945V28.3884C1.37988 29.2337 1.85168 29.9021 2.49387 30.2494C2.59512 30.3031 2.70112 30.3478 2.80998 30.385L17.6961 15.4989L2.81034 0.613159Z"
                      fill="#32BBFF"
                    />
                    <path d="M17.6962 15.4989L2.81006 30.385C3.39172 30.5832 4.06473 30.5365 4.64985 30.1446C13.0638 25.433 18.5944 22.3401 22.1197 20.3743C22.2179 20.3191 22.314 20.2652 22.4091 20.2118L17.6962 15.4989Z" fill="#32BBFF" />
                    <path d="M1.37988 15.4989V28.3884C1.37988 29.2337 1.85168 29.9021 2.49387 30.2494C2.59512 30.3031 2.70112 30.3478 2.80998 30.385L17.6961 15.4989H1.37988Z" fill="#2C9FD9" />
                    <path d="M4.6498 0.853198C3.95517 0.394526 3.13603 0.407592 2.4939 0.748374L17.4702 15.7247L22.4045 10.7904C18.9195 8.84118 13.3259 5.71116 4.6498 0.853198Z" fill="#29CC5E" />
                    <path d="M17.4703 15.2731L2.4939 30.2494C3.13609 30.5901 3.95517 30.6098 4.6498 30.1445C13.0638 25.433 18.5943 22.3401 22.1197 20.3743C22.2178 20.3191 22.314 20.2652 22.409 20.2118L17.4703 15.2731Z" fill="#D93F21" />
                    <path
                      d="M28.6202 15.4989C28.6202 14.7847 28.2598 14.0638 27.5455 13.6641C27.5455 13.6641 26.2096 12.9187 22.1149 10.6284L17.2444 15.4989L22.1196 20.3742C26.1694 18.1004 27.5454 17.3337 27.5454 17.3337C28.2598 16.934 28.6202 16.2132 28.6202 15.4989Z"
                      fill="#FFD500"
                    />
                    <path d="M27.5455 17.3337C28.2598 16.934 28.6202 16.2132 28.6202 15.4989H17.2444L22.1196 20.3742C26.1695 18.1004 27.5455 17.3337 27.5455 17.3337Z" fill="#FFAA00" />
                  </g>
                  <defs>
                    <clipPath id="clip0_941_3689">
                      <rect width="30" height="30" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                  </defs>
                </svg>

                <div className=" flex flex-col items-start gap-[3px]  ">
                  <h4 className=" text-[#fff] font-montserrat font-medium text-[10px] tracking-[0.9px]  ">GET IT ON</h4>
                  <h2 className="text-[#fff] whitespace-nowrap font-raleway font-semibold text-[24px] tracking-[1px] ">Google Play</h2>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
