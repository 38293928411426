import { useState } from "react";
import logo from "../images/Logo.svg";
import PrivacyPolicy from "../Policy&Terms/PrivacyPolicy";
import TermsOfAgreement from "../Policy&Terms/TermsOfAgreement";

function Footer() {
  const [terms, setTerms] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const openTerms = () => {
    setTerms(true);
  };
  const closeTerms = () => {
    setTerms(false);
  };
  const date = new Date().getFullYear();

  return (
    <>
      {/* BIG SCREENS FROM 1280PX */}
      <div className=" 100:hidden xl:flex w-full h-fit justify-center items-center mt-[60px]">
        <div className="w-[90%] py-[20px] gap-[40px] border-t-[0.7px] flex flex-col justify-between items-center border-solid border-t-[#BDC3C7] ">
          <div className="flex justify-between items-center w-full">
            <img className="w-[30px]" src={logo} alt="KOLeT" />
            <div className="flex justify-between items-center gap-[30px]">
              <a title="Instagram" className="text-[13px] text-[#333] font-raleway font-semibold underline tracking-[0.9px] " href="/">
                Instagram
              </a>
              <a title="X" className="text-[13px] text-[#333] font-raleway font-semibold underline tracking-[0.9px] " href="/">
                x
              </a>
              <a title="Linkedin" className="text-[13px] text-[#333] font-raleway font-semibold underline tracking-[0.9px] " href="/">
                Linkedin
              </a>
            </div>
          </div>
          <div className="w-full float-left">
            <p className="text-[20px] w-[90%]   text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[38px] ">
              KOLeT is your trusted partner for seamless and secure financial transactions. We are dedicated to providing a user-friendly platform that redefines the way you manage your finances. Join us on this journey towards effortless payments
              and unparalleled financial convenience.
              <span
                onClick={() => {
                  openModal();
                  closeTerms();
                }}
                className=" cursor-pointer text-[#3498DB] underline"
              >
                {" "}
                Privacy Policy
              </span>{" "}
              and{" "}
              <span
                onClick={() => {
                  closeModal();
                  openTerms();
                }}
                className="text-[#3498DB] cursor-pointer underline"
              >
                Terms of Agreement
              </span>{" "}
            </p>
          </div>
          <div>
            <h3 className="text-[14px] text-[#999] font-raleway font-normal tracking-[0.9px] ">Copyright: © {date} KOLeT. All rights reserved</h3>
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1279PX */}
      <div className=" 100:flex   xl:hidden flex-col w-full h-fit justify-center items-center mt-[60px]">
        <div className="w-[90%] py-[20px] gap-[40px] border-t-[0.7px] flex flex-col justify-between items-center border-solid border-t-[#BDC3C7] ">
          <div className="flex justify-between items-center w-full">
            <img className="w-[30px]" src={logo} alt="KOLeT" />
            <div className="flex justify-between items-center gap-[30px]">
              <a title="Instagram" className="text-[13px] text-[#333] font-raleway font-semibold underline tracking-[0.9px] " href="/">
                Instagram
              </a>
              <a title="X" className="text-[13px] text-[#333] font-raleway font-semibold underline tracking-[0.9px] " href="/">
                x
              </a>
              <a title="Linkedin" className="text-[13px] text-[#333] font-raleway font-semibold underline tracking-[0.9px] " href="/">
                Linkedin
              </a>
            </div>
          </div>
          <div className="w-full float-left">
            <p className="text-[20px]    text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[38px] ">
              KOLeT is your trusted partner for seamless and secure financial transactions. We are dedicated to providing a user-friendly platform that redefines the way you manage your finances. Join us on this journey towards effortless payments
              and unparalleled financial convenience.
              <span
                onClick={() => {
                  // setPrivacyPolicy(true);
                  openModal();
                  closeTerms();
                }}
                className=" cursor-pointer text-[#3498DB] underline"
              >
                {" "}
                Privacy Policy
              </span>{" "}
              and{" "}
              <span
                onClick={() => {
                  // setPrivacyPolicy(false);
                  closeModal();
                  openTerms();
                }}
                className="text-[#3498DB] cursor-pointer underline"
              >
                Terms of Agreement
              </span>{" "}
            </p>
          </div>
          <div>
            <h3 className="text-[14px] text-[#999] font-raleway font-normal tracking-[0.9px] ">Copyright: © {date} KOLeT. All rights reserved</h3>
          </div>
        </div>
      </div>
      {isModalOpen && <PrivacyPolicy closeModal={closeModal} />}
      {terms && <TermsOfAgreement closeTerms={closeTerms} />}
    </>
  );
}

export default Footer;
