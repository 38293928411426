import { useState } from "react";
import PrivacyPolicy from "../Policy&Terms/PrivacyPolicy";
import TermsOfAgreement from "../Policy&Terms/TermsOfAgreement";
import ContactInfo from "../Policy&Terms/ContactInfo";

export const AdminFooter = () => {
  const [terms, setTerms] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [contact, setContact] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const openTerms = () => {
    setTerms(true);
  };
  const closeTerms = () => {
    setTerms(false);
  };
  const date = new Date().getFullYear();
  return (
    <>
      {/* BIG SCREENS FROM 1280PX */}
      <div className=" 100:hidden w-full h-[200px] xl:flex justify-end items-center float-right">
        <h3 className="text-[14px] text-[#999] font-raleway font-normal tracking-[0.9px] ">
          Copyright: © {date} KOLeT. All rights reserved.{" "}
          <span
            onClick={() => {
              openModal();
              closeTerms();
            }}
            className=" cursor-pointer text-[#3498DB] "
          >
            {" "}
            Privacy Policy |{" "}
          </span>
          <span
            onClick={() => {
              closeModal();
              openTerms();
            }}
            className="text-[#3498DB] cursor-pointer "
          >
            Terms of Agreement |
          </span>{" "}
          <span onClick={() => setContact(true)} className="text-[#3498DB] cursor-pointer ">
            Contact Us
          </span>
        </h3>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1279PX */}
      <div className=" 100:flex w-full h-[150px] xl:hidden text-center items-center ">
        <h3 className="text-[12px] leading-[25px] text-[#999] font-raleway font-normal tracking-[0.9px] ">
          Copyright: © {date} KOLeT. All rights reserved.{" "}
          <span
            onClick={() => {
              openModal();
              closeTerms();
            }}
            className=" cursor-pointer text-[#3498DB] "
          >
            {" "}
            Privacy Policy |{" "}
          </span>
          <span
            onClick={() => {
              closeModal();
              openTerms();
            }}
            className="text-[#3498DB] cursor-pointer "
          >
            Terms of Agreement |
          </span>{" "}
          <span onClick={() => setContact(true)} className="text-[#3498DB] cursor-pointer ">
            Contact Us
          </span>
        </h3>
      </div>
      {isModalOpen && <PrivacyPolicy closeModal={closeModal} />}
      {terms && <TermsOfAgreement closeTerms={closeTerms} />}
      {contact && <ContactInfo setContact={setContact} />}
    </>
  );
};
