import { useState } from "react";
import { Link } from "react-router-dom";

export const OtpForgotPassword = () => {
  const [otp, setOtp] = useState("");
  const [otpBox2, setOtpBox2] = useState("");
  const [otpBox3, setOtpBox3] = useState("");
  const [otpBox4, setOtpBox4] = useState("");

  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");
    if (inputValue.length <= 1) {
      setOtp(inputValue);
    }
  };
  const handleInputChange2 = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");
    if (inputValue.length <= 1) {
      setOtpBox2(inputValue);
    }
  };
  const handleInputChange3 = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");
    if (inputValue.length <= 1) {
      setOtpBox3(inputValue);
    }
  };
  const handleInputChange4 = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");
    if (inputValue.length <= 1) {
      setOtpBox4(inputValue);
    }
  };
  return (
    <>
      {/* BIG SCREENS FROM 1280PX */}
      <div className=" 100:hidden xl:flex w-full px-[50px] gap-[100px] flex-col flex justify-center items-center ">
        <div className=" w-full items-center flex justify-between h-[84.15px]">
          <Link to="/forgotpassword">
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.3335 17H23.6668" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14.5001 21.1666C14.5001 21.1666 10.3335 18.0979 10.3335 16.9999C10.3335 15.9019 14.5002 12.8333 14.5002 12.8333" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Link>
        </div>
        <div className=" w-[424px] h-fit flex flex-col p-[40px] gap-[40px] justify-between items-center rounded-[20px] custom-box-shadow  ">
          <div className=" flex flex-col items-center text-center justify-between gap-[10px] w-[308px]">
            <h3 className="text-[25px] font-semibold tracking-[0.9px] text-[#333] font-raleway ">Enter the Code</h3>
            <p className="text-[15px] text-[#777] tracking-[0.9px] font-montserrat font-normal leading-[25px] ">Check your email or phone for the verification code and enter it below.</p>
          </div>
          <form className=" flex flex-col justify-between items-center gap-[10px]">
            <div className=" flex items-center gap-[10px] justify-between">
              <input
                className="h-[50px] outline-[1px] font-montserrat outline-[#3498DB] tracking-[0.9px] font-medium text-[24px] text-center text-[#999] px-[10px] items-center w-[50px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7]"
                type="tel"
                maxLength="1"
                value={otp}
                onChange={handleInputChange}
              />
              <input
                className="h-[50px] outline-[1px] font-montserrat outline-[#3498DB] tracking-[0.9px] font-medium text-[24px] text-center text-[#999] px-[10px] items-center w-[50px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7]"
                type="tel"
                maxLength="1"
                value={otpBox2}
                onChange={handleInputChange2}
              />{" "}
              <input
                className="h-[50px] outline-[1px] font-montserrat outline-[#3498DB] tracking-[0.9px] font-medium text-[24px] text-center text-[#999] px-[10px] items-center w-[50px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7]"
                type="tel"
                maxLength="1"
                value={otpBox3}
                onChange={handleInputChange3}
              />{" "}
              <input
                className="h-[50px] outline-[1px] font-montserrat outline-[#3498DB] tracking-[0.9px] font-medium text-[24px] text-center text-[#999] px-[10px] items-center w-[50px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7]"
                type="tel"
                maxLength="1"
                value={otpBox4}
                onChange={handleInputChange4}
              />
            </div>
            <p className="text-[13px] text-[#333] font-normal font-montserrat">Remaining 00:30 Sec</p>
          </form>

          <div className="flex flex-col  text-center w-[302px] justify-between items-center gap-[10px] ">
            <h3 className="text-[14px] leading-[24px] font-raleway font-medium text-[#333] tracking-[0.9px] ">
              Didn't receive the code? Tap to resend it to your registered{" "}
              <Link to="/forgotpassword" className="text-[#3498DB] cursor-pointer">
                Number
              </Link>{" "}
              or{" "}
              <Link to="/forgotpassword" className="text-[#3498DB] cursor-pointer">
                Email.
              </Link>{" "}
            </h3>

            <Link to="/createpassword">
              <button className="w-[243px] h-[38px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Next</button>
            </Link>
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1279PX */}
      <div className="100:flex w-full h-fit gap-[100px] flex-col px-[50px] pb-[40px] xl:hidden justify-center items-center ">
        <div className=" w-full items-center flex justify-between h-[84.15px]">
          <Link to="/forgotpassword">
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.3335 17H23.6668" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14.5001 21.1666C14.5001 21.1666 10.3335 18.0979 10.3335 16.9999C10.3335 15.9019 14.5002 12.8333 14.5002 12.8333" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Link>
        </div>
        <div className=" w-[90%] sm:w-[424px] h-fit flex flex-col p-[40px] gap-[40px] justify-between items-center rounded-[20px] custom-box-shadow  ">
          <div className=" flex flex-col items-center text-center justify-between gap-[10px] w-[308px]">
            <h3 className="text-[25px] font-semibold tracking-[0.9px] text-[#333] font-raleway ">Enter the Code</h3>
            <p className="text-[15px] text-[#777] tracking-[0.9px] font-montserrat font-normal leading-[25px] ">Check your email or phone for the verification code and enter it below.</p>
          </div>
          <form className=" flex flex-col justify-between items-center gap-[10px]">
            <div className=" flex items-center gap-[10px] justify-between">
              <input
                className="h-[50px] outline-[1px] font-montserrat outline-[#3498DB] tracking-[0.9px] font-medium text-[24px] text-center text-[#999] px-[10px] items-center w-[50px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7]"
                type="tel"
                maxLength="1"
                value={otp}
                onChange={handleInputChange}
              />
              <input
                className="h-[50px] outline-[1px] font-montserrat outline-[#3498DB] tracking-[0.9px] font-medium text-[24px] text-center text-[#999] px-[10px] items-center w-[50px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7]"
                type="tel"
                maxLength="1"
                value={otpBox2}
                onChange={handleInputChange2}
              />{" "}
              <input
                className="h-[50px] outline-[1px] font-montserrat outline-[#3498DB] tracking-[0.9px] font-medium text-[24px] text-center text-[#999] px-[10px] items-center w-[50px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7]"
                type="tel"
                maxLength="1"
                value={otpBox3}
                onChange={handleInputChange3}
              />{" "}
              <input
                className="h-[50px] outline-[1px] font-montserrat outline-[#3498DB] tracking-[0.9px] font-medium text-[24px] text-center text-[#999] px-[10px] items-center w-[50px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7]"
                type="tel"
                maxLength="1"
                value={otpBox4}
                onChange={handleInputChange4}
              />
            </div>
            <p className="text-[13px] text-[#333] font-normal font-montserrat">Remaining 00:30 Sec</p>
          </form>

          <div className="flex flex-col  text-center w-full justify-between items-center gap-[10px] ">
            <h3 className="text-[14px] leading-[24px] font-raleway font-medium text-[#333] tracking-[0.9px] ">
              Didn't receive the code? Tap to resend it to your registered{" "}
              <Link to="/forgotpassword" className="text-[#3498DB] cursor-pointer">
                Number
              </Link>{" "}
              or{" "}
              <Link to="/forgotpassword" className="text-[#3498DB] cursor-pointer">
                Email.
              </Link>{" "}
            </h3>

            <Link to="/createpassword">
              <button className="w-[200px] h-[38px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Next</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
