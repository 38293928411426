function NewsLetter() {
  return (
    <>
      {/* BIG SCREENS FROM 1280PX */}
      <div className=" 100:hidden  h-fit py-[60px]  mt-[60px] xl:flex w-full justify-center items-center ">
        <div className=" flex flex-col justify-center w-[609px] items-center">
          <div className="flex flex-col w-[550px] text-center items-center justify-center gap-[10px]">
            <h3 className=" text-[#333]   leading-[70px] text-[48px] font-raleway tracking-[0.9px] font-semibold">Sign up to get our Newsletter</h3>
            <p className="text-[22px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[38px] ">Enter your email to stay informed about the latest news, insight and trend on KOLeT</p>
            <div className="flex w-[530px] justify-between gap-[10px] items-center">
              <input
                className=" px-[16px]  rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] font-montserrat outline-[1px] outline-[#3498DB] w-full h-[50px] tracking-[0.9px] font-normal text-[14px] text-[#999] "
                type="email"
                placeholder="Email Address"
              />
              <button className="w-[133px] h-[50px] rounded-[4px] px-[20px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Subscribe</button>
            </div>
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1279PX */}
      <div className=" 100:flex  h-fit py-[60px]  mt-[60px] xl:hidden w-full justify-center items-center ">
        <div className=" flex flex-col justify-center w-[90%] phone:w-[450px] items-center">
          <div className="flex flex-col w-full text-center items-center justify-center gap-[10px]">
            <h3 className=" text-[#333]   leading-[48px] text-[32px] font-raleway tracking-[0.9px] font-semibold">Sign up to get our Newsletter</h3>
            <p className="text-[18px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[32px] ">Enter your email to stay informed about the latest news, insight and trend on KOLeT</p>
            <div className="flex w-full justify-between gap-[10px] items-center">
              <input
                className=" px-[16px] outline-[1px] outline-[#3498DB]  rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] font-montserrat  w-full h-[50px] tracking-[0.9px] font-normal text-[14px] text-[#999] "
                type="email"
                placeholder="Email Address"
              />
              <button className="w-[133px] h-[50px] rounded-[4px] px-[20px] text-[16px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Subscribe</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsLetter;
