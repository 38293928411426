function TermsOfAgreement({ closeTerms }) {
  return (
    <>
      {/* BIG SCREENS FROM 1280PX */}
      <div className=" bg-black 100:hidden xl:flex  fixed z-40 justify-center items-center bg-opacity-55 w-full h-full left-0 top-0  ">
        <div onClick={closeTerms} className=" cursor-pointer  flex justify-center items-center w-[60px] absolute right-[40px] top-[40px] h-[60px] bg-[#494B48] rounded-[80px]">
          <svg className=" " width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 5L5 19M5 5L19 19" stroke="#F5F5F5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>

        <div className=" w-[800px] flex flex-col items-center justify-between gap-[40px] px-[40px] py-[50px] h-[700px] rounded-[20px] bg-[#f5f5f5]">
          <h3 className=" font-raleway tracking-tight font-semibold  text-[20px] text-[#333]">Terms and Conditions</h3>
          <div className=" flex flex-col justify-between gap-[20px] overflow-y-scroll  text-left ">
            <p className=" text-[#777] font-montserrat font-normal text-[14px] tracking-tight leading-[25px] ">
              We are Fitchco Technology Solution Limited, doing business as Fitchco ('Company', 'we', 'us', or 'our'), a company registered in Nigeria at CHIEF ORE FAMEWO STREET, OFF KUSENLAROAD, Ikate, Lekki, Lagos.{" "}
            </p>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>We operate the mobile application Kolet (the 'App'), as well as any other related products and services that refer or link to these legal terms (the 'Legal Terms') (collectively, the 'Services'). </p>
            </div>

            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>We facilitate payment of services with ease by transforming your mobile device to a POS terminal using NFC technology in line with CBN regulations. </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                You can contact us by phone at +234 706 636 6375, email at{" "}
                <a className=" cursor-pointer text-[#3498DB]" href="mailto:info@fitchcong.com,">
                  info@fitchcong.com
                </a>
                , or by mail to CHIEF ORE FAMEWO STREET, OFF KUSENLA ROAD, Ikate, Lekki, Lagos, Nigeria.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ('you'), and Fitchco Technology Solution Limited, concerning your access to and use of the Services. You agree
                that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST
                DISCONTINUE USE IMMEDIATELY.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                We will provide you with prior notice of any scheduled changes to the Services you are using. The modified Legal Terms will become effective upon posting or notifying you by no-reply@fitchcong.com, as stated in the email message. By
                continuing to use the Services after the effective date of any changes, you agree to be bound by the modified terms.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>The Services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the Services. </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>We recommend that you print a copy of these Legal Terms for your records. </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">1. OUR SERVICES:</h3>
              <p>
                {" "}
                The information provided when using the Services is not intended for distribution or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would
                subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance
                with local laws, if and to the extent local laws are applicable.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">2. INTELLECTUAL PROPERTY RIGHTS: </h3>
              <p>
                {" "}
                <h3 className=" font-normal  text-[#333] ">Our intellectual property</h3>
                We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services
                (collectively, the 'Content'), as well as the trademarks, service marks, and logos contained therein (the 'Marks').
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) and treaties in the United States and around the world. The Content and Marks are
                provided in or through the Services 'AS IS' for your personal, non-commercial use or internal business purpose only.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-normal  text-[#333] "> Account Information</h3>
              <p>
                If you would at any time like to review or change the information in your account or terminate your account, you can: Upon your request to terminate your account, we will deactivate or delete your account and information from our
                active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-normal  text-[#333] "> Your use of our Services</h3>
              <p>
                Subject to your compliance with these Legal Terms, including the 'PROHIBITED ACTIVITIES' section below, we grant you a non-exclusive, non-transferable, revocable license to: access the Services; and download or print a copy of any
                portion of the Content to which you have properly gained access solely for your personal, non-commercial use or internal business purpose.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated,
                transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to: info@fitchcong.com. If we ever grant you the permission to post,
                reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on
                posting, reproducing, or displaying our Content.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                We reserve all rights not expressly granted to you in and to the Services, Content, and Marks. Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our Services
                will terminate immediately.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                Please review this section and the 'PROHIBITED ACTIVITIES' section carefully prior to using our Services to understand the (a) rights you give us and (b) obligations you have when you post or upload any content through the Services.
                Submissions: By directly sending us any question, comment, suggestion, idea, feedback, or other information about the Services ('Submissions'), you agree to assign to us all intellectual property rights in such Submission. You agree
                that we shall own this Submission and be entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you. You are responsible for what you post or
                upload: By sending us Submissions through any part of the Services you:
              </p>
              <br />
              <p>
                confirm that you have read and agree with our 'PROHIBITED ACTIVITIES' and will not post, send, publish, upload, or transmit through the Services any Submission that is illegal, harassing, hateful, harmful, defamatory, obscene,
                bullying, abusive, discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or misleading; to the extent permissible by applicable law, waive any and all moral rights to any such Submission;
                warrant that any such Submission are original to you or that you have the necessary rights and licenses to submit such Submissions and that you have full authority to grant us the above-mentioned rights in relation to your
                Submissions; and warrant and represent that your Submissions do not constitute confidential information.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                You are solely responsible for your Submissions and you expressly agree to reimburse us for any and all losses that we may suffer because of your breach of (a)this section, (b) any third party’s intellectual property rights, or (c)
                applicable law.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">3. USER REPRESENTATIONS: </h3>
              <p>By using the Services, you represent and warrant that: </p>
              <br />
              <p>
                (1) all registration information you submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such registration information as necessary; (3) you have the legal
                capacity and you agree to comply with these Legal Terms; (4) you are not a minor in the jurisdiction in which you reside; (5) you will not access the Services through automated or non-human means, whether through a bot, script or
                otherwise; (6) you will not use the Services for any illegal or unauthorized purpose; and (7) your use of the Services will not violate any applicable law or regulation.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof).</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">4. USER REGISTRATION: </h3>

              <p>
                You may be required to register to use the Services. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you
                select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">5. PURCHASE AND PAYMENT: </h3>

              <p>
                We accept the following forms of payment:
                <br />
                - Visa
                <br />
                - Mastercard
                <br />
                - Verve
                <br />
                You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Services. You further agree to promptly update account and payment information, including email address, payment
                method, and payment card expiration date, so that we can complete your transactions and contact you as needed. Sales tax will be added to the price of purchases as deemed required by us. We may change prices at any time. All payments
                shall be in Naira.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping fees, and you authorize us to charge your chosen payment provider for any such amounts upon placing your order.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                If your order is subject to recurring charges, then you consent to our charging your payment method on a recurring basis without requiring your prior approval for each recurring charge, until such time as you cancel the applicable
                order. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or cancel quantities purchased per person, per household, or per order. These restrictions may include orders placed by or
                under the same customer account, the same payment method, and/or orders that use the same billing or shipping address. We reserve the right to limit or prohibit orders that, in our sole judgement, appear to be placed by dealers,
                resellers, or distributors.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">6. POLICY: </h3>

              <p>All sales are final and no refund will be issued.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">7. PROHIBITED ACTIVITIES: </h3>

              <p>
                You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavours except those that are specifically endorsed or
                approved by us.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                As a user of the Services, you agree not to: Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission
                from us. Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the
                Content contained therein.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Use any information obtained from the Services in order to harass, abuse, or harm another person.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Make improper use of our support services or submit false reports of abuse or misconduct.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Engage in unauthorized framing of or linking to the Services.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s
                uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools. </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Delete the copyright or other proprietary rights notice from any Content. </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Attempt to impersonate another user or person or use the username of another user. </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats ('gifs'),
                1×1pixels, web bugs, cookies, or other similar devices (sometimes referred to as 'spyware' or 'passive collection mechanisms' or 'pcms').{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Services.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that
                accesses the Services, or use or launch any unauthorized script or other software.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                Make any unauthorized use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under
                false pretenses.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">8. USER GENERATED CONTRIBUTIONS: </h3>

              <p>
                The Services does not offer users to submit or post content. We may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the
                Services, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, 'Contributions'). Contributions may be viewable by other users
                of the Services and through third-party websites. When you create or make available any Contributions, you thereby represent and warrant that:
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating endeavour or commercial enterprise. Sell or otherwise transfer your profile.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the
                copyright, patent, trademark, trade secret, or moral rights of any third party.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the Services, and other users of the Services to use your Contributions in any manner
                contemplated by the Services and these Legal Terms.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and
                use of your Contributions in any manner contemplated by the Services and these Legal Terms.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions are not false, inaccurate, or misleading.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libellous, slanderous, or otherwise objectionable (as determined by us).</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions do not violate any applicable law, regulation, or rule.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions do not violate the privacy or publicity rights of any third-party.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions do not otherwise violate, or link to material that violates, any provision of these Legal Terms, or any applicable law or regulation.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Any use of the Services in violation of the foregoing violates these Legal Terms and may result in, among other things, termination or suspension of your rights to use the Services.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">9. CONTRIBUTION LICENCE: </h3>

              <p>
                You and Services agree that we may access, store, process, and use any information and personal data that you provide and your choices (including settings). By submitting suggestions or other feedback regarding the Services, you agree
                that we can use and share such feedback for any purpose without compensation to you.
              </p>
              <p>By submitting suggestions or other feedback regarding the Services, you agree that we can use and share such feedback for any purpose without compensation to you.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for
                any statements or representations in your Contributions provided by you in any area on the Services. You are solely responsible for your Contributions to the Services and you expressly agree to exonerate us from any and all
                responsibility and to refrain from any legal action against us regarding your Contributions{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">10. GUIDELINES FOR REVIEWS: </h3>

              <p>
                We may provide you areas on the Services to leave reviews or ratings. When posting a review, you must comply with the following criteria: (1) you should have firsthand experience with the person/entity being reviewed; (2) your reviews
                should not contain offensive profanity, or abusive, racist, offensive, or hateful language; (3) your reviews should not contain discriminatory references based on religion, race, gender, national origin, age, marital status, sexual
                orientation, or disability; (4) your reviews should not contain references to illegal activity; (5) you should not be affiliated with competitors if posting negative reviews; (6) you should not make any conclusions as to the legality
                of conduct; (7) you may not post any false or misleading statements; and (8) you may not organise a campaign encouraging others to post reviews, whether positive or negative.
              </p>
              <p>
                We may accept, reject, or remove reviews in our sole discretion. We have absolutely no obligation to screen reviews or to delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews are not endorsed by us,
                and do not necessarily represent our opinions or the views of any of our affiliates or partners. We do not assume liability for any review or for any claims, liabilities, or losses resulting from any review. By posting a review, you
                hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free, fully paid, assignable, and sublicensable right and license to reproduce, modify, translate, transmit by any means, display, perform, and/or distribute all
                content relating to review.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">11. GUIDELINES FOR REVIEWS: </h3>
              <h3 className=" font-normal  text-[#333] ">Use License</h3>

              <p>
                If you access the Services via the App, then we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the App on wireless electronic devices owned or controlled by you, and to access and use the App
                on such devices strictly in accordance with the terms and conditions of this mobile application license contained in these Legal Terms. You shall not: (1) except as permitted by applicable law, decompile, reverse engineer,
                disassemble, attempt to derive the source code of, or decrypt the App; (2) make any modification, adaptation, improvement, enhancement, translation, or derivative work from the App; (3) violate any applicable laws, rules, or
                regulations in connection with your access or use of the App; (4) remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted by us or the licensors of the App; (5) use the App for any
                revenue-generating endeavour, commercial enterprise, or other purpose for which it is not designed or intended; (6) make the App available over a network or other environment permitting access or use by multiple devices or users at
                the same time;(7) use the App for creating a product, service, or software that is, directly or indirectly, competitive with or in any way a substitute for the App; (8) use the App to send automated queries to any website or to send
                any unsolicited commercial email; or (9) use any proprietary information or any of our interfaces or our other intellectual property in the design, development, manufacture, licensing, or distribution of any applications, accessories,
                or devices for use with the App.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">11. GUIDELINES FOR REVIEWS: </h3>
              <h3 className=" font-normal  text-[#333] ">Use License</h3>

              <p>
                If you access the Services via the App, then we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the App on wireless electronic devices owned or controlled by you, and to access and use the App
                on such devices strictly in accordance with the terms and conditions of this mobile application license contained in these Legal Terms. You shall not: (1) except as permitted by applicable law, decompile, reverse engineer,
                disassemble, attempt to derive the source code of, or decrypt the App; (2) make any modification, adaptation, improvement, enhancement, translation, or derivative work from the App; (3) violate any applicable laws, rules, or
                regulations in connection with your access or use of the App; (4) remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted by us or the licensors of the App; (5) use the App for any
                revenue-generating endeavour, commercial enterprise, or other purpose for which it is not designed or intended; (6) make the App available over a network or other environment permitting access or use by multiple devices or users at
                the same time;(7) use the App for creating a product, service, or software that is, directly or indirectly, competitive with or in any way a substitute for the App; (8) use the App to send automated queries to any website or to send
                any unsolicited commercial email; or (9) use any proprietary information or any of our interfaces or our other intellectual property in the design, development, manufacture, licensing, or distribution of any applications, accessories,
                or devices for use with the App. Apple and Android Devices The following terms apply when you use the App obtained from either the Apple Store or Google Play (each an 'App Distributor') to access the Services: (1) the license granted
                to you for our App is limited to a non-transferable license to use the application on a device that utilizes the Apple iOS or Android operating systems, as applicable, and in accordance with the usage rules set forth in the applicable
                App Distributor’s terms of service; (2) we are responsible for providing any maintenance and support services with respect to the App as specified in the terms and conditions of this mobile application license contained in these Legal
                Terms or as otherwise required under applicable law, and you acknowledge that each App Distributor has no obligation whatsoever to furnish any maintenance and support services with respect to the App; (3) in the event of any failure
                of the App to conform to any applicable warranty, you may notify the applicable App Distributor, and the App Distributor, in accordance with its terms and policies, may refund the purchase price, if any, paid for the App, and to the
                maximum extent permitted by applicable law, the App Distributor will have no other warranty obligation whatsoever with respect to the App; (4) you represent and warrant that (i) you are not located in a country that is subject to a US
                government embargo, or that has been designated by the US government as a 'terrorist supporting' country and (ii) you are not listed on any US government list of prohibited or restricted parties; (5) you must comply with applicable
                third-party terms of agreement when using the App, e.g. if you have a VoIP application, then you must not be in violation of their wireless data service agreement when using the App; and (6) you acknowledge and agree that the App
                Distributors are third-party beneficiaries of the terms and conditions in this mobile application license contained in these Legal Terms, and that each App Distributor will have the right (and will be deemed to have accepted the
                right) to enforce the terms and conditions in this mobile application license contained in these Legal Terms against you as a third-party beneficiary thereof.GUIDELINES FOR REVIEWS: Use License If you access the Services via the App,
                then we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the App on wireless electronic devices owned or controlled by you, and to access and use the App on such devices strictly in accordance
                with the terms and conditions of this mobile application license contained in these Legal Terms. You shall not: (1) except as permitted by applicable law, decompile, reverse engineer, disassemble, attempt to derive the source code of,
                or decrypt the App; (2) make any modification, adaptation, improvement, enhancement, translation, or derivative work from the App; (3) violate any applicable laws, rules, or regulations in connection with your access or use of the
                App; (4) remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted by us or the licensors of the App; (5) use the App for any revenue-generating endeavour, commercial enterprise, or other
                purpose for which it is not designed or intended; (6) make the App available over a network or other environment permitting access or use by multiple devices or users at the same time;(7) use the App for creating a product, service,
                or software that is, directly or indirectly, competitive with or in any way a substitute for the App; (8) use the App to send automated queries to any website or to send any unsolicited commercial email; or (9) use any proprietary
                information or any of our interfaces or our other intellectual property in the design, development, manufacture, licensing, or distribution of any applications, accessories, or devices for use with the App. Apple and Android Devices
                The following terms apply when you use the App obtained from either the Apple Store or Google Play (each an 'App Distributor') to access the Services: (1) the license granted to you for our App is limited to a non-transferable license
                to use the application on a device that utilizes the Apple iOS or Android operating systems, as applicable, and in accordance with the usage rules set forth in the applicable App Distributor’s terms of service; (2) we are responsible
                for providing any maintenance and support services with respect to the App as specified in the terms and conditions of this mobile application license contained in these Legal Terms or as otherwise required under applicable law, and
                you acknowledge that each App Distributor has no obligation whatsoever to furnish any maintenance and support services with respect to the App; (3) in the event of any failure of the App to conform to any applicable warranty, you may
                notify the applicable App Distributor, and the App Distributor, in accordance with its terms and policies, may refund the purchase price, if any, paid for the App, and to the maximum extent permitted by applicable law, the App
                Distributor will have no other warranty obligation whatsoever with respect to the App; (4) you represent and warrant that (i) you are not located in a country that is subject to a US government embargo, or that has been designated by
                the US government as a 'terrorist supporting' country and (ii) you are not listed on any US government list of prohibited or restricted parties; (5) you must comply with applicable third-party terms of agreement when using the App,
                e.g. if you have a VoIP application, then you must not be in violation of their wireless data service agreement when using the App; and (6) you acknowledge and agree that the App Distributors are third-party beneficiaries of the terms
                and conditions in this mobile application license contained in these Legal Terms, and that each App Distributor will have the right (and will be deemed to have accepted the right) to enforce the terms and conditions in this mobile
                application license contained in these Legal Terms against you as a third-party beneficiary thereof.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-normal  text-[#333] ">Apple and Android Devices</h3>

              <p>
                The following terms apply when you use the App obtained from either the Apple Store or Google Play (each an 'App Distributor') to access the Services: (1) the license granted to you for our App is limited to a non-transferable license
                to use the application on a device that utilizes the Apple iOS or Android operating systems, as applicable, and in accordance with the usage rules set forth in the applicable App Distributor’s terms of service; (2) we are responsible
                for providing any maintenance and support services with respect to the App as specified in the terms and conditions of this mobile application license contained in these Legal Terms or as otherwise required under applicable law, and
                you acknowledge that each App Distributor has no obligation whatsoever to furnish any maintenance and support services with respect to the App; (3) in the event of any failure of the App to conform to any applicable warranty, you may
                notify the applicable App Distributor, and the App Distributor, in accordance with its terms and policies, may refund the purchase price, if any, paid for the App, and to the maximum extent permitted by applicable law, the App
                Distributor will have no other warranty obligation whatsoever with respect to the App; (4) you represent and warrant that (i) you are not located in a country that is subject to a US government embargo, or that has been designated by
                the US government as a 'terrorist supporting' country and (ii) you are not listed on any US government list of prohibited or restricted parties; (5) you must comply with applicable third-party terms of agreement when using the App,
                e.g. if you have a VoIP application, then you must not be in violation of their wireless data service agreement when using the App; and (6) you acknowledge and agree that the App Distributors are third-party beneficiaries of the terms
                and conditions in this mobile application license contained in these Legal Terms, and that each App Distributor will have the right (and will be deemed to have accepted the right) to enforce the terms and conditions in this mobile
                application license contained in these Legal Terms against you as a third-party beneficiary thereof.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">12. THIRD-PARTY WEBSITES AND CONTENT: </h3>

              <p>
                The Services may contain (or you may be sent via the App) links to other websites ('Third-Party Websites') as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software,
                and other content or items belonging to or originating from third parties ('Third-Party Content'). Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or
                completeness by us, and we are not responsible for any Third-Party Websites accessed through the Services or any Third-Party Content posted on, available through, or installed from the Services, including the content, accuracy,
                offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or permitting the use or installation of any Third-Party
                Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the Services and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own
                risk, and you should be aware these Legal Terms no longer govern. You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigate from the Services or relating
                to any applications you use or install from the Services. Any purchases you make through Third-Party Websites will be through other websites and from other companies, and we take no responsibility whatsoever in relation to such
                purchases which are exclusively between you and the applicable third party. You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you shall hold us blameless from any harm caused
                by your purchase of such products or services. Additionally, you shall hold us blameless from any losses sustained by you or harm caused to you relating to or resulting in any way from any Third-Party Content or any contact with
                Third-Party Websites.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">13. SERVICES MANAGEMENT: </h3>

              <p>
                We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal Terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms,
                including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically
                feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content that are excessive in size or
                are in any way burdensome to our systems; and (5) otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">14. PRIVACY POLICY: </h3>

              <p>
                We care about data privacy and security. By using the Services, you agree to be bound by our Privacy Policy posted on the Services, which is incorporated into these Legal Terms. Please be advised the Services are hosted in Nigeria. If
                you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in Nigeria, then through your continued use of the
                Services, you are transferring your data to Nigeria, and you expressly consent to have your data transferred to and processed in Nigeria.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">15. TERMS AND TERMINATION: </h3>

              <p>
                These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY
                ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL
                TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANYTIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of
                the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">16. MODIFICATIONS AND INTERRUPTIONS: </h3>

              <p>
                We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Services. We will not
                be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Services.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors. We reserve
                the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience
                caused by your inability to access or use the Services during any downtime or discontinuance of the Services. Nothing in these Legal Terms will be construed to obligate us to maintain and support the Services or to supply any
                corrections, updates, or releases in connection therewith.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">17. GOVERNING LAW: </h3>

              <p>
                These Legal Terms shall be governed by and defined following the laws of Nigeria. Fitchco Technology Solution Limited and yourself irrevocably consent that the courts of Nigeria shall have exclusive jurisdiction to resolve any dispute
                which may arise in connection with these Legal Terms.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">18. GOVERNING LAW: </h3>
              <h3 className=" font-normal  text-[#333] ">Informal Negotiations </h3>

              <p>
                To expedite resolution and control the cost of any dispute, controversy, or claim related to these Legal Terms (each a 'Dispute' and collectively, the 'Disputes') brought by either you or us (individually, a 'Party' and collectively,
                the 'Parties'), the Parties agree to first attempt to negotiate any Dispute (except those Disputes expressly provided below) informally for at least thirty (30) days before initiating arbitration. Such informal negotiations commence
                upon written notice from one Party to the other Party.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-normal  text-[#333] ">Binding Arbitration </h3>

              <p>
                Any dispute arising out of or in connection with these Legal Terms, including any question regarding its existence, validity, or termination, shall be referred to and finally resolved by the International Commercial Arbitration Court
                under the European Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146) according to the Rules of this ICAC, which, as a result of referring to it, is considered as the part of this clause. The number of arbitrators shall be
                three (3). The seat, or legal place, or arbitration shall be Lagos, Nigeria. The language of the proceedings shall be English. The governing law of these Legal Terms shall be substantive law of Nigeria.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-normal  text-[#333] ">Restrictions</h3>

              <p>
                The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or
                authority for any Dispute to be arbitrated on a class-action basis or to utilize class action procedures; and (c)there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the
                general public or any other persons.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-normal  text-[#333] ">Exceptions to Informal Negotiations and Arbitration</h3>

              <p>
                The Parties agree that the following Disputes are not subject to the above provisions concerning informal negotiations binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity of, any of the
                intellectual property rights of a Party; (b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive relief. If this provision is found to be
                illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction
                within the courts listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">19. CORRECTIONS: </h3>

              <p>
                There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors,
                inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">20. DISCLAIMER: </h3>

              <p>
                THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION
                WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANT ABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
                COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND
                MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
                AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD
                PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT
                WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR
                OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY
                MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGEMENT AND EXERCISE CAUTION WHERE APPROPRIATE. inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">21. LIMITATIONS OF LIABILITY: </h3>

              <p>
                IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF
                DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER
                AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
                LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">22. IDEMNIFICATION: </h3>

              <p>
                You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand,
                including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) use of the Services; (2) breach of these Legal Terms; (3) any breach of your representations and warranties set forth in these
                Legal Terms; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the Services with whom you connected via the Services.
                Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense
                of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">23. USER DATA: </h3>

              <p>
                We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data relating to your use of the Services. Although we perform regular routine backups of data,
                you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Services. You agree that we shall have no liability to you for any loss or corruption of any such data, and you
                hereby waive any right of action against us arising from any such loss or corruption of such data.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">24. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES: </h3>

              <p>
                Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other
                communications we provide to you electronically, via email and on the Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
                RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or
                other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">25. MISCELLANEOUS </h3>

              <p>
                These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or
                provision of these Legal Terms shall not operate as a waiver of such right or provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time.
                We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Legal Terms is determined to be unlawful, void,
                or unenforceable, that provision or part of the provision is deemed severable from these Legal Terms and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment
                or agency relationship created between you and us as a result of these Legal Terms or use of the Services. You agree that these Legal Terms will not be construed against us by virtue of having drafted them. You hereby waive any and
                all defences you may have based on the electronic form of these Legal Terms and the lack of signing by the parties hereto to execute these Legal Terms.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">26. CONTACT US: </h3>

              <p>
                In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at: Fitchco Technology Solution Limited CHIEF ORE FAMEWO STREET, OFF KUSENLAROAD Ikate, Lekki,
                Lagos Nigeria
                <br />
                Phone: +234 706 636 6375
                <br />
                info@fitchcong.com
              </p>
            </div>
            <div className=" flex-col flex justify-between items-center gap-[10px]">
              <button onClick={closeTerms} className="w-[243px] h-[38px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">
                Agree
              </button>
              <button onClick={closeTerms} className="w-[243px] h-[38px] rounded-[4px] text-[18px] text-[#333] font-raleway border-[1.5px]  border-[#3498DB] tracking-[0.9px]  font-semibold ">
                Decline
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1279PX */}
      <div className=" bg-black 100:flex xl:hidden  fixed z-40 justify-center items-center bg-opacity-55 w-full h-full left-0 top-0  ">
        <div onClick={closeTerms} className=" cursor-pointer  flex justify-center items-center w-[60px] absolute right-[40px] top-[40px] h-[60px] bg-[#494B48] rounded-[80px]">
          <svg className=" " width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 5L5 19M5 5L19 19" stroke="#F5F5F5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>

        <div className=" w-[80%] relative top-[50px] flex flex-col items-center justify-between gap-[40px] px-[30px] py-[50px] h-[550px] rounded-[20px] bg-[#f5f5f5]">
          <h3 className=" font-raleway tracking-tight font-semibold  text-[20px] text-[#333]">Terms and Conditions</h3>
          <div className=" flex flex-col justify-between gap-[20px] overflow-y-scroll  text-left ">
            <p className=" text-[#777] font-montserrat font-normal text-[14px] tracking-tight leading-[25px] ">
              We are Fitchco Technology Solution Limited, doing business as Fitchco ('Company', 'we', 'us', or 'our'), a company registered in Nigeria at CHIEF ORE FAMEWO STREET, OFF KUSENLAROAD, Ikate, Lekki, Lagos.{" "}
            </p>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>We operate the mobile application Kolet (the 'App'), as well as any other related products and services that refer or link to these legal terms (the 'Legal Terms') (collectively, the 'Services'). </p>
            </div>

            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>We facilitate payment of services with ease by transforming your mobile device to a POS terminal using NFC technology in line with CBN regulations. </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                You can contact us by phone at +234 706 636 6375, email at{" "}
                <a className=" cursor-pointer text-[#3498DB]" href="mailto:info@fitchcong.com,">
                  info@fitchcong.com
                </a>
                , or by mail to CHIEF ORE FAMEWO STREET, OFF KUSENLA ROAD, Ikate, Lekki, Lagos, Nigeria.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ('you'), and Fitchco Technology Solution Limited, concerning your access to and use of the Services. You agree
                that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST
                DISCONTINUE USE IMMEDIATELY.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                We will provide you with prior notice of any scheduled changes to the Services you are using. The modified Legal Terms will become effective upon posting or notifying you by no-reply@fitchcong.com, as stated in the email message. By
                continuing to use the Services after the effective date of any changes, you agree to be bound by the modified terms.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>The Services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the Services. </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>We recommend that you print a copy of these Legal Terms for your records. </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">1. OUR SERVICES:</h3>
              <p>
                {" "}
                The information provided when using the Services is not intended for distribution or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would
                subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance
                with local laws, if and to the extent local laws are applicable.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">2. INTELLECTUAL PROPERTY RIGHTS: </h3>
              <p>
                {" "}
                <h3 className=" font-normal  text-[#333] ">Our intellectual property</h3>
                We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services
                (collectively, the 'Content'), as well as the trademarks, service marks, and logos contained therein (the 'Marks').
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) and treaties in the United States and around the world. The Content and Marks are
                provided in or through the Services 'AS IS' for your personal, non-commercial use or internal business purpose only.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-normal  text-[#333] "> Account Information</h3>
              <p>
                If you would at any time like to review or change the information in your account or terminate your account, you can: Upon your request to terminate your account, we will deactivate or delete your account and information from our
                active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-normal  text-[#333] "> Your use of our Services</h3>
              <p>
                Subject to your compliance with these Legal Terms, including the 'PROHIBITED ACTIVITIES' section below, we grant you a non-exclusive, non-transferable, revocable license to: access the Services; and download or print a copy of any
                portion of the Content to which you have properly gained access solely for your personal, non-commercial use or internal business purpose.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated,
                transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to: info@fitchcong.com. If we ever grant you the permission to post,
                reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on
                posting, reproducing, or displaying our Content.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                We reserve all rights not expressly granted to you in and to the Services, Content, and Marks. Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our Services
                will terminate immediately.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                Please review this section and the 'PROHIBITED ACTIVITIES' section carefully prior to using our Services to understand the (a) rights you give us and (b) obligations you have when you post or upload any content through the Services.
                Submissions: By directly sending us any question, comment, suggestion, idea, feedback, or other information about the Services ('Submissions'), you agree to assign to us all intellectual property rights in such Submission. You agree
                that we shall own this Submission and be entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you. You are responsible for what you post or
                upload: By sending us Submissions through any part of the Services you:
              </p>
              <br />
              <p>
                confirm that you have read and agree with our 'PROHIBITED ACTIVITIES' and will not post, send, publish, upload, or transmit through the Services any Submission that is illegal, harassing, hateful, harmful, defamatory, obscene,
                bullying, abusive, discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or misleading; to the extent permissible by applicable law, waive any and all moral rights to any such Submission;
                warrant that any such Submission are original to you or that you have the necessary rights and licenses to submit such Submissions and that you have full authority to grant us the above-mentioned rights in relation to your
                Submissions; and warrant and represent that your Submissions do not constitute confidential information.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                You are solely responsible for your Submissions and you expressly agree to reimburse us for any and all losses that we may suffer because of your breach of (a)this section, (b) any third party’s intellectual property rights, or (c)
                applicable law.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">3. USER REPRESENTATIONS: </h3>
              <p>By using the Services, you represent and warrant that: </p>
              <br />
              <p>
                (1) all registration information you submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such registration information as necessary; (3) you have the legal
                capacity and you agree to comply with these Legal Terms; (4) you are not a minor in the jurisdiction in which you reside; (5) you will not access the Services through automated or non-human means, whether through a bot, script or
                otherwise; (6) you will not use the Services for any illegal or unauthorized purpose; and (7) your use of the Services will not violate any applicable law or regulation.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof).</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">4. USER REGISTRATION: </h3>

              <p>
                You may be required to register to use the Services. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you
                select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">5. PURCHASE AND PAYMENT: </h3>

              <p>
                We accept the following forms of payment:
                <br />
                - Visa
                <br />
                - Mastercard
                <br />
                - Verve
                <br />
                You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Services. You further agree to promptly update account and payment information, including email address, payment
                method, and payment card expiration date, so that we can complete your transactions and contact you as needed. Sales tax will be added to the price of purchases as deemed required by us. We may change prices at any time. All payments
                shall be in Naira.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping fees, and you authorize us to charge your chosen payment provider for any such amounts upon placing your order.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                If your order is subject to recurring charges, then you consent to our charging your payment method on a recurring basis without requiring your prior approval for each recurring charge, until such time as you cancel the applicable
                order. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or cancel quantities purchased per person, per household, or per order. These restrictions may include orders placed by or
                under the same customer account, the same payment method, and/or orders that use the same billing or shipping address. We reserve the right to limit or prohibit orders that, in our sole judgement, appear to be placed by dealers,
                resellers, or distributors.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">6. POLICY: </h3>

              <p>All sales are final and no refund will be issued.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">7. PROHIBITED ACTIVITIES: </h3>

              <p>
                You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavours except those that are specifically endorsed or
                approved by us.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                As a user of the Services, you agree not to: Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission
                from us. Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the
                Content contained therein.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Use any information obtained from the Services in order to harass, abuse, or harm another person.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Make improper use of our support services or submit false reports of abuse or misconduct.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Engage in unauthorized framing of or linking to the Services.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s
                uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools. </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Delete the copyright or other proprietary rights notice from any Content. </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Attempt to impersonate another user or person or use the username of another user. </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats ('gifs'),
                1×1pixels, web bugs, cookies, or other similar devices (sometimes referred to as 'spyware' or 'passive collection mechanisms' or 'pcms').{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Services.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that
                accesses the Services, or use or launch any unauthorized script or other software.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                Make any unauthorized use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under
                false pretenses.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">8. USER GENERATED CONTRIBUTIONS: </h3>

              <p>
                The Services does not offer users to submit or post content. We may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the
                Services, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, 'Contributions'). Contributions may be viewable by other users
                of the Services and through third-party websites. When you create or make available any Contributions, you thereby represent and warrant that:
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating endeavour or commercial enterprise. Sell or otherwise transfer your profile.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the
                copyright, patent, trademark, trade secret, or moral rights of any third party.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the Services, and other users of the Services to use your Contributions in any manner
                contemplated by the Services and these Legal Terms.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and
                use of your Contributions in any manner contemplated by the Services and these Legal Terms.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions are not false, inaccurate, or misleading.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libellous, slanderous, or otherwise objectionable (as determined by us).</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions do not violate any applicable law, regulation, or rule.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions do not violate the privacy or publicity rights of any third-party.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Your Contributions do not otherwise violate, or link to material that violates, any provision of these Legal Terms, or any applicable law or regulation.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>Any use of the Services in violation of the foregoing violates these Legal Terms and may result in, among other things, termination or suspension of your rights to use the Services.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">9. CONTRIBUTION LICENCE: </h3>

              <p>
                You and Services agree that we may access, store, process, and use any information and personal data that you provide and your choices (including settings). By submitting suggestions or other feedback regarding the Services, you agree
                that we can use and share such feedback for any purpose without compensation to you.
              </p>
              <p>By submitting suggestions or other feedback regarding the Services, you agree that we can use and share such feedback for any purpose without compensation to you.</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for
                any statements or representations in your Contributions provided by you in any area on the Services. You are solely responsible for your Contributions to the Services and you expressly agree to exonerate us from any and all
                responsibility and to refrain from any legal action against us regarding your Contributions{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">10. GUIDELINES FOR REVIEWS: </h3>

              <p>
                We may provide you areas on the Services to leave reviews or ratings. When posting a review, you must comply with the following criteria: (1) you should have firsthand experience with the person/entity being reviewed; (2) your reviews
                should not contain offensive profanity, or abusive, racist, offensive, or hateful language; (3) your reviews should not contain discriminatory references based on religion, race, gender, national origin, age, marital status, sexual
                orientation, or disability; (4) your reviews should not contain references to illegal activity; (5) you should not be affiliated with competitors if posting negative reviews; (6) you should not make any conclusions as to the legality
                of conduct; (7) you may not post any false or misleading statements; and (8) you may not organise a campaign encouraging others to post reviews, whether positive or negative.
              </p>
              <p>
                We may accept, reject, or remove reviews in our sole discretion. We have absolutely no obligation to screen reviews or to delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews are not endorsed by us,
                and do not necessarily represent our opinions or the views of any of our affiliates or partners. We do not assume liability for any review or for any claims, liabilities, or losses resulting from any review. By posting a review, you
                hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free, fully paid, assignable, and sublicensable right and license to reproduce, modify, translate, transmit by any means, display, perform, and/or distribute all
                content relating to review.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">11. GUIDELINES FOR REVIEWS: </h3>
              <h3 className=" font-normal  text-[#333] ">Use License</h3>

              <p>
                If you access the Services via the App, then we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the App on wireless electronic devices owned or controlled by you, and to access and use the App
                on such devices strictly in accordance with the terms and conditions of this mobile application license contained in these Legal Terms. You shall not: (1) except as permitted by applicable law, decompile, reverse engineer,
                disassemble, attempt to derive the source code of, or decrypt the App; (2) make any modification, adaptation, improvement, enhancement, translation, or derivative work from the App; (3) violate any applicable laws, rules, or
                regulations in connection with your access or use of the App; (4) remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted by us or the licensors of the App; (5) use the App for any
                revenue-generating endeavour, commercial enterprise, or other purpose for which it is not designed or intended; (6) make the App available over a network or other environment permitting access or use by multiple devices or users at
                the same time;(7) use the App for creating a product, service, or software that is, directly or indirectly, competitive with or in any way a substitute for the App; (8) use the App to send automated queries to any website or to send
                any unsolicited commercial email; or (9) use any proprietary information or any of our interfaces or our other intellectual property in the design, development, manufacture, licensing, or distribution of any applications, accessories,
                or devices for use with the App.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">11. GUIDELINES FOR REVIEWS: </h3>
              <h3 className=" font-normal  text-[#333] ">Use License</h3>

              <p>
                If you access the Services via the App, then we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the App on wireless electronic devices owned or controlled by you, and to access and use the App
                on such devices strictly in accordance with the terms and conditions of this mobile application license contained in these Legal Terms. You shall not: (1) except as permitted by applicable law, decompile, reverse engineer,
                disassemble, attempt to derive the source code of, or decrypt the App; (2) make any modification, adaptation, improvement, enhancement, translation, or derivative work from the App; (3) violate any applicable laws, rules, or
                regulations in connection with your access or use of the App; (4) remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted by us or the licensors of the App; (5) use the App for any
                revenue-generating endeavour, commercial enterprise, or other purpose for which it is not designed or intended; (6) make the App available over a network or other environment permitting access or use by multiple devices or users at
                the same time;(7) use the App for creating a product, service, or software that is, directly or indirectly, competitive with or in any way a substitute for the App; (8) use the App to send automated queries to any website or to send
                any unsolicited commercial email; or (9) use any proprietary information or any of our interfaces or our other intellectual property in the design, development, manufacture, licensing, or distribution of any applications, accessories,
                or devices for use with the App. Apple and Android Devices The following terms apply when you use the App obtained from either the Apple Store or Google Play (each an 'App Distributor') to access the Services: (1) the license granted
                to you for our App is limited to a non-transferable license to use the application on a device that utilizes the Apple iOS or Android operating systems, as applicable, and in accordance with the usage rules set forth in the applicable
                App Distributor’s terms of service; (2) we are responsible for providing any maintenance and support services with respect to the App as specified in the terms and conditions of this mobile application license contained in these Legal
                Terms or as otherwise required under applicable law, and you acknowledge that each App Distributor has no obligation whatsoever to furnish any maintenance and support services with respect to the App; (3) in the event of any failure
                of the App to conform to any applicable warranty, you may notify the applicable App Distributor, and the App Distributor, in accordance with its terms and policies, may refund the purchase price, if any, paid for the App, and to the
                maximum extent permitted by applicable law, the App Distributor will have no other warranty obligation whatsoever with respect to the App; (4) you represent and warrant that (i) you are not located in a country that is subject to a US
                government embargo, or that has been designated by the US government as a 'terrorist supporting' country and (ii) you are not listed on any US government list of prohibited or restricted parties; (5) you must comply with applicable
                third-party terms of agreement when using the App, e.g. if you have a VoIP application, then you must not be in violation of their wireless data service agreement when using the App; and (6) you acknowledge and agree that the App
                Distributors are third-party beneficiaries of the terms and conditions in this mobile application license contained in these Legal Terms, and that each App Distributor will have the right (and will be deemed to have accepted the
                right) to enforce the terms and conditions in this mobile application license contained in these Legal Terms against you as a third-party beneficiary thereof.GUIDELINES FOR REVIEWS: Use License If you access the Services via the App,
                then we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the App on wireless electronic devices owned or controlled by you, and to access and use the App on such devices strictly in accordance
                with the terms and conditions of this mobile application license contained in these Legal Terms. You shall not: (1) except as permitted by applicable law, decompile, reverse engineer, disassemble, attempt to derive the source code of,
                or decrypt the App; (2) make any modification, adaptation, improvement, enhancement, translation, or derivative work from the App; (3) violate any applicable laws, rules, or regulations in connection with your access or use of the
                App; (4) remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted by us or the licensors of the App; (5) use the App for any revenue-generating endeavour, commercial enterprise, or other
                purpose for which it is not designed or intended; (6) make the App available over a network or other environment permitting access or use by multiple devices or users at the same time;(7) use the App for creating a product, service,
                or software that is, directly or indirectly, competitive with or in any way a substitute for the App; (8) use the App to send automated queries to any website or to send any unsolicited commercial email; or (9) use any proprietary
                information or any of our interfaces or our other intellectual property in the design, development, manufacture, licensing, or distribution of any applications, accessories, or devices for use with the App. Apple and Android Devices
                The following terms apply when you use the App obtained from either the Apple Store or Google Play (each an 'App Distributor') to access the Services: (1) the license granted to you for our App is limited to a non-transferable license
                to use the application on a device that utilizes the Apple iOS or Android operating systems, as applicable, and in accordance with the usage rules set forth in the applicable App Distributor’s terms of service; (2) we are responsible
                for providing any maintenance and support services with respect to the App as specified in the terms and conditions of this mobile application license contained in these Legal Terms or as otherwise required under applicable law, and
                you acknowledge that each App Distributor has no obligation whatsoever to furnish any maintenance and support services with respect to the App; (3) in the event of any failure of the App to conform to any applicable warranty, you may
                notify the applicable App Distributor, and the App Distributor, in accordance with its terms and policies, may refund the purchase price, if any, paid for the App, and to the maximum extent permitted by applicable law, the App
                Distributor will have no other warranty obligation whatsoever with respect to the App; (4) you represent and warrant that (i) you are not located in a country that is subject to a US government embargo, or that has been designated by
                the US government as a 'terrorist supporting' country and (ii) you are not listed on any US government list of prohibited or restricted parties; (5) you must comply with applicable third-party terms of agreement when using the App,
                e.g. if you have a VoIP application, then you must not be in violation of their wireless data service agreement when using the App; and (6) you acknowledge and agree that the App Distributors are third-party beneficiaries of the terms
                and conditions in this mobile application license contained in these Legal Terms, and that each App Distributor will have the right (and will be deemed to have accepted the right) to enforce the terms and conditions in this mobile
                application license contained in these Legal Terms against you as a third-party beneficiary thereof.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-normal  text-[#333] ">Apple and Android Devices</h3>

              <p>
                The following terms apply when you use the App obtained from either the Apple Store or Google Play (each an 'App Distributor') to access the Services: (1) the license granted to you for our App is limited to a non-transferable license
                to use the application on a device that utilizes the Apple iOS or Android operating systems, as applicable, and in accordance with the usage rules set forth in the applicable App Distributor’s terms of service; (2) we are responsible
                for providing any maintenance and support services with respect to the App as specified in the terms and conditions of this mobile application license contained in these Legal Terms or as otherwise required under applicable law, and
                you acknowledge that each App Distributor has no obligation whatsoever to furnish any maintenance and support services with respect to the App; (3) in the event of any failure of the App to conform to any applicable warranty, you may
                notify the applicable App Distributor, and the App Distributor, in accordance with its terms and policies, may refund the purchase price, if any, paid for the App, and to the maximum extent permitted by applicable law, the App
                Distributor will have no other warranty obligation whatsoever with respect to the App; (4) you represent and warrant that (i) you are not located in a country that is subject to a US government embargo, or that has been designated by
                the US government as a 'terrorist supporting' country and (ii) you are not listed on any US government list of prohibited or restricted parties; (5) you must comply with applicable third-party terms of agreement when using the App,
                e.g. if you have a VoIP application, then you must not be in violation of their wireless data service agreement when using the App; and (6) you acknowledge and agree that the App Distributors are third-party beneficiaries of the terms
                and conditions in this mobile application license contained in these Legal Terms, and that each App Distributor will have the right (and will be deemed to have accepted the right) to enforce the terms and conditions in this mobile
                application license contained in these Legal Terms against you as a third-party beneficiary thereof.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">12. THIRD-PARTY WEBSITES AND CONTENT: </h3>

              <p>
                The Services may contain (or you may be sent via the App) links to other websites ('Third-Party Websites') as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software,
                and other content or items belonging to or originating from third parties ('Third-Party Content'). Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or
                completeness by us, and we are not responsible for any Third-Party Websites accessed through the Services or any Third-Party Content posted on, available through, or installed from the Services, including the content, accuracy,
                offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or permitting the use or installation of any Third-Party
                Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the Services and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own
                risk, and you should be aware these Legal Terms no longer govern. You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigate from the Services or relating
                to any applications you use or install from the Services. Any purchases you make through Third-Party Websites will be through other websites and from other companies, and we take no responsibility whatsoever in relation to such
                purchases which are exclusively between you and the applicable third party. You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you shall hold us blameless from any harm caused
                by your purchase of such products or services. Additionally, you shall hold us blameless from any losses sustained by you or harm caused to you relating to or resulting in any way from any Third-Party Content or any contact with
                Third-Party Websites.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">13. SERVICES MANAGEMENT: </h3>

              <p>
                We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal Terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms,
                including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically
                feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content that are excessive in size or
                are in any way burdensome to our systems; and (5) otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">14. PRIVACY POLICY: </h3>

              <p>
                We care about data privacy and security. By using the Services, you agree to be bound by our Privacy Policy posted on the Services, which is incorporated into these Legal Terms. Please be advised the Services are hosted in Nigeria. If
                you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in Nigeria, then through your continued use of the
                Services, you are transferring your data to Nigeria, and you expressly consent to have your data transferred to and processed in Nigeria.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">15. TERMS AND TERMINATION: </h3>

              <p>
                These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY
                ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL
                TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANYTIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of
                the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">16. MODIFICATIONS AND INTERRUPTIONS: </h3>

              <p>
                We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Services. We will not
                be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Services.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <p>
                We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors. We reserve
                the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience
                caused by your inability to access or use the Services during any downtime or discontinuance of the Services. Nothing in these Legal Terms will be construed to obligate us to maintain and support the Services or to supply any
                corrections, updates, or releases in connection therewith.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">17. GOVERNING LAW: </h3>

              <p>
                These Legal Terms shall be governed by and defined following the laws of Nigeria. Fitchco Technology Solution Limited and yourself irrevocably consent that the courts of Nigeria shall have exclusive jurisdiction to resolve any dispute
                which may arise in connection with these Legal Terms.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">18. GOVERNING LAW: </h3>
              <h3 className=" font-normal  text-[#333] ">Informal Negotiations </h3>

              <p>
                To expedite resolution and control the cost of any dispute, controversy, or claim related to these Legal Terms (each a 'Dispute' and collectively, the 'Disputes') brought by either you or us (individually, a 'Party' and collectively,
                the 'Parties'), the Parties agree to first attempt to negotiate any Dispute (except those Disputes expressly provided below) informally for at least thirty (30) days before initiating arbitration. Such informal negotiations commence
                upon written notice from one Party to the other Party.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-normal  text-[#333] ">Binding Arbitration </h3>

              <p>
                Any dispute arising out of or in connection with these Legal Terms, including any question regarding its existence, validity, or termination, shall be referred to and finally resolved by the International Commercial Arbitration Court
                under the European Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146) according to the Rules of this ICAC, which, as a result of referring to it, is considered as the part of this clause. The number of arbitrators shall be
                three (3). The seat, or legal place, or arbitration shall be Lagos, Nigeria. The language of the proceedings shall be English. The governing law of these Legal Terms shall be substantive law of Nigeria.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-normal  text-[#333] ">Restrictions</h3>

              <p>
                The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or
                authority for any Dispute to be arbitrated on a class-action basis or to utilize class action procedures; and (c)there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the
                general public or any other persons.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-normal  text-[#333] ">Exceptions to Informal Negotiations and Arbitration</h3>

              <p>
                The Parties agree that the following Disputes are not subject to the above provisions concerning informal negotiations binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity of, any of the
                intellectual property rights of a Party; (b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive relief. If this provision is found to be
                illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction
                within the courts listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">19. CORRECTIONS: </h3>

              <p>
                There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors,
                inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">20. DISCLAIMER: </h3>

              <p>
                THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION
                WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANT ABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
                COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND
                MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
                AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD
                PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT
                WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR
                OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY
                MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGEMENT AND EXERCISE CAUTION WHERE APPROPRIATE. inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">21. LIMITATIONS OF LIABILITY: </h3>

              <p>
                IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF
                DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER
                AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
                LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">22. IDEMNIFICATION: </h3>

              <p>
                You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand,
                including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) use of the Services; (2) breach of these Legal Terms; (3) any breach of your representations and warranties set forth in these
                Legal Terms; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the Services with whom you connected via the Services.
                Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense
                of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">23. USER DATA: </h3>

              <p>
                We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data relating to your use of the Services. Although we perform regular routine backups of data,
                you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Services. You agree that we shall have no liability to you for any loss or corruption of any such data, and you
                hereby waive any right of action against us arising from any such loss or corruption of such data.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">24. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES: </h3>

              <p>
                Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other
                communications we provide to you electronically, via email and on the Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
                RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or
                other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">25. MISCELLANEOUS </h3>

              <p>
                These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or
                provision of these Legal Terms shall not operate as a waiver of such right or provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time.
                We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Legal Terms is determined to be unlawful, void,
                or unenforceable, that provision or part of the provision is deemed severable from these Legal Terms and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment
                or agency relationship created between you and us as a result of these Legal Terms or use of the Services. You agree that these Legal Terms will not be construed against us by virtue of having drafted them. You hereby waive any and
                all defences you may have based on the electronic form of these Legal Terms and the lack of signing by the parties hereto to execute these Legal Terms.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">26. CONTACT US: </h3>

              <p>
                In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at: Fitchco Technology Solution Limited CHIEF ORE FAMEWO STREET, OFF KUSENLAROAD Ikate, Lekki,
                Lagos Nigeria
                <br />
                Phone: +234 706 636 6375
                <br />
                info@fitchcong.com
              </p>
            </div>
            <div className=" flex-col flex justify-between items-center gap-[10px]">
              <button onClick={closeTerms} className="w-[243px] h-[38px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">
                Agree
              </button>
              <button onClick={closeTerms} className="w-[243px] h-[38px] rounded-[4px] text-[18px] text-[#333] font-raleway border-[1.5px]  border-[#3498DB] tracking-[0.9px]  font-semibold ">
                Decline
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsOfAgreement;
