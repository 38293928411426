import { AdminFooter } from "./AdminFooter";
import { Header } from "./Header";
import SideBarUser from "./SideBarUser";

export const ReportsUser = () => {
  return (
    <>
      <div className=" flex  justify-between">
        <SideBarUser />
        {/* BIG SCREENS FROM 1280PX */}
        <div className=" 100:hidden   pl-[290px] pb-[50px] h-fit w-full  pr-[40px] xl:flex flex-col  justify-between">
          <Header />
          <div className="flex w-[460px] flex-col gap-[10px] justify-between">
            <h1 className="text-[#333] tracking-[0.9px] text-[36px] font-raleway font-medium">Financial Reports</h1>
            <p className="text-[16px] leading-[30px] tracking-[0.9px] text-[#999] font-montserrat font-normal ">Explore Detailed Financial Reports.</p>
          </div>
          <div className=" w-full p-[20px] mt-[40px] h-fit justify-center items-center flex flex-col gap-[40px] rounded-[20px] bg-white ">
            <div className="flex justify-between w-full">
              <h4 className=" font-raleway font-semibold text-[18px] text-[#333] tracking-[0.9px]  ">Reports</h4>
              <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.375 1.24976L11.8234 2.08809C12.1267 2.65485 12.2783 2.93824 12.1781 3.05961C12.0778 3.18097 11.7476 3.08295 11.0873 2.88692C10.4272 2.69092 9.7263 2.58547 9 2.58547C5.06497 2.58547 1.875 5.6811 1.875 9.49979C1.875 10.7591 2.22197 11.9399 2.8282 12.9569M6.625 17.7498L6.17652 16.9114C5.87331 16.3447 5.7217 16.0612 5.82195 15.9399C5.9222 15.8185 6.25237 15.9166 6.91267 16.1126C7.57283 16.3086 8.2737 16.414 9 16.414C12.935 16.414 16.125 13.3184 16.125 9.49979C16.125 8.24039 15.778 7.05961 15.1718 6.04261"
                  stroke="#3498DB"
                  strokeWidth="1.125"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
            </div>
            <h3 className=" w-full flex justify-center items-center tracking-[0.9px] font-raleway text-[15px] text-[#333] font-semibold ">Card Transaction Chart</h3>
            <div className="flex  w-full gap-[10px] items-center">
              <div className="flex text-[12px] text-[#999] font-montserrat font-medium tracking-[0.9px] flex-col items-start gap-[40px]">
                <h4>Department Store</h4>
                <h4>Grocries</h4>
                <h4>Resturantes</h4>
                <h4>Electricity</h4>
                <h4>Outfits</h4>
                <h4>Bills</h4>
                <h4>Airtime</h4>
              </div>
              <div className="flex text-[10px]  text-[#333] font-montserrat font-normal tracking-[0.9px] flex-col items-start gap-[40px]">
                <div className="flex justify-between items-center gap-[10px]">
                  <div className=" rounded-r-[2px] h-[20px] w-[827px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                  <h4>₦50,000</h4>
                </div>
                <div className="flex justify-between items-center gap-[10px]">
                  <div className=" rounded-r-[2px] h-[20px] w-[679px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                  <h4>₦40,000</h4>
                </div>
                <div className="flex justify-between items-center gap-[10px]">
                  <div className=" rounded-r-[2px] h-[20px] w-[511px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                  <h4>₦30,000</h4>
                </div>{" "}
                <div className="flex justify-between items-center gap-[10px]">
                  <div className=" rounded-r-[2px] h-[20px] w-[408px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                  <h4>₦20,000</h4>
                </div>
                <div className="flex justify-between items-center gap-[10px]">
                  <div className=" rounded-r-[2px] h-[20px] w-[301px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                  <h4>₦15,000</h4>
                </div>
                <div className="flex justify-between items-center gap-[10px]">
                  <div className=" rounded-r-[2px] h-[20px] w-[102px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                  <h4>₦10,000</h4>
                </div>
                <div className="flex justify-between items-center gap-[10px]">
                  <div className=" rounded-r-[2px] h-[20px] w-[37px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                  <h4>₦5,000</h4>
                </div>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
        {/* SMALL SCREENS FROM 100PX TO 1279PX */}
        <div className="100:flex xl:hidden  pl-[115px] pb-[50px] h-fit w-full  pr-[20px]  flex-col justify-between">
          <Header />
          <div className="flex w-full flex-col gap-[10px] justify-between">
            <h1 className="text-[#333] tracking-[0.9px] text-[30px] font-raleway font-medium">Financial Reports</h1>
            <p className="text-[13px] leading-[30px] tracking-[0.9px] text-[#999] font-montserrat font-normal ">Explore Detailed Financial Reports.</p>
          </div>

          <div className=" w-full p-[20px] mt-[40px] h-fit justify-center items-center flex flex-col gap-[40px] rounded-[20px] bg-white ">
            <div className="flex justify-between w-full">
              <h4 className=" font-raleway font-semibold text-[18px] text-[#333] tracking-[0.9px]  ">Reports</h4>
              <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.375 1.24976L11.8234 2.08809C12.1267 2.65485 12.2783 2.93824 12.1781 3.05961C12.0778 3.18097 11.7476 3.08295 11.0873 2.88692C10.4272 2.69092 9.7263 2.58547 9 2.58547C5.06497 2.58547 1.875 5.6811 1.875 9.49979C1.875 10.7591 2.22197 11.9399 2.8282 12.9569M6.625 17.7498L6.17652 16.9114C5.87331 16.3447 5.7217 16.0612 5.82195 15.9399C5.9222 15.8185 6.25237 15.9166 6.91267 16.1126C7.57283 16.3086 8.2737 16.414 9 16.414C12.935 16.414 16.125 13.3184 16.125 9.49979C16.125 8.24039 15.778 7.05961 15.1718 6.04261"
                  stroke="#3498DB"
                  strokeWidth="1.125"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
            </div>
            <h3 className=" w-full flex justify-center items-center tracking-[0.9px] font-raleway text-[15px] text-[#333] font-semibold ">Card Transaction Chart</h3>
            <div className="flex overflow-x-scroll  w-full gap-[10px] items-center">
              <div className="flex text-[12px] whitespace-nowrap text-[#999] font-montserrat font-medium tracking-[0.9px] flex-col items-start gap-[40px]">
                <h4>Department Store</h4>
                <h4>Grocries</h4>
                <h4>Resturantes</h4>
                <h4>Electricity</h4>
                <h4>Outfits</h4>
                <h4>Bills</h4>
                <h4>Airtime</h4>
              </div>
              <div className="flex text-[10px]  text-[#333] font-montserrat font-normal tracking-[0.9px] flex-col items-start gap-[40px]">
                <div className="flex justify-between items-center gap-[10px]">
                  <div className=" rounded-r-[2px] h-[20px] w-[827px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                  <h4>₦50,000</h4>
                </div>
                <div className="flex justify-between items-center gap-[10px]">
                  <div className=" rounded-r-[2px] h-[20px] w-[679px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                  <h4>₦40,000</h4>
                </div>
                <div className="flex justify-between items-center gap-[10px]">
                  <div className=" rounded-r-[2px] h-[20px] w-[511px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                  <h4>₦30,000</h4>
                </div>{" "}
                <div className="flex justify-between items-center gap-[10px]">
                  <div className=" rounded-r-[2px] h-[20px] w-[408px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                  <h4>₦20,000</h4>
                </div>
                <div className="flex justify-between items-center gap-[10px]">
                  <div className=" rounded-r-[2px] h-[20px] w-[301px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                  <h4>₦15,000</h4>
                </div>
                <div className="flex justify-between items-center gap-[10px]">
                  <div className=" rounded-r-[2px] h-[20px] w-[102px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                  <h4>₦10,000</h4>
                </div>
                <div className="flex justify-between items-center gap-[10px]">
                  <div className=" rounded-r-[2px] h-[20px] w-[37px] bg-[#3498DB] bg-opacity-20  ">{/* */}</div>
                  <h4>₦5,000</h4>
                </div>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </>
  );
};
