import contactUs from "../images/contactKolet.png";

const ContactInfo = ({ setContact }) => {
  return (
    <>
      <div className=" bg-black flex  fixed z-40 justify-center items-center bg-opacity-55 w-full h-full left-0 top-0  ">
        <div onClick={() => setContact(false)} className=" cursor-pointer  flex justify-center items-center w-[60px] absolute right-[40px] top-[40px] h-[60px] bg-[#494B48] rounded-[80px]">
          <svg className=" " width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 5L5 19M5 5L19 19" stroke="#F5F5F5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>

        <div className=" 100:hidden xl:flex w-[600px] flex-col items-center justify-between gap-[20px] px-[40px] py-[50px] h-fit rounded-[20px] bg-[#f5f5f5]">
          <img src={contactUs} alt="Customer Service" />
          <div className=" flex flex-col gap-[40px] items-center">
            <p className="text-[18px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Talk to Us or Send an Email</p>
            <div className="flex justify-between items-center gap-[20px]">
              {" "}
              <a href="tel:2347066366375">
                <button className="w-[200px] h-[44px] flex items-center justify-center gap-[10px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2.51858 7.96164C1.88656 6.85958 1.5814 5.9597 1.39739 5.04752C1.12524 3.69843 1.74804 2.38058 2.77975 1.53969C3.2158 1.18429 3.71565 1.30572 3.9735 1.76831L4.55562 2.81265C5.01702 3.64042 5.24772 4.0543 5.20196 4.4931C5.15621 4.9319 4.84508 5.28928 4.22281 6.00405L2.51858 7.96164ZM2.51858 7.96164C3.79784 10.1922 5.80539 12.2009 8.03856 13.4816M8.03856 13.4816C9.14063 14.1136 10.0405 14.4188 10.9527 14.6028C12.3018 14.875 13.6196 14.2522 14.4605 13.2204C14.8159 12.7844 14.6945 12.2846 14.2319 12.0267L13.1876 11.4446C12.3598 10.9832 11.9459 10.7525 11.5071 10.7982C11.0683 10.844 10.7109 11.1551 9.99616 11.7774L8.03856 13.4816Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Call
                </button>
              </a>
              <a href="mailto:info@fitchcong.com">
                <button className="w-[200px] h-[44px] flex justify-center items-center gap-[10px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">
                  {" "}
                  <svg width="16" height="16" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.90088 3.09473L5.28894 5.01443C6.538 5.72215 7.06574 5.72215 8.3148 5.01443L11.7029 3.09473" stroke="#fff" strokeWidth="1.5" strokeLinejoin="round" />
                    <path
                      d="M1.90861 6.75842C1.94065 8.26086 1.95667 9.01204 2.51103 9.56855C3.06539 10.125 3.83693 10.1444 5.38001 10.1831C6.33103 10.207 7.27271 10.207 8.22374 10.1831C9.76682 10.1444 10.5383 10.125 11.0927 9.56855C11.6471 9.01204 11.6631 8.26086 11.6951 6.75842C11.7055 6.27533 11.7055 5.79513 11.6951 5.31204C11.6631 3.80962 11.6471 3.05841 11.0927 2.50194C10.5383 1.94546 9.76682 1.92608 8.22374 1.88731C7.27271 1.86341 6.33103 1.86341 5.38 1.8873C3.83693 1.92607 3.06539 1.94545 2.51103 2.50193C1.95666 3.0584 1.94065 3.80961 1.9086 5.31204C1.8983 5.79513 1.89831 6.27533 1.90861 6.75842Z"
                      stroke="#fff"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Email
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className=" 100:flex xl:hidden w-[85%] sm:w-[550px] flex-col items-center justify-between gap-[20px] px-[20px] py-[50px] h-fit rounded-[20px] bg-[#f5f5f5]">
          <img src={contactUs} alt="Customer Service" />

          <div className=" flex flex-col gap-[40px] items-center">
            <p className="text-[18px] text-[#333] font-raleway font-semibold tracking-[0.9px] ">Talk to Us or Send an Email</p>
            <div className="flex justify-between items-center gap-[10px]">
              {" "}
              <a href="tel:2347066366375">
                <button className="w-[150px] sm:w-[200px] h-[44px] flex items-center justify-center gap-[10px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2.51858 7.96164C1.88656 6.85958 1.5814 5.9597 1.39739 5.04752C1.12524 3.69843 1.74804 2.38058 2.77975 1.53969C3.2158 1.18429 3.71565 1.30572 3.9735 1.76831L4.55562 2.81265C5.01702 3.64042 5.24772 4.0543 5.20196 4.4931C5.15621 4.9319 4.84508 5.28928 4.22281 6.00405L2.51858 7.96164ZM2.51858 7.96164C3.79784 10.1922 5.80539 12.2009 8.03856 13.4816M8.03856 13.4816C9.14063 14.1136 10.0405 14.4188 10.9527 14.6028C12.3018 14.875 13.6196 14.2522 14.4605 13.2204C14.8159 12.7844 14.6945 12.2846 14.2319 12.0267L13.1876 11.4446C12.3598 10.9832 11.9459 10.7525 11.5071 10.7982C11.0683 10.844 10.7109 11.1551 9.99616 11.7774L8.03856 13.4816Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Call
                </button>
              </a>
              <a href="mailto:info@fitchcong.com">
                <button className="w-[150px] sm:w-[200px] h-[44px] flex justify-center items-center gap-[10px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">
                  {" "}
                  <svg width="16" height="16" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.90088 3.09473L5.28894 5.01443C6.538 5.72215 7.06574 5.72215 8.3148 5.01443L11.7029 3.09473" stroke="#fff" strokeWidth="1.5" strokeLinejoin="round" />
                    <path
                      d="M1.90861 6.75842C1.94065 8.26086 1.95667 9.01204 2.51103 9.56855C3.06539 10.125 3.83693 10.1444 5.38001 10.1831C6.33103 10.207 7.27271 10.207 8.22374 10.1831C9.76682 10.1444 10.5383 10.125 11.0927 9.56855C11.6471 9.01204 11.6631 8.26086 11.6951 6.75842C11.7055 6.27533 11.7055 5.79513 11.6951 5.31204C11.6631 3.80962 11.6471 3.05841 11.0927 2.50194C10.5383 1.94546 9.76682 1.92608 8.22374 1.88731C7.27271 1.86341 6.33103 1.86341 5.38 1.8873C3.83693 1.92607 3.06539 1.94545 2.51103 2.50193C1.95666 3.0584 1.94065 3.80961 1.9086 5.31204C1.8983 5.79513 1.89831 6.27533 1.90861 6.75842Z"
                      stroke="#fff"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Email
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactInfo;
