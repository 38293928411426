import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../images/KOLET LOGO.png";
import "./Login.css";

export const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [border, setBorder] = useState(false);
  const [border2, setBorder2] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [hamburger, setHamburger] = useState(false);
  const [navBar, setNavBar] = useState(false);
  function handleScrollBackground() {
    if (window.scrollY >= 20) {
      setNavBar(true);
    } else {
      setNavBar(false);
    }
  }
  window.addEventListener("scroll", handleScrollBackground);

  // Function to handle password change
  const handlePasswordChange = (e) => {
    const password = e.target.value;
    // if the password is valid or not
    setIsPasswordValid(password === "correctpassword");
  };

  return (
    <>
      {/* BIG SCREENS FROM 1280PX */}
      <div className=" 100:hidden xl:flex  w-full h-fit px-[50px] gap-[100px] flex-col justify-between items-center ">
        <div className={` fixed px-[50px] w-full items-center flex justify-between ${navBar ? "bg-[#fff] bg-opacity-40" : " bg-transparent"} h-[84.15px]`}>
          <Link onClick={() => window.scrollTo(0, 0)} to="*">
            <img src={logo} alt="Kolet" />
          </Link>
          <Link onClick={() => window.scrollTo(0, 0)} to="*">
            <h3 className="text-[18px] font-raleway font-semibold text-[#333] -tracking-tight">Home</h3>
          </Link>
          <Link onClick={() => window.scrollTo(0, 0)} to="/signup">
            <div className="w-[163px] h-[38px]  rounded-[4px] justify-center items-center inline-flex bg-[#3498DB] text-[18px] text-[#fff] tracking-tight text-center font-raleway font-semibold ">Join Us</div>
          </Link>
        </div>
        <div className=" w-[424px] mt-[200px] h-fit flex flex-col p-[30px] gap-[40px] justify-between items-center rounded-[20px] custom-box-shadow  ">
          <div className=" flex flex-col items-center text-center justify-between gap-[10px] w-[288px]">
            <h3 className="text-[25px] font-semibold tracking-[0.9px] text-[#333] font-raleway ">Welcome Back</h3>
            <p className="text-[15px] text-[#777] tracking-[0.9px] font-montserrat font-normal leading-[25px] ">Log in to your account to continue.</p>
          </div>
          <form className="flex flex-col  gap-[20px]">
            <div
              onClick={() => {
                setBorder(true);
                setBorder2(false);
              }}
              className={
                border
                  ? " flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[1px] border-[#3498DB] "
                  : "flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] "
              }
            >
              <input className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="tel" placeholder="Phone Number" />
              <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.68799 5.02981C2.68799 3.41257 2.68799 2.60395 3.1904 2.10154C3.69281 1.59912 4.50144 1.59912 6.11868 1.59912C7.73591 1.59912 8.54452 1.59912 9.04697 2.10154C9.54937 2.60395 9.54937 3.41257 9.54937 5.02981V7.97041C9.54937 9.58764 9.54937 10.3963 9.04697 10.8987C8.54452 11.4011 7.73591 11.4011 6.11868 11.4011C4.50144 11.4011 3.69281 11.4011 3.1904 10.8987C2.68799 10.3963 2.68799 9.58764 2.68799 7.97041V5.02981Z"
                  stroke="#999999"
                  strokeWidth="0.735148"
                  strokeLinecap="round"
                />
                <path d="M5.62866 9.93066H6.60886" stroke="#999999" strokeWidth="0.735148" strokeLinecap="round" strokeLinejoin="round" />
                <path
                  d="M4.64844 1.59912L4.69206 1.86084C4.78659 2.42802 4.83385 2.71161 5.02836 2.88418C5.23126 3.06419 5.5189 3.06942 6.11873 3.06942C6.71857 3.06942 7.00621 3.06419 7.20911 2.88418C7.40363 2.71161 7.45087 2.42802 7.54541 1.86084L7.58903 1.59912"
                  stroke="#999999"
                  strokeWidth="0.735148"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div
              onClick={() => {
                setBorder2(true);
                setBorder(false);
              }}
              className={`flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[${isPasswordValid ? "1px" : "0.7px"}] border-[${border2 ? "#3498DB" : "#BDC3C7"}]  ${
                isPasswordValid ? "border-[#3498DB]" : "border-[#FF0000]"
              } `}
            >
              <input onChange={handlePasswordChange} className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type={showPassword ? "text" : "password"} placeholder="Password" />
              <span onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? (
                  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.4796 5.56714C11.6286 5.77607 11.7031 5.88056 11.7031 6.03519C11.7031 6.18981 11.6286 6.2943 11.4796 6.50323C10.8101 7.44206 9.10029 9.46588 6.80211 9.46588C4.50393 9.46588 2.79413 7.44206 2.12463 6.50323C1.97562 6.2943 1.90112 6.18981 1.90112 6.03519C1.90112 5.88056 1.97562 5.77607 2.12463 5.56714C2.79413 4.62833 4.50393 2.60449 6.80211 2.60449C9.10029 2.60449 10.8101 4.62833 11.4796 5.56714Z"
                      stroke="#999999"
                      strokeWidth="0.735148"
                    />
                    <path
                      d="M8.27238 6.03524C8.27238 5.22319 7.61413 4.56494 6.80208 4.56494C5.99004 4.56494 5.33179 5.22319 5.33179 6.03524C5.33179 6.84728 5.99004 7.50554 6.80208 7.50554C7.61413 7.50554 8.27238 6.84728 8.27238 6.03524Z"
                      stroke="#999999"
                      strokeWidth="0.735148"
                    />
                  </svg>
                ) : (
                  <svg width="13" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.78 3.91992C10.78 3.91992 8.81998 6.85992 5.87998 6.85992C2.93998 6.85992 0.97998 3.91992 0.97998 3.91992" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" />
                    <path d="M7.3501 6.61499L8.0851 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.80005 5.38989L10.78 6.36989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.97998 6.36989L1.95998 5.38989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.41005 6.61499L3.67505 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                )}
              </span>
            </div>
            {isPasswordValid ? "" : <p className=" text-[12px] tracking-tight text-[#E74C3C] font-normal font-montserrat">Password does not match</p>}
            <div className=" w-full float-right">
              <Link className="w-[106px]" to="/forgotpassword">
                <h3 className=" float-right font-montserrat font-normal tracking-tight text-[12px] text-[#2980B9] ">Forgot Password?</h3>
              </Link>
            </div>
          </form>
          <div className="flex flex-col justify-between items-center gap-[10px] ">
            <Link onClick={() => window.scrollTo(0, 0)} to="/dashboarduser">
              <button className="w-[243px] h-[38px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Sign In</button>
            </Link>
            <h3 className="tracking-[0.9px] h-[38px] font-raleway font-semibold text-[16px] text-[#333] ">
              Not a member?{" "}
              <Link className="text-[#2980B9]" to="/signup">
                Sign up
              </Link>{" "}
              now
            </h3>
          </div>
          <div className="w-[297px] h-[105px] flex-col gap-[20px] flex justify-between items-center border-solid border-t-[#BDC3C7] border-t-[0.7px] p-[20px]">
            <h3 className=" font-montserrat font-normal text-[12px] text-[#333] tracking-[0.9px] ">Get started in a single click!</h3>
            <div className="flex justify-between gap-[20px] items-center">
              <div className="w-[30px] h-[30px] rounded-[5px] flex justify-center items-center bg-[#1877F2] ">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1002_5854)">
                    <rect width="15" height="15" transform="translate(0.5 0.654053)" fill="#1877F2" />
                    <path
                      d="M15.5 8.79755C15.5 4.79348 12.2541 1.54755 8.25 1.54755C4.24593 1.54755 1 4.79348 1 8.79755C1 12.4162 3.65122 15.4156 7.11719 15.9595V10.8932H5.27637V8.79755H7.11719V7.20028C7.11719 5.38325 8.19956 4.37958 9.85562 4.37958C10.6488 4.37958 11.4785 4.52118 11.4785 4.52118V6.30536H10.5643C9.66368 6.30536 9.38281 6.86422 9.38281 7.43756V8.79755H11.3936L11.0721 10.8932H9.38281V15.9595C12.8488 15.4156 15.5 12.4162 15.5 8.79755Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1002_5854">
                      <rect width="15" height="15" fill="white" transform="translate(0.5 0.654053)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="w-[30px] h-[30px] rounded-[5px] flex justify-center items-center bg-[#333] ">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1002_5857)">
                    <rect width="15" height="15" transform="translate(0.5 0.654053)" fill="#333333" />
                    <path
                      d="M2.375 13.7791L7.09275 9.0613M7.09275 9.0613L2.375 2.52905H5.5L8.90725 7.2468M7.09275 9.0613L10.5 13.7791H13.625L8.90725 7.2468M13.625 2.52905L8.90725 7.2468"
                      stroke="#F5F5F5"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1002_5857">
                      <rect width="15" height="15" fill="white" transform="translate(0.5 0.654053)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="w-[30px] h-[30px] rounded-[5px] flex justify-center items-center bg-[#fff] ">
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="14" height="14" transform="translate(0 0.154053)" fill="white" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.72 7.31317C13.72 6.8168 13.6755 6.33953 13.5927 5.88135H7V8.58907H10.7673C10.605 9.46407 10.1118 10.2054 9.37045 10.7018V12.4582H11.6327C12.9564 11.2395 13.72 9.44498 13.72 7.31317Z"
                    fill="#4285F4"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.00008 14.1539C8.89008 14.1539 10.4746 13.5271 11.6328 12.458L9.37054 10.7016C8.74372 11.1216 7.9419 11.3698 7.00008 11.3698C5.1769 11.3698 3.63372 10.1384 3.08327 8.48389H0.744629V10.2975C1.89645 12.5853 4.26372 14.1539 7.00008 14.1539Z"
                    fill="#34A853"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.08318 8.48401C2.94318 8.06401 2.86364 7.61538 2.86364 7.15401C2.86364 6.69265 2.94318 6.24401 3.08318 5.82401V4.01038H0.744545C0.270455 4.95538 0 6.02447 0 7.15401C0 8.28356 0.270455 9.35265 0.744545 10.2976L3.08318 8.48401Z"
                    fill="#FBBC05"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.00008 2.93814C8.02781 2.93814 8.95054 3.29133 9.67599 3.98496L11.6837 1.97723C10.4714 0.847689 8.8869 0.154053 7.00008 0.154053C4.26372 0.154053 1.89645 1.72269 0.744629 4.01042L3.08327 5.82405C3.63372 4.16951 5.1769 2.93814 7.00008 2.93814Z"
                    fill="#EA4335"
                  />
                </svg>
              </div>
              <div className="w-[30px] h-[30px] rounded-[5px] flex justify-center items-center bg-[#000] ">
                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1002_5869)">
                    <rect width="11" height="12.5" transform="translate(0.5 0.904053)" fill="black" />
                    <path
                      d="M11.2695 10.6453C11.072 11.0821 10.8382 11.4841 10.5673 11.8536C10.1982 12.3575 9.89587 12.7062 9.6629 12.8999C9.30177 13.2178 8.91485 13.3806 8.50051 13.3899C8.20306 13.3899 7.84435 13.3088 7.42679 13.1445C7.00786 12.9809 6.62287 12.8999 6.27085 12.8999C5.90166 12.8999 5.5057 12.9809 5.08218 13.1445C4.65801 13.3088 4.3163 13.3945 4.05505 13.403C3.65772 13.4192 3.26169 13.2518 2.86638 12.8999C2.61407 12.6893 2.29848 12.3282 1.92042 11.8166C1.5148 11.2703 1.18131 10.6369 0.920057 9.91466C0.640261 9.13459 0.5 8.37921 0.5 7.64791C0.5 6.8102 0.68911 6.08769 1.0679 5.48223C1.36559 4.99591 1.76162 4.61228 2.25729 4.33065C2.75296 4.04902 3.28853 3.90551 3.86529 3.89632C4.18088 3.89632 4.59473 3.98976 5.10902 4.1734C5.62186 4.35765 5.95115 4.45109 6.09552 4.45109C6.20346 4.45109 6.56927 4.34184 7.1894 4.12402C7.77583 3.92202 8.27078 3.83838 8.67624 3.87133C9.77495 3.9562 10.6004 4.37077 11.1493 5.11766C10.1667 5.68755 9.68064 6.48575 9.69031 7.50972C9.69918 8.3073 10.0015 8.97101 10.5956 9.49801C10.8648 9.7426 11.1655 9.93163 11.5 10.0659C11.4275 10.2673 11.3509 10.4602 11.2695 10.6453ZM8.7496 1.15412C8.7496 1.77927 8.51099 2.36296 8.0354 2.90322C7.46145 3.54549 6.76724 3.91662 6.01443 3.85805C6.00484 3.78306 5.99927 3.70412 5.99927 3.62118C5.99927 3.02104 6.27222 2.37878 6.75693 1.85364C6.99892 1.58775 7.30668 1.36667 7.67991 1.19031C8.05232 1.01658 8.40459 0.920505 8.73589 0.904053C8.74557 0.987625 8.7496 1.0712 8.7496 1.15412V1.15412Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1002_5869">
                      <rect width="11" height="12.5" fill="white" transform="translate(0.5 0.904053)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1279PX */}
      <div className="100:flex w-full h-fit gap-[100px] flex-col px-[40px] py-[20px] xl:hidden justify-center items-center ">
        <div className={`top-0  fixed w-full items-center px-[40px] flex justify-between ${navBar ? "bg-[#fff] bg-opacity-40" : " bg-transparent"} h-[84.15px]`}>
          <Link to="*">
            <img src={logo} alt="Kolet" />
          </Link>
          <svg onClick={() => setHamburger(true)} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 12.1709H10" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 5.1709H4" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 19.1709H4" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>{" "}
        <div className=" w-[95%] sm:w-[424px] mt-[150px]  h-fit flex flex-col p-[30px] gap-[40px] justify-between items-center rounded-[20px] custom-box-shadow  ">
          <div className=" flex flex-col items-center text-center justify-between gap-[10px] w-[288px]">
            <h3 className="text-[25px] font-semibold tracking-[0.9px] text-[#333] font-raleway ">Welcome Back</h3>
            <p className="text-[15px] text-[#777] tracking-[0.9px] font-montserrat font-normal leading-[25px] ">Log in to your account to continue.</p>
          </div>
          <form className="flex w-full flex-col  gap-[20px]">
            <div
              onClick={() => {
                setBorder(true);
                setBorder2(false);
              }}
              className={
                border
                  ? " flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[1px] border-[#3498DB] "
                  : "flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] "
              }
            >
              <input className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="tel" placeholder="Phone Number" />
              <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.68799 5.02981C2.68799 3.41257 2.68799 2.60395 3.1904 2.10154C3.69281 1.59912 4.50144 1.59912 6.11868 1.59912C7.73591 1.59912 8.54452 1.59912 9.04697 2.10154C9.54937 2.60395 9.54937 3.41257 9.54937 5.02981V7.97041C9.54937 9.58764 9.54937 10.3963 9.04697 10.8987C8.54452 11.4011 7.73591 11.4011 6.11868 11.4011C4.50144 11.4011 3.69281 11.4011 3.1904 10.8987C2.68799 10.3963 2.68799 9.58764 2.68799 7.97041V5.02981Z"
                  stroke="#999999"
                  strokeWidth="0.735148"
                  strokeLinecap="round"
                />
                <path d="M5.62866 9.93066H6.60886" stroke="#999999" strokeWidth="0.735148" strokeLinecap="round" strokeLinejoin="round" />
                <path
                  d="M4.64844 1.59912L4.69206 1.86084C4.78659 2.42802 4.83385 2.71161 5.02836 2.88418C5.23126 3.06419 5.5189 3.06942 6.11873 3.06942C6.71857 3.06942 7.00621 3.06419 7.20911 2.88418C7.40363 2.71161 7.45087 2.42802 7.54541 1.86084L7.58903 1.59912"
                  stroke="#999999"
                  strokeWidth="0.735148"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div
              onClick={() => {
                setBorder2(true);
                setBorder(false);
              }}
              className={`flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[${isPasswordValid ? "1px" : "0.7px"}] border-[${border2 ? "#3498DB" : "#BDC3C7"}]  ${
                isPasswordValid ? "border-[#3498DB]" : "border-[#FF0000]"
              } `}
            >
              <input onChange={handlePasswordChange} className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type={showPassword ? "text" : "password"} placeholder="Password" />
              <span onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? (
                  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.4796 5.56714C11.6286 5.77607 11.7031 5.88056 11.7031 6.03519C11.7031 6.18981 11.6286 6.2943 11.4796 6.50323C10.8101 7.44206 9.10029 9.46588 6.80211 9.46588C4.50393 9.46588 2.79413 7.44206 2.12463 6.50323C1.97562 6.2943 1.90112 6.18981 1.90112 6.03519C1.90112 5.88056 1.97562 5.77607 2.12463 5.56714C2.79413 4.62833 4.50393 2.60449 6.80211 2.60449C9.10029 2.60449 10.8101 4.62833 11.4796 5.56714Z"
                      stroke="#999999"
                      strokeWidth="0.735148"
                    />
                    <path
                      d="M8.27238 6.03524C8.27238 5.22319 7.61413 4.56494 6.80208 4.56494C5.99004 4.56494 5.33179 5.22319 5.33179 6.03524C5.33179 6.84728 5.99004 7.50554 6.80208 7.50554C7.61413 7.50554 8.27238 6.84728 8.27238 6.03524Z"
                      stroke="#999999"
                      strokeWidth="0.735148"
                    />
                  </svg>
                ) : (
                  <svg width="13" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.78 3.91992C10.78 3.91992 8.81998 6.85992 5.87998 6.85992C2.93998 6.85992 0.97998 3.91992 0.97998 3.91992" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" />
                    <path d="M7.3501 6.61499L8.0851 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.80005 5.38989L10.78 6.36989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.97998 6.36989L1.95998 5.38989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.41005 6.61499L3.67505 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                )}
              </span>
            </div>
            {isPasswordValid ? "" : <p className=" text-[12px] tracking-tight text-[#E74C3C] font-normal font-montserrat">Password does not match</p>}
            <div className=" w-full float-right">
              <Link className="w-[106px]" to="/forgotpassword">
                <h3 className=" float-right font-montserrat font-normal tracking-tight text-[12px] text-[#2980B9] ">Forgot Password?</h3>
              </Link>
            </div>
          </form>
          <div className="flex flex-col justify-between items-center gap-[10px] ">
            <Link onClick={() => window.scrollTo(0, 0)} to="/dashboarduser">
              <button className="w-[200px] h-[38px] rounded-[4px] text-[16px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Sign In</button>
            </Link>
            <h3 className="tracking-[0.9px] text-center h-[38px] font-raleway font-semibold text-[14px] text-[#333] ">
              Not a member?{" "}
              <Link className="text-[#2980B9]" to="/signup">
                Sign up
              </Link>{" "}
              now
            </h3>
          </div>
          <div className="w-[250px] h-[105px] flex-col gap-[20px] flex justify-between items-center border-solid border-t-[#BDC3C7] border-t-[0.7px] p-[20px]">
            <h3 className=" font-montserrat font-normal text-[12px] text-[#333] tracking-[0.9px] ">Get started in a single click!</h3>
            <div className="flex justify-between gap-[20px] items-center">
              <div className="w-[30px] h-[30px] rounded-[5px] flex justify-center items-center bg-[#1877F2] ">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1002_5854)">
                    <rect width="15" height="15" transform="translate(0.5 0.654053)" fill="#1877F2" />
                    <path
                      d="M15.5 8.79755C15.5 4.79348 12.2541 1.54755 8.25 1.54755C4.24593 1.54755 1 4.79348 1 8.79755C1 12.4162 3.65122 15.4156 7.11719 15.9595V10.8932H5.27637V8.79755H7.11719V7.20028C7.11719 5.38325 8.19956 4.37958 9.85562 4.37958C10.6488 4.37958 11.4785 4.52118 11.4785 4.52118V6.30536H10.5643C9.66368 6.30536 9.38281 6.86422 9.38281 7.43756V8.79755H11.3936L11.0721 10.8932H9.38281V15.9595C12.8488 15.4156 15.5 12.4162 15.5 8.79755Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1002_5854">
                      <rect width="15" height="15" fill="white" transform="translate(0.5 0.654053)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="w-[30px] h-[30px] rounded-[5px] flex justify-center items-center bg-[#333] ">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1002_5857)">
                    <rect width="15" height="15" transform="translate(0.5 0.654053)" fill="#333333" />
                    <path
                      d="M2.375 13.7791L7.09275 9.0613M7.09275 9.0613L2.375 2.52905H5.5L8.90725 7.2468M7.09275 9.0613L10.5 13.7791H13.625L8.90725 7.2468M13.625 2.52905L8.90725 7.2468"
                      stroke="#F5F5F5"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1002_5857">
                      <rect width="15" height="15" fill="white" transform="translate(0.5 0.654053)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="w-[30px] h-[30px] rounded-[5px] flex justify-center items-center bg-[#fff] ">
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="14" height="14" transform="translate(0 0.154053)" fill="white" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.72 7.31317C13.72 6.8168 13.6755 6.33953 13.5927 5.88135H7V8.58907H10.7673C10.605 9.46407 10.1118 10.2054 9.37045 10.7018V12.4582H11.6327C12.9564 11.2395 13.72 9.44498 13.72 7.31317Z"
                    fill="#4285F4"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.00008 14.1539C8.89008 14.1539 10.4746 13.5271 11.6328 12.458L9.37054 10.7016C8.74372 11.1216 7.9419 11.3698 7.00008 11.3698C5.1769 11.3698 3.63372 10.1384 3.08327 8.48389H0.744629V10.2975C1.89645 12.5853 4.26372 14.1539 7.00008 14.1539Z"
                    fill="#34A853"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.08318 8.48401C2.94318 8.06401 2.86364 7.61538 2.86364 7.15401C2.86364 6.69265 2.94318 6.24401 3.08318 5.82401V4.01038H0.744545C0.270455 4.95538 0 6.02447 0 7.15401C0 8.28356 0.270455 9.35265 0.744545 10.2976L3.08318 8.48401Z"
                    fill="#FBBC05"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.00008 2.93814C8.02781 2.93814 8.95054 3.29133 9.67599 3.98496L11.6837 1.97723C10.4714 0.847689 8.8869 0.154053 7.00008 0.154053C4.26372 0.154053 1.89645 1.72269 0.744629 4.01042L3.08327 5.82405C3.63372 4.16951 5.1769 2.93814 7.00008 2.93814Z"
                    fill="#EA4335"
                  />
                </svg>
              </div>
              <div className="w-[30px] h-[30px] rounded-[5px] flex justify-center items-center bg-[#000] ">
                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1002_5869)">
                    <rect width="11" height="12.5" transform="translate(0.5 0.904053)" fill="black" />
                    <path
                      d="M11.2695 10.6453C11.072 11.0821 10.8382 11.4841 10.5673 11.8536C10.1982 12.3575 9.89587 12.7062 9.6629 12.8999C9.30177 13.2178 8.91485 13.3806 8.50051 13.3899C8.20306 13.3899 7.84435 13.3088 7.42679 13.1445C7.00786 12.9809 6.62287 12.8999 6.27085 12.8999C5.90166 12.8999 5.5057 12.9809 5.08218 13.1445C4.65801 13.3088 4.3163 13.3945 4.05505 13.403C3.65772 13.4192 3.26169 13.2518 2.86638 12.8999C2.61407 12.6893 2.29848 12.3282 1.92042 11.8166C1.5148 11.2703 1.18131 10.6369 0.920057 9.91466C0.640261 9.13459 0.5 8.37921 0.5 7.64791C0.5 6.8102 0.68911 6.08769 1.0679 5.48223C1.36559 4.99591 1.76162 4.61228 2.25729 4.33065C2.75296 4.04902 3.28853 3.90551 3.86529 3.89632C4.18088 3.89632 4.59473 3.98976 5.10902 4.1734C5.62186 4.35765 5.95115 4.45109 6.09552 4.45109C6.20346 4.45109 6.56927 4.34184 7.1894 4.12402C7.77583 3.92202 8.27078 3.83838 8.67624 3.87133C9.77495 3.9562 10.6004 4.37077 11.1493 5.11766C10.1667 5.68755 9.68064 6.48575 9.69031 7.50972C9.69918 8.3073 10.0015 8.97101 10.5956 9.49801C10.8648 9.7426 11.1655 9.93163 11.5 10.0659C11.4275 10.2673 11.3509 10.4602 11.2695 10.6453ZM8.7496 1.15412C8.7496 1.77927 8.51099 2.36296 8.0354 2.90322C7.46145 3.54549 6.76724 3.91662 6.01443 3.85805C6.00484 3.78306 5.99927 3.70412 5.99927 3.62118C5.99927 3.02104 6.27222 2.37878 6.75693 1.85364C6.99892 1.58775 7.30668 1.36667 7.67991 1.19031C8.05232 1.01658 8.40459 0.920505 8.73589 0.904053C8.74557 0.987625 8.7496 1.0712 8.7496 1.15412V1.15412Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1002_5869">
                      <rect width="11" height="12.5" fill="white" transform="translate(0.5 0.904053)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      {hamburger && (
        <div className="bg-[#000] top-0 left-0 flex justify-between    fixed bg-opacity-65 w-full h-[100%] ">
          <div className=" bg-[#ECF7FF]   pt-[40px] flex  gap-[50px] items-center flex-col w-[230px] h-full">
            <img className="w-[45px]" src={logo} alt="Kolet" />

            <Link onClick={() => window.scrollTo(0, 0)} to="*">
              <h3 className="text-[18px] font-raleway font-semibold text-[#333] -tracking-tight">Home</h3>
            </Link>
            <Link onClick={() => window.scrollTo(0, 0)} to="/signup">
              <div className="w-[163px] h-[38px]  rounded-[4px] justify-center items-center inline-flex bg-[#3498DB] text-[18px] text-[#fff] tracking-tight text-center font-raleway font-semibold ">Join Us</div>
            </Link>
          </div>
          <div onClick={() => setHamburger(false)} className=" cursor-pointer  flex justify-center items-center w-[60px] absolute right-[20px] top-[30px] h-[60px] bg-[#494B48] rounded-[80px]">
            <svg className=" " width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 5L5 19M5 5L19 19" stroke="#F5F5F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
        </div>
      )}
    </>
  );
};
