import security from "../images/security.png";

function Security() {
  return (
    <>
      {/* BIG SCREENS FROM 1280PX */}
      <div className=" 100:hidden  h-fit py-[60px]  mt-[60px] xl:flex w-full justify-center items-center ">
        <div className="w-[1100px] px-[60px] py-[70px] bg-[#3498DB] rounded-[20px] flex justify-between items-center gap-[60px] h-[586px] bg-opacity-10">
          <div className="flex flex-col justify-between items-start gap-[30px] ">
            <h2 className=" text-[#333]   leading-[70px] text-[48px] font-raleway tracking-[0.9px] font-semibold">
              Your Security, Our Priority - Trust <span className="text-[#3498DB]">KOLeT </span> for Safe Transactions
            </h2>
            <p className="text-[22px]  text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[38px] ">
              Explore with Confidence - KOLeT Ensures Your Privacy and Security. Trust KOLeT for a secure and private payment experience. Download now with confidence!
            </p>
            <button className="w-[281px] h-[44px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Download Securely</button>
          </div>
          <img src={security} alt="Secured" />
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1279PX */}
      <div className=" 100:flex  h-fit py-[60px]  mt-[60px] xl:hidden w-full justify-center items-center ">
        <div className="w-[90%] sm:w-[550px] px-[40px] py-[70px] bg-[#3498DB] rounded-[20px] flex flex-col justify-between items-center gap-[60px] h-fit bg-opacity-10">
          <div className="flex sm:max-w-[500px] flex-col justify-between items-start gap-[30px] ">
            <h2 className=" text-[#333]   leading-[70px] text-[32px] font-raleway tracking-[0.9px] font-semibold">
              Your Security, Our Priority - Trust <span className="text-[#3498DB]">KOLeT </span> for Safe Transactions
            </h2>
            <p className="text-[18px]  text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[38px] ">
              Explore with Confidence - KOLeT Ensures Your Privacy and Security. Trust KOLeT for a secure and private payment experience. Download now with confidence!
            </p>
            <button className="w-[281px] h-[44px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Download Securely</button>
          </div>
          <img src={security} alt="Secured" />
        </div>
      </div>
    </>
  );
}

export default Security;
