import { useState } from "react";
import { Link } from "react-router-dom";

export const ForgotPassword = () => {
  const [border, setBorder] = useState(false);
  const [border2, setBorder2] = useState(false);

  return (
    <>
      {/* BIG SCREENS FROM 1280PX */}
      <div className=" 100:hidden xl:flex  w-full h-fit px-[50px] gap-[100px] flex-col justify-center items-center ">
        <div className=" w-full items-center flex justify-between h-[84.15px]">
          <Link to="/login">
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.3335 17H23.6668" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14.5001 21.1666C14.5001 21.1666 10.3335 18.0979 10.3335 16.9999C10.3335 15.9019 14.5002 12.8333 14.5002 12.8333" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Link>
        </div>
        <div className=" w-[424px] h-fit flex flex-col py-[50px] px-[30px] gap-[40px] justify-between items-center rounded-[20px] custom-box-shadow  ">
          <div className=" flex flex-col items-center text-center justify-between gap-[10px] w-[288px]">
            <h3 className="text-[25px] font-semibold tracking-[0.9px] text-[#333] font-raleway ">Forgot Your Password?</h3>
            <p className="text-[15px] text-[#777] tracking-[0.9px] font-montserrat font-normal leading-[25px] ">Enter your email/number to receive a reset code.</p>
          </div>
          <form className="flex flex-col  gap-[20px]">
            <div
              onClick={() => {
                setBorder(true);
                setBorder2(false);
              }}
              className={
                border
                  ? " flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[1px] border-[#3498DB] "
                  : "flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] "
              }
            >
              <input className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="tel" placeholder="Phone Number" />
              <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.68799 5.02981C2.68799 3.41257 2.68799 2.60395 3.1904 2.10154C3.69281 1.59912 4.50144 1.59912 6.11868 1.59912C7.73591 1.59912 8.54452 1.59912 9.04697 2.10154C9.54937 2.60395 9.54937 3.41257 9.54937 5.02981V7.97041C9.54937 9.58764 9.54937 10.3963 9.04697 10.8987C8.54452 11.4011 7.73591 11.4011 6.11868 11.4011C4.50144 11.4011 3.69281 11.4011 3.1904 10.8987C2.68799 10.3963 2.68799 9.58764 2.68799 7.97041V5.02981Z"
                  stroke="#999999"
                  strokeWidth="0.735148"
                  strokeLinecap="round"
                />
                <path d="M5.62866 9.93066H6.60886" stroke="#999999" strokeWidth="0.735148" strokeLinecap="round" strokeLinejoin="round" />
                <path
                  d="M4.64844 1.59912L4.69206 1.86084C4.78659 2.42802 4.83385 2.71161 5.02836 2.88418C5.23126 3.06419 5.5189 3.06942 6.11873 3.06942C6.71857 3.06942 7.00621 3.06419 7.20911 2.88418C7.40363 2.71161 7.45087 2.42802 7.54541 1.86084L7.58903 1.59912"
                  stroke="#999999"
                  strokeWidth="0.735148"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div
              onClick={() => {
                setBorder2(true);
                setBorder(false);
              }}
              className={
                border2
                  ? " flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[1px] border-[#3498DB] "
                  : "flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] "
              }
            >
              <input className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="email" placeholder="Email (optional)" />
              <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.90137 2.94067L5.28943 4.86038C6.53849 5.56809 7.06623 5.56809 8.31529 4.86038L11.7033 2.94067" stroke="#999999" strokeWidth="0.735148" strokeLinejoin="round" />
                <path
                  d="M1.9091 6.60436C1.94113 8.10681 1.95716 8.85799 2.51152 9.41449C3.06588 9.97095 3.83742 9.99031 5.3805 10.0291C6.33152 10.053 7.2732 10.053 8.22423 10.0291C9.76731 9.99031 10.5388 9.97095 11.0932 9.41449C11.6476 8.85799 11.6636 8.10681 11.6956 6.60436C11.7059 6.12127 11.7059 5.64107 11.6956 5.15798C11.6636 3.65557 11.6476 2.90436 11.0932 2.34788C10.5388 1.79141 9.76731 1.77203 8.22423 1.73326C7.2732 1.70936 6.33152 1.70936 5.38049 1.73325C3.83742 1.77202 3.06588 1.7914 2.51152 2.34788C1.95715 2.90435 1.94113 3.65556 1.90909 5.15798C1.89879 5.64107 1.89879 6.12127 1.9091 6.60436Z"
                  stroke="#999999"
                  strokeWidth="0.735148"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </form>
          <Link to="/otp">
            <button className="w-[243px] h-[38px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Send Reset Code</button>
          </Link>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1279PX */}
      <div className="100:flex w-full h-fit gap-[100px] flex-col px-[50px] pb-[40px] xl:hidden justify-center items-center ">
        <div className=" w-full items-center flex justify-between h-[84.15px]">
          <Link to="/login">
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.3335 17H23.6668" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14.5001 21.1666C14.5001 21.1666 10.3335 18.0979 10.3335 16.9999C10.3335 15.9019 14.5002 12.8333 14.5002 12.8333" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Link>
        </div>
        <div className=" w-[90%] sm:w-[424px] h-fit flex flex-col py-[40px] px-[30px] gap-[40px] justify-between items-center rounded-[20px] custom-box-shadow  ">
          <div className=" flex flex-col items-center phone:w-[294px] text-center justify-between gap-[10px] w-full">
            <h3 className="text-[25px] font-semibold tracking-[0.9px] text-[#333] font-raleway ">Forgot Your Password?</h3>
            <p className="text-[15px] text-[#777] tracking-[0.9px] font-montserrat font-normal leading-[25px] ">Enter your email/number to receive a reset code.</p>
          </div>
          <form className="flex w-full flex-col  gap-[20px]">
            <div
              onClick={() => {
                setBorder(true);
                setBorder2(false);
              }}
              className={
                border
                  ? " flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[1px] border-[#3498DB] "
                  : "flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] "
              }
            >
              <input className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="tel" placeholder="Phone Number" />
              <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.68799 5.02981C2.68799 3.41257 2.68799 2.60395 3.1904 2.10154C3.69281 1.59912 4.50144 1.59912 6.11868 1.59912C7.73591 1.59912 8.54452 1.59912 9.04697 2.10154C9.54937 2.60395 9.54937 3.41257 9.54937 5.02981V7.97041C9.54937 9.58764 9.54937 10.3963 9.04697 10.8987C8.54452 11.4011 7.73591 11.4011 6.11868 11.4011C4.50144 11.4011 3.69281 11.4011 3.1904 10.8987C2.68799 10.3963 2.68799 9.58764 2.68799 7.97041V5.02981Z"
                  stroke="#999999"
                  strokeWidth="0.735148"
                  strokeLinecap="round"
                />
                <path d="M5.62866 9.93066H6.60886" stroke="#999999" strokeWidth="0.735148" strokeLinecap="round" strokeLinejoin="round" />
                <path
                  d="M4.64844 1.59912L4.69206 1.86084C4.78659 2.42802 4.83385 2.71161 5.02836 2.88418C5.23126 3.06419 5.5189 3.06942 6.11873 3.06942C6.71857 3.06942 7.00621 3.06419 7.20911 2.88418C7.40363 2.71161 7.45087 2.42802 7.54541 1.86084L7.58903 1.59912"
                  stroke="#999999"
                  strokeWidth="0.735148"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div
              onClick={() => {
                setBorder2(true);
                setBorder(false);
              }}
              className={
                border2
                  ? " flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[1px] border-[#3498DB] "
                  : "flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] "
              }
            >
              <input className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="email" placeholder="Email (optional)" />
              <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.90137 2.94067L5.28943 4.86038C6.53849 5.56809 7.06623 5.56809 8.31529 4.86038L11.7033 2.94067" stroke="#999999" strokeWidth="0.735148" strokeLinejoin="round" />
                <path
                  d="M1.9091 6.60436C1.94113 8.10681 1.95716 8.85799 2.51152 9.41449C3.06588 9.97095 3.83742 9.99031 5.3805 10.0291C6.33152 10.053 7.2732 10.053 8.22423 10.0291C9.76731 9.99031 10.5388 9.97095 11.0932 9.41449C11.6476 8.85799 11.6636 8.10681 11.6956 6.60436C11.7059 6.12127 11.7059 5.64107 11.6956 5.15798C11.6636 3.65557 11.6476 2.90436 11.0932 2.34788C10.5388 1.79141 9.76731 1.77203 8.22423 1.73326C7.2732 1.70936 6.33152 1.70936 5.38049 1.73325C3.83742 1.77202 3.06588 1.7914 2.51152 2.34788C1.95715 2.90435 1.94113 3.65556 1.90909 5.15798C1.89879 5.64107 1.89879 6.12127 1.9091 6.60436Z"
                  stroke="#999999"
                  strokeWidth="0.735148"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </form>
          <Link to="/otp">
            <button className="w-[200px] h-[38px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Send Reset Code</button>
          </Link>
        </div>
      </div>
    </>
  );
};
