function PrivacyPolicy({ closeModal }) {
  //   if (!isOpen) return null;
  return (
    <>
      {/* BIG SCREENS FROM 1280PX */}
      <div className=" bg-black 100:hidden  xl:flex  fixed z-40 justify-center items-center bg-opacity-55 w-full h-full left-0 top-0  ">
        <div onClick={closeModal} className=" cursor-pointer  flex justify-center items-center w-[60px] absolute right-[40px] top-[40px] h-[60px] bg-[#494B48] rounded-[80px]">
          <svg className=" " width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 5L5 19M5 5L19 19" stroke="#F5F5F5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className=" w-[800px] flex flex-col items-center justify-between gap-[40px] px-[40px] py-[50px] h-[700px] rounded-[20px] bg-[#f5f5f5]">
          <h3 className=" font-raleway tracking-tight font-semibold  text-[20px] text-[#333]">PRIVACY NOTICE</h3>
          <div className=" flex flex-col justify-between gap-[20px] overflow-y-scroll  text-left ">
            <p className=" text-[#777] font-montserrat font-normal text-[14px] tracking-tight leading-[25px] ">
              This privacy notice for Fitchco Technology Solution Limited ("we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services") Questions or
              concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.
            </p>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium text-[#333] "> 1. WHAT INFORMATION DO WE COLLECT? </h3>
              <p>
                In Short: We collect personal information that you provide to us. We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our
                products and Services, when you participate in activities on the Services, or otherwise when you contact us. Sensitive Information. We do not process sensitive information. All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to such personal information. <br />
                <br />
                Sensitive Information. We do not process sensitive information. All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
              </p>
            </div>

            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-normal  text-[#333] "> Information automatically collected </h3>
              <p>
                In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services. We automatically collect certain information when you visit,
                use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics,
                operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the
                security and operation of our Services, and for our internal analytics and reporting purposes. Like many businesses, we also collect information through cookies and similar technologies.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">2. HOW DO WE PROCESS YOUR INFORMATION? </h3>
              <p>
                In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your
                consent. We process your personal information for a variety of reasons, depending on how you interact with our Services, including:{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? </h3>
              <p>In Short: We may share information in specific situations described in this section and/or with the following third parties. We may need to share your personal information in the following situations: </p>
              <br />
              <p>Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p>
              <br />
              <p>
                Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other
                companies that we control or that are under common control with us.
              </p>
              <br />
              <p>Business Partners. We may share your information with our business partners to offer you certain products, services, or promotions. </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? </h3>
              <p>In Short: We may use cookies and other tracking technologies to collect and store your information. </p>
              <p>
                We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie
                Notice.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS? </h3>
              <p>
                In Short: If you choose to register or log in to our Services using a social media account, we may have access to certain information about you. Our Services offer you the ability to register and log in using your third-party social
                media account details (like your Facebook or X logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the
                social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such asocial media platform.{" "}
              </p>
              <p>
                We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for,
                other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your
                privacy preferences on their sites and apps.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY? </h3>
              <p>
                In Short: We may transfer, store, and process your information in countries other than your own. Our servers are located in United State. If you are accessing our Services from outside Nigeria, please be aware that your information
                may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" above), in and other
                countries. If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or Switzerland, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your
                country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
              <p>
                In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law. We will only keep your personal information for as long as it is necessary for the
                purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).{" "}
              </p>
              <br />
              <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is no</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">8. DO WE COLLECT INFORMATION FROM MINORS?</h3>
              <p>
                In Short: We do not knowingly collect data from or market to children under 18 years of age. We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least
                18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the
                account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at{" "}
                <a className=" cursor-pointer text-[#3498DB]" href="mailto:report@fitchcong.co">
                  report@fitchcong.com
                </a>
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">9. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
              <p>
                In Short: You may review, change, or terminate your account at any time. Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the
                applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                below. However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful
                processing grounds other than consent.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-normal  text-[#333] "> Account Information</h3>
              <p>
                If you would at any time like to review or change the information in your account or terminate your account, you can: Upon your request to terminate your account, we will deactivate or delete your account and information from our
                active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">10. CONTROLS FOR DO-NOT-TRACK FEATURES:</h3>
              <p>
                Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities
                monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that
                automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">11. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
              <p>
                In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws. We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated
                version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We
                encourage you to review this privacy notice frequently to be informed of how we are protecting your information.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
              <p>
                If you have questions or comments about this notice, you may contact us via any of the channels below;
                <br />
                Email: info@fitchcong.com
                <br />
                Mobile Number: +234 706 636 6375
                <br />
                Address: Chief Ore Famewo Street, Off Kusenla Road. Lagos
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h3>
              <p>
                Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal
                information, please fill out and submit a data subject access request on our website. www.fitchcong.com
              </p>
            </div>
            <div className=" flex-col flex justify-between items-center gap-[10px]">
              <button
                //    onClick={onClose}
                className="w-[243px] h-[38px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold "
              >
                Agree
              </button>
              <button
                //    onClick={onClose}
                className="w-[243px] h-[38px] rounded-[4px] text-[18px] text-[#333] font-raleway border-[1.5px]  border-[#3498DB] tracking-[0.9px]  font-semibold "
              >
                Decline
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1279PX */}
      <div className=" bg-black 100:flex xl:hidden  fixed z-40 justify-center items-center bg-opacity-55 w-full h-full left-0 top-0  ">
        <div onClick={closeModal} className=" cursor-pointer  flex justify-center items-center w-[60px] absolute right-[40px] top-[40px] h-[60px] bg-[#494B48] rounded-[80px]">
          <svg className=" " width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 5L5 19M5 5L19 19" stroke="#F5F5F5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className=" w-[80%] relative top-[50px] flex flex-col items-center justify-between gap-[40px] px-[30px] py-[50px] h-[550px] rounded-[20px] bg-[#f5f5f5]">
          <h3 className=" font-raleway tracking-tight font-semibold  text-[20px] text-[#333]">PRIVACY NOTICE</h3>
          <div className=" flex flex-col justify-between gap-[20px] overflow-y-scroll  text-left ">
            <p className=" text-[#777] font-montserrat font-normal text-[14px] tracking-tight leading-[25px] ">
              This privacy notice for Fitchco Technology Solution Limited ("we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services") Questions or
              concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.
            </p>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium text-[#333] "> 1. WHAT INFORMATION DO WE COLLECT? </h3>
              <p>
                In Short: We collect personal information that you provide to us. We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our
                products and Services, when you participate in activities on the Services, or otherwise when you contact us. Sensitive Information. We do not process sensitive information. All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to such personal information. <br />
                <br />
                Sensitive Information. We do not process sensitive information. All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
              </p>
            </div>

            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-normal  text-[#333] "> Information automatically collected </h3>
              <p>
                In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services. We automatically collect certain information when you visit,
                use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics,
                operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the
                security and operation of our Services, and for our internal analytics and reporting purposes. Like many businesses, we also collect information through cookies and similar technologies.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">2. HOW DO WE PROCESS YOUR INFORMATION? </h3>
              <p>
                In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your
                consent. We process your personal information for a variety of reasons, depending on how you interact with our Services, including:{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? </h3>
              <p>In Short: We may share information in specific situations described in this section and/or with the following third parties. We may need to share your personal information in the following situations: </p>
              <br />
              <p>Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p>
              <br />
              <p>
                Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other
                companies that we control or that are under common control with us.
              </p>
              <br />
              <p>Business Partners. We may share your information with our business partners to offer you certain products, services, or promotions. </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? </h3>
              <p>In Short: We may use cookies and other tracking technologies to collect and store your information. </p>
              <p>
                We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie
                Notice.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS? </h3>
              <p>
                In Short: If you choose to register or log in to our Services using a social media account, we may have access to certain information about you. Our Services offer you the ability to register and log in using your third-party social
                media account details (like your Facebook or X logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the
                social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such asocial media platform.{" "}
              </p>
              <p>
                We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for,
                other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your
                privacy preferences on their sites and apps.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY? </h3>
              <p>
                In Short: We may transfer, store, and process your information in countries other than your own. Our servers are located in United State. If you are accessing our Services from outside Nigeria, please be aware that your information
                may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" above), in and other
                countries. If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or Switzerland, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your
                country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
              <p>
                In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law. We will only keep your personal information for as long as it is necessary for the
                purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).{" "}
              </p>
              <br />
              <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is no</p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">8. DO WE COLLECT INFORMATION FROM MINORS?</h3>
              <p>
                In Short: We do not knowingly collect data from or market to children under 18 years of age. We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least
                18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the
                account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at{" "}
                <a className=" cursor-pointer text-[#3498DB]" href="mailto:report@fitchcong.co">
                  report@fitchcong.com
                </a>
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">9. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
              <p>
                In Short: You may review, change, or terminate your account at any time. Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the
                applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                below. However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful
                processing grounds other than consent.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-normal  text-[#333] "> Account Information</h3>
              <p>
                If you would at any time like to review or change the information in your account or terminate your account, you can: Upon your request to terminate your account, we will deactivate or delete your account and information from our
                active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">10. CONTROLS FOR DO-NOT-TRACK FEATURES:</h3>
              <p>
                Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities
                monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that
                automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">11. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
              <p>
                In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws. We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated
                version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We
                encourage you to review this privacy notice frequently to be informed of how we are protecting your information.{" "}
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
              <p>
                If you have questions or comments about this notice, you may contact us via any of the channels below;
                <br />
                Email: info@fitchcong.com
                <br />
                Mobile Number: +234 706 636 6375
                <br />
                Address: Chief Ore Famewo Street, Off Kusenla Road. Lagos
              </p>
            </div>
            <div className=" text-[#777] font-montserrat font-normal text-[13px] tracking-tight leading-[27px] ">
              <h3 className=" font-medium  text-[#333] ">13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h3>
              <p>
                Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal
                information, please fill out and submit a data subject access request on our website. www.fitchcong.com
              </p>
            </div>
            <div className=" flex-col flex justify-between items-center gap-[10px]">
              <button onClick={closeModal} className="w-[243px] h-[38px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">
                Agree
              </button>
              <button onClick={closeModal} className="w-[243px] h-[38px] rounded-[4px] text-[18px] text-[#333] font-raleway border-[1.5px]  border-[#3498DB] tracking-[0.9px]  font-semibold ">
                Decline
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
