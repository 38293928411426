import { Link } from "react-router-dom";
import logo from "../images/Logo.svg";
import { useState } from "react";

const Header = ({ home, scrollToSection, features, testimonial, howItWorks, contact }) => {
  const [hamburger, setHamburger] = useState(false);
  const [navBar, setNavBar] = useState(false);
  function handleScrollBackground() {
    if (window.scrollY >= 20) {
      setNavBar(true);
    } else {
      setNavBar(false);
    }
  }
  window.addEventListener("scroll", handleScrollBackground);

  return (
    <>
      {/* BIG SCREENS FROM 1280PX */}
      <div className={`100:hidden top-0 fixed w-full h-[104px] px-[50px] py-[20px] z-30 xl:flex ${navBar ? " bg-[#fff] bg-opacity-40" : " bg-transparent "} justify-center items-center`}>
        <div className="flex justify-between items-center w-[95%] ">
          <img src={logo} alt="KOLeT" />
          <div className="flex gap-[50px] justify-between items-center text-[18px] font-raleway font-semibold text-[#333] -tracking-tight">
            <h3
              onClick={() => {
                scrollToSection(home);
              }}
              className=" cursor-pointer"
            >
              Home
            </h3>
            <h3
              onClick={() => {
                scrollToSection(features);
              }}
              className=" cursor-pointer"
            >
              Features
            </h3>
            <h3
              onClick={() => {
                scrollToSection(howItWorks);
              }}
              className=" cursor-pointer"
            >
              How It Works
            </h3>
            <h3
              onClick={() => {
                scrollToSection(testimonial);
              }}
              className=" cursor-pointer"
            >
              Testimonials
            </h3>
            <h3
              onClick={() => {
                scrollToSection(contact);
              }}
              className=" cursor-pointer"
            >
              Contact
            </h3>
          </div>
          <div className="flex w-[346px] justify-between items-center">
            <Link onClick={() => window.scrollTo(0, 0)} to="/login">
              <div className="w-[163px] h-[38px] border-solid border-[1px] rounded-[4px] justify-center items-center inline-flex border-[#3498DB] text-[18px] text-[#333] tracking-tight text-center font-raleway font-semibold ">Sign In</div>
            </Link>
            <Link onClick={() => window.scrollTo(0, 0)} to="/signup">
              <div className="w-[163px] h-[38px]  rounded-[4px] justify-center items-center inline-flex bg-[#3498DB] text-[18px] text-[#fff] tracking-tight text-center font-raleway font-semibold ">Join Us</div>
            </Link>
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1279PX */}
      <div className={`100:flex w-full h-[104px] top-0 fixed justify-between px-[40px] py-[20px] xl:hidden z-30  items-center ${navBar ? " bg-[#fff] bg-opacity-40" : " bg-transparent "} `}>
        <div className="flex justify-between items-center w-full ">
          <img src={logo} alt="KOLeT" />
          <svg onClick={() => setHamburger(true)} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 12.1709H10" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 5.1709H4" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 19.1709H4" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
      </div>
      {hamburger && (
        <div className="bg-[#000] top-0 left-0 flex justify-between z-40   fixed bg-opacity-65 w-full h-[100%] ">
          <div className=" bg-[#ECF7FF]   pt-[40px] flex  gap-[50px] items-center flex-col w-[230px] h-full">
            <img className="w-[45px]" src={logo} alt="KOLeT" />
            <div className="flex flex-col  gap-[50px] justify-between items-center text-[18px] font-raleway font-semibold text-[#333] -tracking-tight">
              <h3
                onClick={() => {
                  scrollToSection(home);
                  setHamburger(false);
                }}
                className=" cursor-pointer"
              >
                Home
              </h3>
              <h3
                onClick={() => {
                  scrollToSection(features);
                  setHamburger(false);
                }}
                className=" cursor-pointer"
              >
                Features
              </h3>
              <h3
                onClick={() => {
                  scrollToSection(howItWorks);
                  setHamburger(false);
                }}
                className=" cursor-pointer"
              >
                How It Works
              </h3>
              <h3
                onClick={() => {
                  scrollToSection(testimonial);
                  setHamburger(false);
                }}
                className=" cursor-pointer"
              >
                Testimonials
              </h3>
              <h3
                onClick={() => {
                  scrollToSection(contact);
                  setHamburger(false);
                }}
                className=" cursor-pointer"
              >
                Contact
              </h3>

              <Link to="/login">
                <div className="w-[163px] h-[38px] border-solid border-[1px] rounded-[4px] justify-center items-center inline-flex border-[#3498DB] text-[18px] text-[#333] tracking-tight text-center font-raleway font-semibold ">Sign In</div>
              </Link>
              <Link to="/signup">
                <div className="w-[163px] h-[38px]  rounded-[4px] justify-center items-center inline-flex bg-[#3498DB] text-[18px] text-[#fff] tracking-tight text-center font-raleway font-semibold ">Join Us</div>
              </Link>
            </div>
          </div>
          <div onClick={() => setHamburger(false)} className=" cursor-pointer  flex justify-center items-center w-[60px] absolute right-[35px] top-[35px] h-[60px] bg-[#494B48] rounded-[80px]">
            <svg className=" " width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 5L5 19M5 5L19 19" stroke="#F5F5F5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
