import SideBarUser from "./SideBarUser";
import avatar from "../images/KoletUser.png";

export const ProfileUser = () => {
  return (
    <>
      <div className=" flex  justify-between">
        <SideBarUser />
        {/* BIG SCREENS FROM 1280PX */}
        <div className=" 100:hidden gap-[40px]  pl-[290px] pb-[50px] h-screen w-full  pr-[50px] xl:flex flex-col items-center  justify-center">
          <div className="flex w-full justify-center items-center gap-[30px] flex-col">
            <img className="w-[197px] h-[197x] rounded-full" src={avatar} alt="User" />
            <h3 className=" text-[#333] font-raleway font-semibold text-[34px] tracking-[0.9px] ">John Doe</h3>
            <div className=" w-[85%]">
              <div className=" px-[10px]  w-full h-[82px] gap-[20px] flex justify-start items-center border-b-[0.7px] border-b-[#BDC3C7] ">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.57757 15.4816C5.1628 16.324 1.45336 18.0441 3.71266 20.1966C4.81631 21.248 6.04549 22 7.59087 22H16.4091C17.9545 22 19.1837 21.248 20.2873 20.1966C22.5466 18.0441 18.8372 16.324 17.4224 15.4816C14.1048 13.5061 9.89519 13.5061 6.57757 15.4816Z"
                    stroke="#3498DB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z" stroke="#3498DB" strokeWidth="1.5" />
                </svg>

                <div className=" flex flex-col gap-[5px]">
                  <h4 className="text-[16px] text-[#333] tracking-[0.9px] font-medium font-montserrat">John Doe</h4>
                  <h5 className="text-[12px] text-[#999] tracking-[0.9px] font-medium font-montserrat">Full Name</h5>
                </div>
              </div>
              <div className=" px-[10px]  w-full h-[82px] gap-[20px] flex justify-start items-center border-b-[0.7px] border-b-[#BDC3C7] ">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5 9C5 5.70017 5 4.05025 6.02513 3.02513C7.05025 2 8.70017 2 12 2C15.2998 2 16.9497 2 17.9749 3.02513C19 4.05025 19 5.70017 19 9V15C19 18.2998 19 19.9497 17.9749 20.9749C16.9497 22 15.2998 22 12 22C8.70017 22 7.05025 22 6.02513 20.9749C5 19.9497 5 18.2998 5 15V9Z"
                    stroke="#3498DB"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                  />
                  <path d="M11 19H13" stroke="#3498DB" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M9 2L9.089 2.53402C9.28188 3.69129 9.37832 4.26993 9.77519 4.62204C10.1892 4.98934 10.7761 5 12 5C13.2239 5 13.8108 4.98934 14.2248 4.62204C14.6217 4.26993 14.7181 3.69129 14.911 2.53402L15 2"
                    stroke="#3498DB"
                    strokeWidth="1.25"
                    strokeLinejoin="round"
                  />
                </svg>

                <div className=" flex flex-col gap-[5px]">
                  <h4 className="text-[16px] text-[#333] tracking-[0.9px] font-medium font-montserrat">23481412345678</h4>
                  <h5 className="text-[12px] text-[#999] tracking-[0.9px] font-medium font-montserrat">Phone Number</h5>
                </div>
              </div>
              <div className=" px-[10px]  w-full h-[82px] gap-[20px] flex justify-start items-center border-b-[0.7px] border-b-[#BDC3C7] ">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 6L8.91302 9.91697C11.4616 11.361 12.5384 11.361 15.087 9.91697L22 6" stroke="#3498DB" stroke-width="1.25" stroke-linejoin="round" />
                  <path
                    d="M2.01577 13.4756C2.08114 16.5412 2.11383 18.0739 3.24496 19.2094C4.37608 20.3448 5.95033 20.3843 9.09883 20.4634C11.0393 20.5122 12.9607 20.5122 14.9012 20.4634C18.0497 20.3843 19.6239 20.3448 20.7551 19.2094C21.8862 18.0739 21.9189 16.5412 21.9842 13.4756C22.0053 12.4899 22.0053 11.5101 21.9842 10.5244C21.9189 7.45886 21.8862 5.92609 20.7551 4.79066C19.6239 3.65523 18.0497 3.61568 14.9012 3.53657C12.9607 3.48781 11.0393 3.48781 9.09882 3.53656C5.95033 3.61566 4.37608 3.65521 3.24495 4.79065C2.11382 5.92608 2.08114 7.45885 2.01576 10.5244C1.99474 11.5101 1.99475 12.4899 2.01577 13.4756Z"
                    stroke="#3498DB"
                    stroke-width="1.25"
                    stroke-linejoin="round"
                  />
                </svg>

                <div className=" flex flex-col gap-[5px]">
                  <h4 className="text-[16px] text-[#333] tracking-[0.9px] font-medium font-montserrat">Johndoe@gmail.com</h4>
                  <h5 className="text-[12px] text-[#999] tracking-[0.9px] font-medium font-montserrat">Email</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* SMALL SCREENS FROM 100PX TO 1279PX */}
        <div className="100:flex xl:hidden gap-[40px] pl-[115px] pb-[50px] h-screen w-full  pr-[20px]  flex-col items-center  justify-center">
          <div className="flex w-full justify-center items-center gap-[30px] flex-col">
            <img className="w-[150px] h-[150px] rounded-full" src={avatar} alt="User" />
            <h3 className=" text-[#333] font-raleway font-semibold text-[34px] tracking-[0.9px] ">John Doe</h3>
            <div className=" w-[85%]">
              <div className=" px-[10px]  w-full h-[82px] gap-[20px] flex justify-start items-center border-b-[0.7px] border-b-[#BDC3C7] ">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.57757 15.4816C5.1628 16.324 1.45336 18.0441 3.71266 20.1966C4.81631 21.248 6.04549 22 7.59087 22H16.4091C17.9545 22 19.1837 21.248 20.2873 20.1966C22.5466 18.0441 18.8372 16.324 17.4224 15.4816C14.1048 13.5061 9.89519 13.5061 6.57757 15.4816Z"
                    stroke="#3498DB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z" stroke="#3498DB" strokeWidth="1.5" />
                </svg>

                <div className=" flex flex-col gap-[5px]">
                  <h4 className="text-[16px] text-[#333] tracking-[0.9px] font-medium font-montserrat">John Doe</h4>
                  <h5 className="text-[12px] text-[#999] tracking-[0.9px] font-medium font-montserrat">Full Name</h5>
                </div>
              </div>
              <div className=" px-[10px]  w-full h-[82px] gap-[20px] flex justify-start items-center border-b-[0.7px] border-b-[#BDC3C7] ">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5 9C5 5.70017 5 4.05025 6.02513 3.02513C7.05025 2 8.70017 2 12 2C15.2998 2 16.9497 2 17.9749 3.02513C19 4.05025 19 5.70017 19 9V15C19 18.2998 19 19.9497 17.9749 20.9749C16.9497 22 15.2998 22 12 22C8.70017 22 7.05025 22 6.02513 20.9749C5 19.9497 5 18.2998 5 15V9Z"
                    stroke="#3498DB"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                  />
                  <path d="M11 19H13" stroke="#3498DB" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M9 2L9.089 2.53402C9.28188 3.69129 9.37832 4.26993 9.77519 4.62204C10.1892 4.98934 10.7761 5 12 5C13.2239 5 13.8108 4.98934 14.2248 4.62204C14.6217 4.26993 14.7181 3.69129 14.911 2.53402L15 2"
                    stroke="#3498DB"
                    strokeWidth="1.25"
                    strokeLinejoin="round"
                  />
                </svg>

                <div className=" flex flex-col gap-[5px]">
                  <h4 className="text-[16px] text-[#333] tracking-[0.9px] font-medium font-montserrat">23481412345678</h4>
                  <h5 className="text-[12px] text-[#999] tracking-[0.9px] font-medium font-montserrat">Phone Number</h5>
                </div>
              </div>
              <div className=" px-[10px]  w-full h-[82px] gap-[20px] flex justify-start items-center border-b-[0.7px] border-b-[#BDC3C7] ">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 6L8.91302 9.91697C11.4616 11.361 12.5384 11.361 15.087 9.91697L22 6" stroke="#3498DB" stroke-width="1.25" stroke-linejoin="round" />
                  <path
                    d="M2.01577 13.4756C2.08114 16.5412 2.11383 18.0739 3.24496 19.2094C4.37608 20.3448 5.95033 20.3843 9.09883 20.4634C11.0393 20.5122 12.9607 20.5122 14.9012 20.4634C18.0497 20.3843 19.6239 20.3448 20.7551 19.2094C21.8862 18.0739 21.9189 16.5412 21.9842 13.4756C22.0053 12.4899 22.0053 11.5101 21.9842 10.5244C21.9189 7.45886 21.8862 5.92609 20.7551 4.79066C19.6239 3.65523 18.0497 3.61568 14.9012 3.53657C12.9607 3.48781 11.0393 3.48781 9.09882 3.53656C5.95033 3.61566 4.37608 3.65521 3.24495 4.79065C2.11382 5.92608 2.08114 7.45885 2.01576 10.5244C1.99474 11.5101 1.99475 12.4899 2.01577 13.4756Z"
                    stroke="#3498DB"
                    strokeWidth="1.25"
                    strokeLinejoin="round"
                  />
                </svg>

                <div className=" flex flex-col gap-[5px]">
                  <h4 className="text-[16px] text-[#333] tracking-[0.9px] font-medium font-montserrat">Johndoe@gmail.com</h4>
                  <h5 className="text-[12px] text-[#999] tracking-[0.9px] font-medium font-montserrat">Email</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
