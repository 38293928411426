import { AdminFooter } from "./AdminFooter";
import { Header } from "./Header";
import SideBarUser from "./SideBarUser";

function PaymentHistory() {
  return (
    <>
      <div className=" flex  justify-between">
        <SideBarUser />
        {/* BIG SCREENS FROM 1280PX */}
        <div className=" 100:hidden   pl-[290px] pb-[50px] h-fit w-full  pr-[40px] xl:flex flex-col  justify-between">
          <Header />
          <div className="flex w-[560px] flex-col gap-[10px] justify-between">
            <h1 className="text-[#333] tracking-[0.9px] text-[36px] font-raleway font-medium">Payment History</h1>
            <p className="text-[16px] leading-[30px] tracking-[0.9px] text-[#999] font-montserrat font-normal ">Explore Your Payment History. Gain Insight and Stay in Control.</p>
          </div>
          <div className=" flex  mt-[40px] flex-col justify-center items-center gap-[15px]">
            <div className="w-full  gap-[15px] flex items-center justify-between">
              {/* Box 1 */}
              <div className=" w-full px-[40px] py-[19px] h-[188px] justify-center items-center flex flex-col gap-[20px] rounded-[20px] bg-white ">
                <h3 className="text-[18px] text-[#333] tracking-[0.9px] font-raleway font-medium">Total Payments</h3>
                <div className=" w-[60px] h-[60px] flex justify-center items-center rounded-[10px] bg-[#2ECC71] bg-opacity-5 ">
                  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M33.86 3.33325C32.0043 3.33325 30.5 7.8104 30.5 13.3333H33.86C35.4793 13.3333 36.289 13.3333 36.7902 12.7742C37.2915 12.2151 37.2042 11.4788 37.0297 10.0063C36.569 6.11897 35.3238 3.33325 33.86 3.33325Z"
                      stroke="#2ECC71"
                      strokeWidth="2"
                    />
                    <path
                      d="M30.4999 13.4237V31.0763C30.4999 33.5958 30.4999 34.8554 29.7299 35.3513C28.4718 36.1618 26.5268 34.4623 25.5484 33.8454C24.7401 33.3356 24.3361 33.0808 23.8874 33.0661C23.4028 33.0501 22.9914 33.2946 22.1181 33.8454L18.9333 35.8539C18.0741 36.3956 17.6446 36.6666 17.1666 36.6666C16.6886 36.6666 16.259 36.3956 15.3999 35.8539L12.2151 33.8454C11.4068 33.3356 11.0027 33.0808 10.5541 33.0661C10.0695 33.0501 9.65814 33.2946 8.7847 33.8454C7.8065 34.4623 5.86137 36.1618 4.60317 35.3513C3.83325 34.8554 3.83325 33.5958 3.83325 31.0763V13.4237C3.83325 8.667 3.83325 6.28867 5.29772 4.81097C6.76219 3.33325 9.1192 3.33325 13.8333 3.33325H33.8333"
                      stroke="#2ECC71"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M10.5 10H23.8333" stroke="#2ECC71" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M13.8333 16.6667H10.5" stroke="#2ECC71" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M26.612 22.778H25.38V23.576H26.612V24.444H25.38V28H23.63L19.164 21.35V28H17.344V24.444H16.126V23.576H17.344V22.778H16.126V21.91H17.344V18.2H19.094L23.588 24.892V18.2H25.38V21.91H26.612V22.778Z" fill="#2ECC71" />
                  </svg>
                </div>
                <h2 className=" tracking-[0.9px] text-[24px] text-[#333] font-montserrat font-medium ">400,000</h2>
              </div>
              {/* Box 2 */}
              <div className=" w-full px-[40px] py-[19px] h-[188px]  justify-center items-center flex flex-col gap-[20px] rounded-[20px] bg-white ">
                <h3 className="text-[18px] tracking-[0.9px] text-[#333] font-raleway font-medium">Total Amount Paid</h3>
                <div className=" w-[60px] h-[60px] flex justify-center items-center rounded-[10px] bg-[#2ECC71] bg-opacity-5 ">
                  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M33.86 3.33325C32.0043 3.33325 30.5 7.8104 30.5 13.3333H33.86C35.4793 13.3333 36.289 13.3333 36.7902 12.7742C37.2915 12.2151 37.2042 11.4788 37.0297 10.0063C36.569 6.11897 35.3238 3.33325 33.86 3.33325Z"
                      stroke="#2ECC71"
                      strokeWidth="2"
                    />
                    <path
                      d="M30.4999 13.4237V31.0763C30.4999 33.5958 30.4999 34.8554 29.7299 35.3513C28.4718 36.1618 26.5268 34.4623 25.5484 33.8454C24.7401 33.3356 24.3361 33.0808 23.8874 33.0661C23.4028 33.0501 22.9914 33.2946 22.1181 33.8454L18.9333 35.8539C18.0741 36.3956 17.6446 36.6666 17.1666 36.6666C16.6886 36.6666 16.259 36.3956 15.3999 35.8539L12.2151 33.8454C11.4068 33.3356 11.0027 33.0808 10.5541 33.0661C10.0695 33.0501 9.65814 33.2946 8.7847 33.8454C7.8065 34.4623 5.86137 36.1618 4.60317 35.3513C3.83325 34.8554 3.83325 33.5958 3.83325 31.0763V13.4237C3.83325 8.667 3.83325 6.28867 5.29772 4.81097C6.76219 3.33325 9.1192 3.33325 13.8333 3.33325H33.8333"
                      stroke="#2ECC71"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M10.5 10H23.8333" stroke="#2ECC71" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M13.8333 16.6667H10.5" stroke="#2ECC71" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M26.612 22.778H25.38V23.576H26.612V24.444H25.38V28H23.63L19.164 21.35V28H17.344V24.444H16.126V23.576H17.344V22.778H16.126V21.91H17.344V18.2H19.094L23.588 24.892V18.2H25.38V21.91H26.612V22.778Z" fill="#2ECC71" />
                  </svg>
                </div>
                <h2 className=" tracking-[0.9px] text-[24px] text-[#333] font-montserrat font-medium ">350,000</h2>
              </div>
              {/* Box 3 */}
              <div className=" w-full px-[40px] py-[19px] h-[188px]  justify-center items-center flex flex-col gap-[20px] rounded-[20px] bg-white ">
                <h3 className="text-[18px] text-[#333] tracking-[0.9px] font-raleway font-medium">Average Payment Amount</h3>
                <div className=" w-[60px] h-[60px] flex justify-center items-center rounded-[10px] bg-[#2ECC71] bg-opacity-5 ">
                  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M33.86 3.33325C32.0043 3.33325 30.5 7.8104 30.5 13.3333H33.86C35.4793 13.3333 36.289 13.3333 36.7902 12.7742C37.2915 12.2151 37.2042 11.4788 37.0297 10.0063C36.569 6.11897 35.3238 3.33325 33.86 3.33325Z"
                      stroke="#2ECC71"
                      strokeWidth="2"
                    />
                    <path
                      d="M30.4999 13.4237V31.0763C30.4999 33.5958 30.4999 34.8554 29.7299 35.3513C28.4718 36.1618 26.5268 34.4623 25.5484 33.8454C24.7401 33.3356 24.3361 33.0808 23.8874 33.0661C23.4028 33.0501 22.9914 33.2946 22.1181 33.8454L18.9333 35.8539C18.0741 36.3956 17.6446 36.6666 17.1666 36.6666C16.6886 36.6666 16.259 36.3956 15.3999 35.8539L12.2151 33.8454C11.4068 33.3356 11.0027 33.0808 10.5541 33.0661C10.0695 33.0501 9.65814 33.2946 8.7847 33.8454C7.8065 34.4623 5.86137 36.1618 4.60317 35.3513C3.83325 34.8554 3.83325 33.5958 3.83325 31.0763V13.4237C3.83325 8.667 3.83325 6.28867 5.29772 4.81097C6.76219 3.33325 9.1192 3.33325 13.8333 3.33325H33.8333"
                      stroke="#2ECC71"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M10.5 10H23.8333" stroke="#2ECC71" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M13.8333 16.6667H10.5" stroke="#2ECC71" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M26.612 22.778H25.38V23.576H26.612V24.444H25.38V28H23.63L19.164 21.35V28H17.344V24.444H16.126V23.576H17.344V22.778H16.126V21.91H17.344V18.2H19.094L23.588 24.892V18.2H25.38V21.91H26.612V22.778Z" fill="#2ECC71" />
                  </svg>
                </div>
                <h2 className=" tracking-[0.9px] text-[24px] text-[#333] font-montserrat font-medium ">120,000</h2>
              </div>
            </div>
            {/* Tables start here */}
            <div className=" w-full p-[20px] h-fit flex flex-col gap-[15px] rounded-[20px] bg-white ">
              <div className="flex justify-between w-full">
                <h4 className=" font-raleway font-semibold text-[18px] text-[#333] tracking-[0.9px]  ">Payment History</h4>
                <div className="flex justify-between items-center gap-[10px]">
                  <h3 className=" font-montserrat font-normal text-[#999] tracking-[0.9px] text-[12px]">Sort:</h3>
                  <div>
                    <select className="w-[73px] text-[12px] tracking-[0.9px] font-montserrat font-normal text-[#999] outline-none rounded-[20px] pl-[10px] py-[5px] bg-[#F5F5F5] h-[25px]" name="calendar" id="calendar">
                      <option className=" border-b-[0.7px]" value="Year">
                        Year
                      </option>
                      <option value="Month">Month</option>
                      <option value="Week">Week</option>
                    </select>
                  </div>
                </div>
              </div>
              <table className=" w-full">
                <thead>
                  <tr className="  text-[14px] text-[#999] tracking-[0.9px] font-medium font-montserrat w-full h-[57px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>ID</th>

                    <th>Date</th>
                    <th>Amount</th>
                    <th className=" ">Status</th>
                    <th className="">Reciept</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>01</th>

                    <th>December 19, 2023</th>
                    <th>₦4000</th>
                    <th className="  items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#2ECC71] bg-opacity-10 bg-[#2ECC71] ">Successful</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]   gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>02</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#E74C3C] bg-opacity-10 bg-[#E74C3C] ">Canceled</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>03</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#F39C12] bg-opacity-10 bg-[#F39C12] ">Pending</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>04</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#2ECC71] bg-opacity-10 bg-[#2ECC71] ">Successful</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>05</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#F39C12] bg-opacity-10 bg-[#F39C12] ">Pending</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <AdminFooter />
        </div>
        {/* SMALL SCREENS FROM 100PX TO 1279PX */}
        <div className="100:flex xl:hidden  pl-[115px] pb-[50px] h-fit w-full  pr-[20px]  flex-col justify-between">
          <Header />
          <div className="flex w-full flex-col gap-[10px] justify-between">
            <h1 className="text-[#333] tracking-[0.9px] text-[30px] font-raleway font-medium">Payment History</h1>
            <p className="text-[13px] leading-[30px] tracking-[0.9px] text-[#999] font-montserrat font-normal ">Explore Your Payment History. Gain Insight and Stay in Control.</p>
          </div>
          <div className="w-full  gap-[15px] mt-[40px] flex-col flex items-center justify-between">
            <div className="w-full   gap-[10px] flex-wrap flex items-center justify-between">
              {/* Box 1 */}
              <div className=" w-full md:w-[357px] px-[40px] py-[19px] h-[188px] justify-center items-center flex flex-col gap-[20px] rounded-[20px] bg-white ">
                <h3 className="text-[16px] text-[#333] tracking-[0.9px] font-raleway font-medium">Total Payments</h3>
                <div className=" w-[60px] h-[60px] flex justify-center items-center rounded-[10px] bg-[#2ECC71] bg-opacity-5 ">
                  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M33.86 3.33325C32.0043 3.33325 30.5 7.8104 30.5 13.3333H33.86C35.4793 13.3333 36.289 13.3333 36.7902 12.7742C37.2915 12.2151 37.2042 11.4788 37.0297 10.0063C36.569 6.11897 35.3238 3.33325 33.86 3.33325Z"
                      stroke="#2ECC71"
                      strokeWidth="2"
                    />
                    <path
                      d="M30.4999 13.4237V31.0763C30.4999 33.5958 30.4999 34.8554 29.7299 35.3513C28.4718 36.1618 26.5268 34.4623 25.5484 33.8454C24.7401 33.3356 24.3361 33.0808 23.8874 33.0661C23.4028 33.0501 22.9914 33.2946 22.1181 33.8454L18.9333 35.8539C18.0741 36.3956 17.6446 36.6666 17.1666 36.6666C16.6886 36.6666 16.259 36.3956 15.3999 35.8539L12.2151 33.8454C11.4068 33.3356 11.0027 33.0808 10.5541 33.0661C10.0695 33.0501 9.65814 33.2946 8.7847 33.8454C7.8065 34.4623 5.86137 36.1618 4.60317 35.3513C3.83325 34.8554 3.83325 33.5958 3.83325 31.0763V13.4237C3.83325 8.667 3.83325 6.28867 5.29772 4.81097C6.76219 3.33325 9.1192 3.33325 13.8333 3.33325H33.8333"
                      stroke="#2ECC71"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M10.5 10H23.8333" stroke="#2ECC71" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M13.8333 16.6667H10.5" stroke="#2ECC71" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M26.612 22.778H25.38V23.576H26.612V24.444H25.38V28H23.63L19.164 21.35V28H17.344V24.444H16.126V23.576H17.344V22.778H16.126V21.91H17.344V18.2H19.094L23.588 24.892V18.2H25.38V21.91H26.612V22.778Z" fill="#2ECC71" />
                  </svg>
                </div>
                <h2 className=" tracking-[0.9px] text-[24px] text-[#333] font-montserrat font-medium ">400,000</h2>
              </div>
              {/* Box 2 */}
              <div className=" w-full md:w-[357px] px-[40px] py-[19px] h-[188px]  justify-center items-center flex flex-col gap-[20px] rounded-[20px] bg-white ">
                <h3 className="text-[16px] tracking-[0.9px] text-[#333] font-raleway font-medium">Total Amount Paid</h3>
                <div className=" w-[60px] h-[60px] flex justify-center items-center rounded-[10px] bg-[#2ECC71] bg-opacity-5 ">
                  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M33.86 3.33325C32.0043 3.33325 30.5 7.8104 30.5 13.3333H33.86C35.4793 13.3333 36.289 13.3333 36.7902 12.7742C37.2915 12.2151 37.2042 11.4788 37.0297 10.0063C36.569 6.11897 35.3238 3.33325 33.86 3.33325Z"
                      stroke="#2ECC71"
                      strokeWidth="2"
                    />
                    <path
                      d="M30.4999 13.4237V31.0763C30.4999 33.5958 30.4999 34.8554 29.7299 35.3513C28.4718 36.1618 26.5268 34.4623 25.5484 33.8454C24.7401 33.3356 24.3361 33.0808 23.8874 33.0661C23.4028 33.0501 22.9914 33.2946 22.1181 33.8454L18.9333 35.8539C18.0741 36.3956 17.6446 36.6666 17.1666 36.6666C16.6886 36.6666 16.259 36.3956 15.3999 35.8539L12.2151 33.8454C11.4068 33.3356 11.0027 33.0808 10.5541 33.0661C10.0695 33.0501 9.65814 33.2946 8.7847 33.8454C7.8065 34.4623 5.86137 36.1618 4.60317 35.3513C3.83325 34.8554 3.83325 33.5958 3.83325 31.0763V13.4237C3.83325 8.667 3.83325 6.28867 5.29772 4.81097C6.76219 3.33325 9.1192 3.33325 13.8333 3.33325H33.8333"
                      stroke="#2ECC71"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M10.5 10H23.8333" stroke="#2ECC71" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M13.8333 16.6667H10.5" stroke="#2ECC71" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M26.612 22.778H25.38V23.576H26.612V24.444H25.38V28H23.63L19.164 21.35V28H17.344V24.444H16.126V23.576H17.344V22.778H16.126V21.91H17.344V18.2H19.094L23.588 24.892V18.2H25.38V21.91H26.612V22.778Z" fill="#2ECC71" />
                  </svg>
                </div>
                <h2 className=" tracking-[0.9px] text-[24px] text-[#333] font-montserrat font-medium ">350,000</h2>
              </div>
              {/* Box 3 */}
              <div className=" w-full md:w-[357px] px-[30px] text-center py-[19px] h-[188px]  justify-center items-center flex flex-col gap-[20px] rounded-[20px] bg-white ">
                <h3 className="text-[16px] whitespace-nowrap text-[#333] tracking-[0.9px] font-raleway font-medium">Average Payment Amount</h3>
                <div className=" w-[60px] h-[60px] flex justify-center items-center rounded-[10px] bg-[#2ECC71] bg-opacity-5 ">
                  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M33.86 3.33325C32.0043 3.33325 30.5 7.8104 30.5 13.3333H33.86C35.4793 13.3333 36.289 13.3333 36.7902 12.7742C37.2915 12.2151 37.2042 11.4788 37.0297 10.0063C36.569 6.11897 35.3238 3.33325 33.86 3.33325Z"
                      stroke="#2ECC71"
                      strokeWidth="2"
                    />
                    <path
                      d="M30.4999 13.4237V31.0763C30.4999 33.5958 30.4999 34.8554 29.7299 35.3513C28.4718 36.1618 26.5268 34.4623 25.5484 33.8454C24.7401 33.3356 24.3361 33.0808 23.8874 33.0661C23.4028 33.0501 22.9914 33.2946 22.1181 33.8454L18.9333 35.8539C18.0741 36.3956 17.6446 36.6666 17.1666 36.6666C16.6886 36.6666 16.259 36.3956 15.3999 35.8539L12.2151 33.8454C11.4068 33.3356 11.0027 33.0808 10.5541 33.0661C10.0695 33.0501 9.65814 33.2946 8.7847 33.8454C7.8065 34.4623 5.86137 36.1618 4.60317 35.3513C3.83325 34.8554 3.83325 33.5958 3.83325 31.0763V13.4237C3.83325 8.667 3.83325 6.28867 5.29772 4.81097C6.76219 3.33325 9.1192 3.33325 13.8333 3.33325H33.8333"
                      stroke="#2ECC71"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M10.5 10H23.8333" stroke="#2ECC71" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M13.8333 16.6667H10.5" stroke="#2ECC71" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M26.612 22.778H25.38V23.576H26.612V24.444H25.38V28H23.63L19.164 21.35V28H17.344V24.444H16.126V23.576H17.344V22.778H16.126V21.91H17.344V18.2H19.094L23.588 24.892V18.2H25.38V21.91H26.612V22.778Z" fill="#2ECC71" />
                  </svg>
                </div>
                <h2 className=" tracking-[0.9px] text-[24px] text-[#333] font-montserrat font-medium ">120,000</h2>
              </div>
            </div>
            {/* Tables start here */}
            <div className=" w-full p-[20px] overflow-x-scroll h-fit flex flex-col gap-[15px] rounded-[20px] bg-white ">
              <div className="flex justify-between w-full">
                <h4 className=" font-raleway font-semibold text-[16px] text-[#333] tracking-[0.9px]  ">Payment History</h4>
                <div>
                  <select className="w-[73px] text-[12px] tracking-[0.9px] font-montserrat font-normal text-[#999] outline-none rounded-[20px] pl-[10px] py-[5px] bg-[#F5F5F5] h-[25px]" name="calendar" id="calendar">
                    <option className=" border-b-[0.7px]" value="Year">
                      Year
                    </option>
                    <option value="Month">Month</option>
                    <option value="Week">Week</option>
                  </select>
                </div>
              </div>

              <table className=" w-[1061px] ">
                <thead>
                  <tr className="  text-[14px] text-[#999] tracking-[0.9px] font-medium font-montserrat w-full h-[57px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>ID</th>

                    <th>Date</th>
                    <th>Amount</th>
                    <th className=" ">Status</th>
                    <th className="">Reciept</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>01</th>

                    <th>December 19, 2023</th>
                    <th>₦4000</th>
                    <th className="  items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#2ECC71] bg-opacity-10 bg-[#2ECC71] ">Successful</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]   gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>02</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#E74C3C] bg-opacity-10 bg-[#E74C3C] ">Canceled</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>03</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#F39C12] bg-opacity-10 bg-[#F39C12] ">Pending</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>04</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#2ECC71] bg-opacity-10 bg-[#2ECC71] ">Successful</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>05</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#F39C12] bg-opacity-10 bg-[#F39C12] ">Pending</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </>
  );
}

export default PaymentHistory;
