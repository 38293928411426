import { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../images/KOLET LOGO.png";

const SideBarUser = ({ children }) => {
  const [hamburger, setHamburger] = useState(false);
  const [logout, setLogout] = useState(false);
  const location = useLocation();
  const sideMenu = [
    {
      path: "/dashboarduser",
      title: "Dashboard",
      name: "Dashboard",
      icon: (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.33325 4.65409C1.33325 3.39701 1.33325 2.76847 1.72378 2.37795C2.1143 1.98743 2.74284 1.98743 3.99992 1.98743C5.257 1.98743 5.88554 1.98743 6.27606 2.37795C6.66659 2.76847 6.66658 3.39701 6.66658 4.65409V5.98743C6.66658 7.24451 6.66659 7.87303 6.27606 8.26356C5.88554 8.65409 5.257 8.65409 3.99992 8.65409C2.74284 8.65409 2.1143 8.65409 1.72378 8.26356C1.33325 7.87303 1.33325 7.24451 1.33325 5.98743V4.65409Z"
            stroke="currentColor"
            strokeWidth="1.5"
          />
          <path
            d="M1.33325 13.3207C1.33325 12.6994 1.33325 12.3888 1.43475 12.1437C1.57007 11.8171 1.82964 11.5575 2.15634 11.4221C2.40137 11.3207 2.712 11.3207 3.33325 11.3207H4.66659C5.28784 11.3207 5.59847 11.3207 5.8435 11.4221C6.1702 11.5575 6.42977 11.8171 6.56509 12.1437C6.66659 12.3888 6.66658 12.6994 6.66658 13.3207C6.66658 13.9419 6.66659 14.2525 6.56509 14.4976C6.42977 14.8243 6.1702 15.0839 5.8435 15.2192C5.59847 15.3207 5.28784 15.3207 4.66659 15.3207H3.33325C2.712 15.3207 2.40137 15.3207 2.15634 15.2192C1.82964 15.0839 1.57007 14.8243 1.43475 14.4976C1.33325 14.2525 1.33325 13.9419 1.33325 13.3207Z"
            stroke="currentColor"
            strokeWidth="1.5"
          />
          <path
            d="M9.33325 11.3207C9.33325 10.0637 9.33325 9.43512 9.72379 9.04459C10.1143 8.65405 10.7429 8.65405 11.9999 8.65405C13.257 8.65405 13.8855 8.65405 14.2761 9.04459C14.6666 9.43512 14.6666 10.0637 14.6666 11.3207V12.6541C14.6666 13.9111 14.6666 14.5397 14.2761 14.9302C13.8855 15.3207 13.257 15.3207 11.9999 15.3207C10.7429 15.3207 10.1143 15.3207 9.72379 14.9302C9.33325 14.5397 9.33325 13.9111 9.33325 12.6541V11.3207Z"
            stroke="currentColor"
            strokeWidth="1.5"
          />
          <path
            d="M9.33325 3.98743C9.33325 3.36617 9.33325 3.05555 9.43472 2.81051C9.57005 2.48381 9.82965 2.22425 10.1563 2.08892C10.4014 1.98743 10.712 1.98743 11.3333 1.98743H12.6666C13.2879 1.98743 13.5985 1.98743 13.8435 2.08892C14.1702 2.22425 14.4298 2.48381 14.5651 2.81051C14.6666 3.05555 14.6666 3.36617 14.6666 3.98743C14.6666 4.60868 14.6666 4.91931 14.5651 5.16434C14.4298 5.49104 14.1702 5.75061 13.8435 5.88593C13.5985 5.98743 13.2879 5.98743 12.6666 5.98743H11.3333C10.712 5.98743 10.4014 5.98743 10.1563 5.88593C9.82965 5.75061 9.57005 5.49104 9.43472 5.16434C9.33325 4.91931 9.33325 4.60868 9.33325 3.98743Z"
            stroke="currentColor"
            strokeWidth="1.5"
          />
        </svg>
      ),
    },
    {
      path: "/transactionhistoryuser",
      title: "Transaction",
      name: "Transaction",
      icon: (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.3334 11.9874H2.66675" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M11.3333 9.98743C11.3333 9.98743 13.3333 11.4604 13.3333 11.9874C13.3333 12.5145 11.3333 13.9874 11.3333 13.9874" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M3.33325 5.32068H13.3333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4.66673 3.32068C4.66673 3.32068 2.66675 4.79366 2.66675 5.32069C2.66674 5.84773 4.66675 7.32068 4.66675 7.32068" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      ),
    },
    {
      path: "/paymenthistory",
      title: "Payment History",
      name: "Payment History",
      icon: (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.344 1.98743C12.6017 1.98743 12 3.77829 12 5.98743H13.344C13.9917 5.98743 14.3156 5.98743 14.5161 5.76379C14.7166 5.54015 14.6817 5.24565 14.6119 4.65663C14.4276 3.10171 13.9295 1.98743 13.344 1.98743Z" stroke="currentColor" />
          <path
            d="M11.9999 6.0236V13.0846C11.9999 14.0924 11.9999 14.5963 11.6919 14.7946C11.1887 15.1188 10.4107 14.439 10.0193 14.1923C9.69599 13.9884 9.53439 13.8864 9.35492 13.8806C9.16105 13.8742 8.99652 13.972 8.64719 14.1923L7.37325 14.9957C7.02959 15.2124 6.85779 15.3208 6.66659 15.3208C6.47539 15.3208 6.30356 15.2124 5.95992 14.9957L4.68601 14.1923C4.36269 13.9884 4.20103 13.8864 4.02161 13.8806C3.82773 13.8742 3.66321 13.972 3.31383 14.1923C2.92255 14.439 2.1445 15.1188 1.64122 14.7946C1.33325 14.5963 1.33325 14.0924 1.33325 13.0846V6.0236C1.33325 4.12093 1.33325 3.16959 1.91904 2.57851C2.50483 1.98743 3.44763 1.98743 5.33325 1.98743H13.3333"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M4 4.65405H9.33333" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5.33333 7.32068H4" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10.79 10.135H10.35V10.42H10.79V10.73H10.35V12H9.725L8.13 9.625V12H7.48V10.73H7.045V10.42H7.48V10.135H7.045V9.825H7.48V8.5H8.105L9.71 10.89V8.5H10.35V9.825H10.79V10.135Z" fill="currentColor" />
        </svg>
      ),
    },
    {
      path: "/reportsuser",
      title: "Reports",
      name: "Reports",
      icon: (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 14.6541H6.66667C4.46678 14.6541 3.36683 14.6541 2.68342 13.9707C2 13.2872 2 12.1873 2 9.98739V2.65405" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M4.66675 3.32068H5.33341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M4.66675 5.32068H7.33341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path
            d="M3.33325 13.9873C4.04721 12.6893 5.01511 9.33328 6.87079 9.33328C8.15332 9.33328 8.48545 10.9685 9.74232 10.9685C11.9047 10.9685 11.5913 7.32068 13.9999 7.32068"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      path: "/profileuser",
      title: "Profile",
      name: "Profile",
      icon: (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.38505 10.9752C3.44187 11.5368 0.968909 12.6835 2.47511 14.1185C3.21088 14.8194 4.03033 15.3208 5.06058 15.3208H10.9394C11.9697 15.3208 12.7891 14.8194 13.5249 14.1185C15.0311 12.6835 12.5581 11.5368 11.6149 10.9752C9.4032 9.65818 6.5968 9.65818 4.38505 10.9752Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M11 4.98743C11 6.64428 9.65687 7.98743 8 7.98743C6.34315 7.98743 5 6.64428 5 4.98743C5 3.33057 6.34315 1.98743 8 1.98743C9.65687 1.98743 11 3.33057 11 4.98743Z" stroke="currentColor" strokeWidth="1.5" />
        </svg>
      ),
    },
  ];

  return (
    <>
      {/* BIG SCREENS FROM 1280PX */}
      <div className=" 100:hidden  ] xl:flex w-[250px] py-[42px] gap-[100px] flex-col flex items-center fixed h-full bg-[#3498DB] bg-opacity-10 rounded-r-[20px]">
        <Link onClick={() => window.scrollTo(0, 0)} to="*">
          <img src={logo} alt="Kolet" />
        </Link>
        <div className=" flex flex-col w-[150px] overflow-y-scroll justify-between items-start gap-[150px]">
          <div className=" gap-[40px] w-[170px] items-start justify-between flex flex-col ">
            {sideMenu.map((item, id) => (
              <NavLink
                onClick={() => window.scrollTo(0, 0)}
                title={item.title}
                key={id}
                className={`flex justify-between items-center gap-[10px] hover:text-[#2ECC71] text-[16px] font-raleway font-semibold tracking-[0.9px] ${location.pathname === item.path ? "text-[#3498DB]" : "text-[#333]"}  `}
                to={item.path}
              >
                {item.icon}
                {item.name}
              </NavLink>
            ))}
          </div>
          <Link onClick={() => setLogout(true)} to="">
            <div title="Logout" className=" hover:text-[#2ECC71] flex text-[#333] justify-between text-[16px] font-raleway font-semibold tracking-[0.9px] items-center gap-[10px]">
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.33325 2.65405L6.89152 2.80995C5.17237 3.41672 4.31277 3.72011 3.82301 4.41233C3.33325 5.10456 3.33325 6.01612 3.33325 7.83925V9.46885C3.33325 11.292 3.33325 12.2035 3.82301 12.8958C4.31277 13.588 5.17237 13.8914 6.89152 14.4982L7.33325 14.6541"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M13.9999 8.65403H7.33325M13.9999 8.65403C13.9999 8.18723 12.6704 7.31505 12.3333 6.98737M13.9999 8.65403C13.9999 9.12083 12.6704 9.99303 12.3333 10.3207"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <h3>Logout</h3>
            </div>
          </Link>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1279PX */}
      {hamburger ? (
        <>
          <div className=" z-[20]   top-0 left-0 flex justify-between bg-black bg-opacity-65 fixed  w-full h-screen ">
            <div className="flex w-[250px] !bg-[#ECF7FF] scroll-smooth relative z-30  py-[42px] gap-[100px] flex-col items-center  h-screen  rounded-r-[20px]">
              <Link to="*">
                <img src={logo} alt="Kolet" />
              </Link>
              <div className="  flex flex-col w-[170px] gap-[150px] justify-between items-start ">
                <div className=" gap-[40px] w-[170px] items-start justify-between flex flex-col ">
                  {sideMenu.map((item, id) => (
                    <NavLink
                      onClick={() => window.scrollTo(0, 0)}
                      title={item.title}
                      key={id}
                      className={`flex justify-between items-center gap-[10px] hover:text-[#2ECC71] text-[16px] font-raleway font-semibold tracking-[0.9px] ${location.pathname === item.path ? "text-[#3498DB]" : "text-[#333]"}  `}
                      to={item.path}
                    >
                      {item.icon}
                      {item.name}
                    </NavLink>
                  ))}
                </div>
                <Link
                  onClick={() => {
                    setLogout(true);
                    setHamburger(false);
                  }}
                  to=""
                >
                  <div title="Logout" className=" hover:text-[#2ECC71] flex text-[#333] justify-between text-[16px] font-raleway font-semibold tracking-[0.9px] items-center gap-[10px]">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.33325 2.65405L6.89152 2.80995C5.17237 3.41672 4.31277 3.72011 3.82301 4.41233C3.33325 5.10456 3.33325 6.01612 3.33325 7.83925V9.46885C3.33325 11.292 3.33325 12.2035 3.82301 12.8958C4.31277 13.588 5.17237 13.8914 6.89152 14.4982L7.33325 14.6541"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M13.9999 8.65403H7.33325M13.9999 8.65403C13.9999 8.18723 12.6704 7.31505 12.3333 6.98737M13.9999 8.65403C13.9999 9.12083 12.6704 9.99303 12.3333 10.3207"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <h3>Logout</h3>
                  </div>
                </Link>
              </div>
            </div>

            <div onClick={() => setHamburger(false)} className=" cursor-pointer  flex justify-center items-center w-[60px] absolute right-[20px] top-[35px] h-[60px] bg-[#494B48] rounded-[80px]">
              <svg className=" " width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 5L5 19M5 5L19 19" stroke="#F5F5F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
        </>
      ) : (
        <div
          onClick={() => {
            setHamburger(true);
          }}
          className=" 100:flex xl:hidden w-[100px] py-[42px] gap-[100px] flex-col  items-center fixed h-screen bg-[#3498DB] bg-opacity-10 rounded-r-[20px]"
        >
          <Link onClick={() => window.scrollTo(0, 0)} to="*">
            <img src={logo} alt="Kolet" />
          </Link>

          <div className=" overflow-y-scroll flex flex-col w-full justify-between gap-[150px] items-center ">
            <div className=" gap-[40px] w-full item-center justify-center flex flex-col ">
              {sideMenu.map((item, id) => (
                <NavLink
                  title={item.title}
                  key={id}
                  className={`flex w-full justify-center items-center gap-[10px] hover:text-[#2ECC71] text-[16px] font-raleway font-semibold tracking-[0.9px] ${location.pathname === item.path ? "text-[#3498DB]" : "text-[#333]"}  `}
                  to={item.path}
                >
                  {item.icon}
                </NavLink>
              ))}
            </div>
            <Link
              onClick={() => {
                setLogout(true);
                setHamburger(false);
              }}
              to=""
            >
              <div title="Logout" className=" hover:text-[#2ECC71] flex text-[#333] justify-between text-[16px] font-raleway font-semibold tracking-[0.9px] items-center gap-[10px]">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.33325 2.65405L6.89152 2.80995C5.17237 3.41672 4.31277 3.72011 3.82301 4.41233C3.33325 5.10456 3.33325 6.01612 3.33325 7.83925V9.46885C3.33325 11.292 3.33325 12.2035 3.82301 12.8958C4.31277 13.588 5.17237 13.8914 6.89152 14.4982L7.33325 14.6541"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M13.9999 8.65403H7.33325M13.9999 8.65403C13.9999 8.18723 12.6704 7.31505 12.3333 6.98737M13.9999 8.65403C13.9999 9.12083 12.6704 9.99303 12.3333 10.3207"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </Link>
          </div>
        </div>
      )}

      <main>{children}</main>
      {logout && (
        <div className=" bg-black flex  fixed z-[50] justify-center items-center bg-opacity-55 w-full h-full left-0 top-0  ">
          <div onClick={() => setLogout(false)} className=" cursor-pointer  flex justify-center items-center w-[60px] absolute right-[40px] top-[40px] h-[60px] bg-[#494B48] rounded-[80px]">
            <svg className=" " width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 5L5 19M5 5L19 19" stroke="#F5F5F5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <div className=" 100:w-[80%] text-center phone:w-[350px] flex flex-col items-center  justify-between  gap-[40px] px-[30px] py-[50px] h-fit rounded-[20px] bg-[#f5f5f5]">
            <h3 className=" text-[16px] text-[#333] font-semibold tracking-[0.9px] ">Are you sure you want to logout</h3>
            <div className=" flex flex-col items-center gap-[10px] w-full ">
              <Link to="/login">
                <button className="w-[143px] text-center h-[38px] rounded-[4px]  text-[#fff] tracking-[0.9px] bg-[#E74C3C] text-[18px] font-semibold font-raleway ">Yes</button>
              </Link>
              <button onClick={() => setLogout(false)} className="w-[143px] text-center h-[38px] rounded-[4px] border-[#3498DB] text-[#333] border-solid border-[1.5px] tracking-[0.9px] text-[18px] font-semibold font-raleway ">
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SideBarUser;
