import comingSoon from "../images/comingSoon.png";

function ComingSoon() {
  return (
    <>
      {/* BIG SCREENS FROM 1280PX */}
      <div className=" 100:hidden py-[33px] xl:flex w-full h-fit bg-[#3498DB] justify-center items-center mt-[60px]">
        <div className="w-[90%] gap-[70px] flex flex-col justify-between items-center">
          <div className="text-center flex w-[550px] flex-col gap-[10px] justify-between items-center">
            <h2 className=" text-[#f5f5f5] text-center leading-[70px] text-[48px] font-raleway tracking-[0.9px] font-semibold">
              Unveiling the Future of Finance with <span className="text-[#2ECC71]">KOLeT</span>
            </h2>
            <p className="text-[22px] w-[500px] text-[#ECF0F1] font-montserrat font-normal tracking-[0.9px] leading-[35px] ">Exciting Features Coming Soon - Elevate Your Payment Experience</p>
          </div>
          <div className="w-[913px] px-[60px] h-[430px] flex gap-[60px] justify-between pt-[67px] items-center rounded-[20px] bg-[#f5f5f5]">
            <div className="grid w-[300px] text-left gap-[10px]">
              <h3 className=" leading-[35px] text-[#333] font-semibold font-raleway text-[20px] tracking-[0.9px] ">Next-Level Convenience Awaits with KOLet</h3>
              <p className="text-[13px] leading-[25px] text-[#999] trackinng-[0.9px] font-montserrat font-normal ">
                Explore enhanced convenience as KOLet introduces upcoming features for purchasing airtime, data, cable TV, and electricity, all within the KOLet app. Elevate your financial experience with seamless transactions at your fingertips!
              </p>
            </div>
            <img src={comingSoon} alt="coming soon" />
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1279PX */}
      <div className=" 100:flex  xl:hidden mt-[60px] py-[33px] h-fit  bg-[#3498DB] w-full justify-center items-center">
        <div className=" w-[90%] gap-[60px] flex justify-between items-center flex-col">
          <div className="text-center phone:w-[400px] flex flex-col gap-[10px] justify-between items-center">
            <h2 className=" text-[#f5f5f5] sm:w-[450px]  text-center leading-[45px] text-[28px] font-raleway tracking-[0.9px] font-semibold">
              Unveiling the Future of Finance with <span className="text-[#2ECC71]">KOLet</span>
            </h2>
            <p className="text-[18px]  text-[#ECF0F1] font-montserrat font-normal tracking-[0.9px] leading-[35px] ">Exciting Features Coming Soon - Elevate Your Payment Experience</p>
          </div>
          <div className="w-[90%] phone:w-[346px] px-[30px] h-[593px] flex flex-col gap-[60px] justify-between overflow-hidden pt-[55px] items-center rounded-[20px] bg-[#f5f5f5]">
            <div className="grid  phone:w-[300px]  text-left gap-[10px]">
              <h3 className=" leading-[35px] text-[#333] font-semibold font-raleway text-[18px] tracking-[0.9px] ">Next-Level Convenience Awaits with KOLet</h3>
              <p className="text-[12px] leading-[25px] text-[#999] trackinng-[0.9px] font-montserrat font-normal ">
                Explore enhanced convenience as KOLet introduces upcoming features for purchasing airtime, data, cable TV, and electricity, all within the KOLet app. Elevate your financial experience with seamless transactions at your fingertips!
              </p>
            </div>
            <img src={comingSoon} alt="coming soon" />
          </div>
        </div>
      </div>
    </>
  );
}

export default ComingSoon;
