import { useState } from "react";

function Contact() {
  const [border, setBorder] = useState(false);
  const [border2, setBorder2] = useState(false);
  const [border3, setBorder3] = useState(false);

  return (
    <>
      {/* BIG SCREENS FROM 1280PX */}
      <div className=" 100:hidden  h-fit py-[60px]  mt-[60px] xl:flex w-full justify-center items-center ">
        <div className="w-[1200px] px-[70px] py-[60px]  rounded-[20px] flex justify-between items-center gap-[60px] h-[586px] bg-opacity-10">
          <div className="flex flex-col  justify-between items-start gap-[10px] ">
            <h2 className=" text-[#333]   leading-[70px] text-[48px] font-raleway tracking-[0.9px] font-semibold">Contact Us </h2>
            <p className="text-[22px] w-[502px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[38px] ">
              Have a question or need assistance? Use the form to send us a message directly.We're here to help! Reach out with any questions or concerns. Your satisfaction is our priority.
            </p>
          </div>
          <div className=" w-[542px] h-fit  bg-[#3498DB] flex flex-col justify-between gap-[60px] items-center bg-opacity-10 px-[71px] py-[43px] rounded-[20px] ">
            <h3 className=" text-[#333]   leading-[70px] text-[24px] font-raleway tracking-[0.9px] font-semibold">Contact Form</h3>
            <form className="gap-[20px] flex flex-col ">
              <div
                onClick={() => {
                  setBorder(true);
                  setBorder2(false);
                  setBorder3(false);
                }}
                className={`flex justify-between h-[43px] px-[16px] items-center w-[400px] rounded-[4px] bg-[#fff] border-solid border-[${border ? "1px" : "0.7px"}] border-[${border ? "#3498DB" : "#BDC3C7"}]  `}
              >
                <input className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="text" placeholder="Full Name" />
                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3.46113 8.20637C2.76775 8.61923 0.949759 9.46225 2.05704 10.5172C2.59794 11.0325 3.20036 11.401 3.95775 11.401H8.27955C9.03695 11.401 9.63938 11.0325 10.1803 10.5172C11.2875 9.46225 9.46956 8.61923 8.77617 8.20637C7.15022 7.23818 5.08709 7.23818 3.46113 8.20637Z"
                    stroke="#999999"
                    strokeWidth="0.735148"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.32422 3.80457C8.32422 5.0226 7.33682 6.01001 6.11878 6.01001C4.90074 6.01001 3.91333 5.0226 3.91333 3.80457C3.91333 2.58653 4.90074 1.59912 6.11878 1.59912C7.33682 1.59912 8.32422 2.58653 8.32422 3.80457Z"
                    stroke="#999999"
                    strokeWidth="0.735148"
                  />
                </svg>
              </div>
              <div
                onClick={() => {
                  setBorder2(true);
                  setBorder(false);
                  setBorder3(false);
                }}
                className={`flex justify-between h-[43px] px-[16px] items-center w-[400px] rounded-[4px] bg-[#fff] border-solid border-[${border2 ? "1px" : "0.7px"}] border-[${border2 ? "#3498DB" : "#BDC3C7"}]  `}
              >
                <input className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="email" placeholder="Email Address" />
                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.21777 3.55957L4.60584 5.47927C5.85489 6.18699 6.38263 6.18699 7.6317 5.47927L11.0198 3.55957" stroke="#999999" strokeWidth="0.735148" strokeLinejoin="round" />
                  <path
                    d="M1.2255 7.22326C1.25754 8.72571 1.27356 9.47688 1.82793 10.0334C2.38229 10.5898 3.15383 10.6092 4.6969 10.648C5.64793 10.6719 6.5896 10.6719 7.54064 10.648C9.08372 10.6092 9.85523 10.5898 10.4096 10.0334C10.964 9.47688 10.98 8.72571 11.012 7.22326C11.0224 6.74017 11.0224 6.25997 11.012 5.77688C10.98 4.27446 10.964 3.52325 10.4096 2.96678C9.85523 2.41031 9.08372 2.39092 7.54064 2.35215C6.5896 2.32825 5.64793 2.32825 4.6969 2.35215C3.15383 2.39091 2.38229 2.4103 1.82792 2.96678C1.27356 3.52325 1.25754 4.27446 1.2255 5.77688C1.2152 6.25997 1.2152 6.74017 1.2255 7.22326Z"
                    stroke="#999999"
                    strokeWidth="0.735148"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div
                onClick={() => {
                  setBorder3(true);
                  setBorder2(false);
                  setBorder(false);
                }}
                className={`flex justify-between h-[43px] px-[16px] items-center w-[400px] rounded-[4px] bg-[#fff] border-solid border-[${border3 ? "1px" : "0.7px"}] border-[${border3 ? "#3498DB" : "#BDC3C7"}]  `}
              >
                <input className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="tel" placeholder="Phone Number" />
                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.68799 5.02981C2.68799 3.41257 2.68799 2.60395 3.1904 2.10154C3.69281 1.59912 4.50144 1.59912 6.11868 1.59912C7.73591 1.59912 8.54452 1.59912 9.04697 2.10154C9.54937 2.60395 9.54937 3.41257 9.54937 5.02981V7.97041C9.54937 9.58764 9.54937 10.3963 9.04697 10.8987C8.54452 11.4011 7.73591 11.4011 6.11868 11.4011C4.50144 11.4011 3.69281 11.4011 3.1904 10.8987C2.68799 10.3963 2.68799 9.58764 2.68799 7.97041V5.02981Z"
                    stroke="#999999"
                    strokeWidth="0.735148"
                    strokeLinecap="round"
                  />
                  <path d="M5.62866 9.93066H6.60886" stroke="#999999" strokeWidth="0.735148" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M4.64844 1.59912L4.69206 1.86084C4.78659 2.42802 4.83385 2.71161 5.02836 2.88418C5.23126 3.06419 5.5189 3.06942 6.11873 3.06942C6.71857 3.06942 7.00621 3.06419 7.20911 2.88418C7.40363 2.71161 7.45087 2.42802 7.54541 1.86084L7.58903 1.59912"
                    stroke="#999999"
                    strokeWidth="0.735148"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <textarea
                placeholder="Message........"
                className="font-montserrat tracking-[0.9px] font-normal text-[14px] text-[#999] outline-[#3498DB]  h-[86px] px-[16px] py-[10px] items-center w-[400px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] "
              ></textarea>
            </form>
            <button className="w-[216px] h-[44px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Get in Touch</button>
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1279PX */}
      <div className=" 100:flex  h-fit py-[60px]  mt-[60px] xl:hidden w-full justify-center items-center ">
        <div className="  gap-[60px] relative flex w-[90%] phone:w-[450px]  flex-col justify-center items-center">
          <div className="flex flex-col  justify-between items-start gap-[10px] ">
            <h2 className=" text-[#333]    text-[32px] font-raleway tracking-[0.9px] font-semibold">Contact Us </h2>
            <p className="text-[18px]  text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[38px] ">
              Have a question or need assistance? Use the form to send us a message directly.We're here to help! Reach out with any questions or concerns. Your satisfaction is our priority.
            </p>
          </div>
          <div className=" w-full h-fit  bg-[#3498DB] flex flex-col justify-between gap-[60px] items-center bg-opacity-10 px-[40px] py-[43px] rounded-[20px] ">
            <h3 className=" text-[#333]   leading-[70px] text-[24px] font-raleway tracking-[0.9px] font-semibold">Contact Form</h3>
            <form className="gap-[20px] w-full flex flex-col ">
              <div
                onClick={() => {
                  setBorder(true);
                  setBorder2(false);
                  setBorder3(false);
                }}
                className={`flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[${border ? "1px" : "0.7px"}] border-[${border ? "#3498DB" : "#BDC3C7"}]  `}
              >
                <input className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="text" placeholder="Full Name" />
                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3.46113 8.20637C2.76775 8.61923 0.949759 9.46225 2.05704 10.5172C2.59794 11.0325 3.20036 11.401 3.95775 11.401H8.27955C9.03695 11.401 9.63938 11.0325 10.1803 10.5172C11.2875 9.46225 9.46956 8.61923 8.77617 8.20637C7.15022 7.23818 5.08709 7.23818 3.46113 8.20637Z"
                    stroke="#999999"
                    strokeWidth="0.735148"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.32422 3.80457C8.32422 5.0226 7.33682 6.01001 6.11878 6.01001C4.90074 6.01001 3.91333 5.0226 3.91333 3.80457C3.91333 2.58653 4.90074 1.59912 6.11878 1.59912C7.33682 1.59912 8.32422 2.58653 8.32422 3.80457Z"
                    stroke="#999999"
                    strokeWidth="0.735148"
                  />
                </svg>
              </div>
              <div
                onClick={() => {
                  setBorder2(true);
                  setBorder(false);
                  setBorder3(false);
                }}
                className={`flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[${border2 ? "1px" : "0.7px"}] border-[${border2 ? "#3498DB" : "#BDC3C7"}]  `}
              >
                {" "}
                <input className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="email" placeholder="Email Address" />
                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.21777 3.55957L4.60584 5.47927C5.85489 6.18699 6.38263 6.18699 7.6317 5.47927L11.0198 3.55957" stroke="#999999" strokeWidth="0.735148" strokeLinejoin="round" />
                  <path
                    d="M1.2255 7.22326C1.25754 8.72571 1.27356 9.47688 1.82793 10.0334C2.38229 10.5898 3.15383 10.6092 4.6969 10.648C5.64793 10.6719 6.5896 10.6719 7.54064 10.648C9.08372 10.6092 9.85523 10.5898 10.4096 10.0334C10.964 9.47688 10.98 8.72571 11.012 7.22326C11.0224 6.74017 11.0224 6.25997 11.012 5.77688C10.98 4.27446 10.964 3.52325 10.4096 2.96678C9.85523 2.41031 9.08372 2.39092 7.54064 2.35215C6.5896 2.32825 5.64793 2.32825 4.6969 2.35215C3.15383 2.39091 2.38229 2.4103 1.82792 2.96678C1.27356 3.52325 1.25754 4.27446 1.2255 5.77688C1.2152 6.25997 1.2152 6.74017 1.2255 7.22326Z"
                    stroke="#999999"
                    strokeWidth="0.735148"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div
                onClick={() => {
                  setBorder3(true);
                  setBorder2(false);
                  setBorder(false);
                }}
                className={`flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[${border3 ? "1px" : "0.7px"}] border-[${border3 ? "#3498DB" : "#BDC3C7"}]  `}
              >
                {" "}
                <input className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="tel" placeholder="Phone Number" />
                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.68799 5.02981C2.68799 3.41257 2.68799 2.60395 3.1904 2.10154C3.69281 1.59912 4.50144 1.59912 6.11868 1.59912C7.73591 1.59912 8.54452 1.59912 9.04697 2.10154C9.54937 2.60395 9.54937 3.41257 9.54937 5.02981V7.97041C9.54937 9.58764 9.54937 10.3963 9.04697 10.8987C8.54452 11.4011 7.73591 11.4011 6.11868 11.4011C4.50144 11.4011 3.69281 11.4011 3.1904 10.8987C2.68799 10.3963 2.68799 9.58764 2.68799 7.97041V5.02981Z"
                    stroke="#999999"
                    strokeWidth="0.735148"
                    strokeLinecap="round"
                  />
                  <path d="M5.62866 9.93066H6.60886" stroke="#999999" strokeWidth="0.735148" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M4.64844 1.59912L4.69206 1.86084C4.78659 2.42802 4.83385 2.71161 5.02836 2.88418C5.23126 3.06419 5.5189 3.06942 6.11873 3.06942C6.71857 3.06942 7.00621 3.06419 7.20911 2.88418C7.40363 2.71161 7.45087 2.42802 7.54541 1.86084L7.58903 1.59912"
                    stroke="#999999"
                    strokeWidth="0.735148"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <textarea
                placeholder="Message........"
                className="font-montserrat tracking-[0.9px] font-normal text-[14px] text-[#999] outline-[#3498DB]  h-[86px] px-[16px] py-[10px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] "
              ></textarea>
            </form>
            <button className="w-[216px] h-[44px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Get in Touch</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
