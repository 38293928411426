import { Header } from "./Header";
import { AdminFooter } from "./AdminFooter";
import SideBarUser from "./SideBarUser";

const TransactionUser = () => {
  return (
    <>
      <div className=" flex  justify-between">
        <SideBarUser />
        {/* BIG SCREENS FROM 1280PX */}
        <div className=" 100:hidden   pl-[290px] pb-[50px] h-fit w-full  pr-[40px] xl:flex flex-col  justify-between">
          <Header />
          <div className="flex w-[533px] flex-col gap-[10px] justify-between">
            <h1 className="text-[#333] tracking-[0.9px] text-[36px] font-raleway font-medium">Transactions</h1>
            <p className="text-[16px] leading-[30px] tracking-[0.9px] text-[#999] font-montserrat font-normal ">Navigate your financial journey effortlessly with a comprehensive view of transactions.</p>
          </div>

          <div className=" flex flex-col justify-center items-center gap-[15px]">
            <div className="w-full  gap-[15px] mt-[40px] flex items-center justify-between">
              {/* Box 1 */}
              <div className=" w-full px-[40px] py-[19px] h-[188px] justify-center items-center flex flex-col gap-[20px] rounded-[20px] bg-white ">
                <h3 className="text-[18px] text-[#333] tracking-[0.9px] font-raleway font-medium">Total Transactions</h3>
                <div className=" w-[60px] h-[60px] flex justify-center items-center rounded-[10px] bg-[#2ECC71] bg-opacity-5 ">
                  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M33.8334 28.3334H7.16675" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M28.8333 23.3334C28.8333 23.3334 33.8333 27.0159 33.8333 28.3334C33.8333 29.651 28.8333 33.3334 28.8333 33.3334" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.83325 11.6666H33.8333" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12.1667 6.66663C12.1667 6.66663 7.16676 10.3491 7.16675 11.6667C7.16673 12.9843 12.1667 16.6666 12.1667 16.6666" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
                <h2 className=" tracking-[0.9px] text-[24px] text-[#333] font-montserrat font-medium ">100,000</h2>
              </div>
              {/* Box 2 */}
              <div className=" w-full px-[40px] py-[19px] h-[188px]  justify-center items-center flex flex-col gap-[20px] rounded-[20px] bg-white ">
                <h3 className="text-[18px] tracking-[0.9px] text-[#333] font-raleway font-medium">Total Amount Spent</h3>
                <div className=" w-[60px] h-[60px] flex justify-center items-center rounded-[10px] bg-[#2ECC71] bg-opacity-5 ">
                  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M33.8334 28.3334H7.16675" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M28.8333 23.3334C28.8333 23.3334 33.8333 27.0159 33.8333 28.3334C33.8333 29.651 28.8333 33.3334 28.8333 33.3334" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.83325 11.6666H33.8333" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12.1667 6.66663C12.1667 6.66663 7.16676 10.3491 7.16675 11.6667C7.16673 12.9843 12.1667 16.6666 12.1667 16.6666" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
                <h2 className=" tracking-[0.9px] text-[24px] text-[#333] font-montserrat font-medium ">300,000</h2>
              </div>
              {/* Box 3 */}
              <div className=" w-full px-[40px] py-[19px] h-[188px]  justify-center items-center flex flex-col gap-[20px] rounded-[20px] bg-white ">
                <h3 className="text-[18px] text-[#333] tracking-[0.9px] font-raleway font-medium">Average Transaction Amount</h3>
                <div className=" w-[60px] h-[60px] flex justify-center items-center rounded-[10px] bg-[#2ECC71] bg-opacity-5 ">
                  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M33.8334 28.3334H7.16675" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M28.8333 23.3334C28.8333 23.3334 33.8333 27.0159 33.8333 28.3334C33.8333 29.651 28.8333 33.3334 28.8333 33.3334" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.83325 11.6666H33.8333" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12.1667 6.66663C12.1667 6.66663 7.16676 10.3491 7.16675 11.6667C7.16673 12.9843 12.1667 16.6666 12.1667 16.6666" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
                <h2 className=" tracking-[0.9px] text-[24px] text-[#333] font-montserrat font-medium ">20,000</h2>
              </div>
            </div>
            {/* Tables start here */}

            <div className=" w-full p-[20px] h-fit flex flex-col gap-[15px] rounded-[20px] bg-white ">
              <div className="flex items-center justify-between pr-[20px] w-full">
                <h4 className=" font-raleway font-semibold text-[18px] text-[#333] tracking-[0.9px]  ">Recent Transactions</h4>
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.375 1.24976L11.8234 2.08809C12.1267 2.65485 12.2783 2.93824 12.1781 3.05961C12.0778 3.18097 11.7476 3.08295 11.0873 2.88692C10.4272 2.69092 9.7263 2.58547 9 2.58547C5.06497 2.58547 1.875 5.6811 1.875 9.49979C1.875 10.7591 2.22197 11.9399 2.8282 12.9569M6.625 17.7498L6.17652 16.9114C5.87331 16.3447 5.7217 16.0612 5.82195 15.9399C5.9222 15.8185 6.25237 15.9166 6.91267 16.1126C7.57283 16.3086 8.2737 16.414 9 16.414C12.935 16.414 16.125 13.3184 16.125 9.49979C16.125 8.24039 15.778 7.05961 15.1718 6.04261"
                    stroke="#3498DB"
                    strokeWidth="1.125"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <table className=" w-full">
                <thead>
                  <tr className="  text-[14px] text-[#999] tracking-[0.9px] font-medium font-montserrat w-full h-[57px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>ID</th>
                    <th className="">Name</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th className=" ">Status</th>
                    <th className="">Reciept</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>01</th>
                    <th className="">Joe Biden</th>
                    <th>December 19, 2023</th>
                    <th>₦4000</th>
                    <th className="  items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#2ECC71] bg-opacity-10 bg-[#2ECC71] ">Successful</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]   gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>02</th>
                    <th className="">Amarachi Nkemdilichi</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#E74C3C] bg-opacity-10 bg-[#E74C3C] ">Canceled</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>03</th>
                    <th className="">Liji Joem</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#F39C12] bg-opacity-10 bg-[#F39C12] ">Pending</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>04</th>
                    <th className="">Liji Joem</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#2ECC71] bg-opacity-10 bg-[#2ECC71] ">Successful</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>05</th>
                    <th className="">Liji Joem</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#F39C12] bg-opacity-10 bg-[#F39C12] ">Pending</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <AdminFooter />
        </div>
        {/* SMALL SCREENS FROM 100PX TO 1279PX */}
        <div className="100:flex xl:hidden  pl-[115px] pb-[50px] h-fit w-full  pr-[20px]  flex-col justify-between">
          <Header />
          <div className="flex w-full flex-col gap-[10px] justify-between">
            <h1 className="text-[#333] tracking-[0.9px] text-[30px] font-raleway font-medium">Transactions</h1>
            <p className="text-[13px] leading-[30px] tracking-[0.9px] text-[#999] font-montserrat font-normal ">Navigate your financial journey effortlessly with a comprehensive view of transactions.</p>
          </div>
          <div className="w-full  gap-[15px] mt-[40px] flex-col flex items-center justify-between">
            <div className="w-full   gap-[10px] flex-wrap flex items-center justify-between">
              {/* Box 1 */}
              <div className=" w-full md:w-[357px] px-[40px] py-[19px] h-[188px] justify-center items-center flex flex-col gap-[20px] rounded-[20px] bg-white ">
                <h3 className="text-[16px] text-[#333] tracking-[0.9px] font-raleway font-medium">Total Transactions</h3>
                <div className=" w-[60px] h-[60px] flex justify-center items-center rounded-[10px] bg-[#2ECC71] bg-opacity-5 ">
                  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M33.8334 28.3334H7.16675" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M28.8333 23.3334C28.8333 23.3334 33.8333 27.0159 33.8333 28.3334C33.8333 29.651 28.8333 33.3334 28.8333 33.3334" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.83325 11.6666H33.8333" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12.1667 6.66663C12.1667 6.66663 7.16676 10.3491 7.16675 11.6667C7.16673 12.9843 12.1667 16.6666 12.1667 16.6666" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
                <h2 className=" tracking-[0.9px] text-[24px] text-[#333] font-montserrat font-medium ">100,000</h2>
              </div>
              {/* Box 2 */}
              <div className=" w-full md:w-[357px] px-[40px] py-[19px] h-[188px]  justify-center items-center flex flex-col gap-[20px] rounded-[20px] bg-white ">
                <h3 className="text-[16px] tracking-[0.9px] text-[#333] font-raleway font-medium">Total Amount Spent</h3>
                <div className=" w-[60px] h-[60px] flex justify-center items-center rounded-[10px] bg-[#2ECC71] bg-opacity-5 ">
                  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M33.8334 28.3334H7.16675" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M28.8333 23.3334C28.8333 23.3334 33.8333 27.0159 33.8333 28.3334C33.8333 29.651 28.8333 33.3334 28.8333 33.3334" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.83325 11.6666H33.8333" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12.1667 6.66663C12.1667 6.66663 7.16676 10.3491 7.16675 11.6667C7.16673 12.9843 12.1667 16.6666 12.1667 16.6666" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
                <h2 className=" tracking-[0.9px] text-[24px] text-[#333] font-montserrat font-medium ">300,000</h2>
              </div>
              {/* Box 3 */}
              <div className=" w-full md:w-[357px] px-[30px] text-center py-[19px] h-[188px]  justify-center items-center flex flex-col gap-[20px] rounded-[20px] bg-white ">
                <h3 className="text-[16px] whitespace-nowrap text-[#333] tracking-[0.9px] font-raleway font-medium">Average Transaction Amount</h3>
                <div className=" w-[60px] h-[60px] flex justify-center items-center rounded-[10px] bg-[#2ECC71] bg-opacity-5 ">
                  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M33.8334 28.3334H7.16675" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M28.8333 23.3334C28.8333 23.3334 33.8333 27.0159 33.8333 28.3334C33.8333 29.651 28.8333 33.3334 28.8333 33.3334" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.83325 11.6666H33.8333" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12.1667 6.66663C12.1667 6.66663 7.16676 10.3491 7.16675 11.6667C7.16673 12.9843 12.1667 16.6666 12.1667 16.6666" stroke="#2ECC71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
                <h2 className=" tracking-[0.9px] text-[24px] text-[#333] font-montserrat font-medium ">20,000</h2>
              </div>
            </div>
            {/* Tables start here */}
            <div className=" w-full p-[20px] overflow-x-scroll h-fit flex flex-col gap-[15px] rounded-[20px] bg-white ">
              <div className="flex items-center justify-between w-full">
                <h4 className=" font-raleway font-semibold text-[18px] text-[#333] tracking-[0.9px]  ">Recent Transactions</h4>
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.375 1.24976L11.8234 2.08809C12.1267 2.65485 12.2783 2.93824 12.1781 3.05961C12.0778 3.18097 11.7476 3.08295 11.0873 2.88692C10.4272 2.69092 9.7263 2.58547 9 2.58547C5.06497 2.58547 1.875 5.6811 1.875 9.49979C1.875 10.7591 2.22197 11.9399 2.8282 12.9569M6.625 17.7498L6.17652 16.9114C5.87331 16.3447 5.7217 16.0612 5.82195 15.9399C5.9222 15.8185 6.25237 15.9166 6.91267 16.1126C7.57283 16.3086 8.2737 16.414 9 16.414C12.935 16.414 16.125 13.3184 16.125 9.49979C16.125 8.24039 15.778 7.05961 15.1718 6.04261"
                    stroke="#3498DB"
                    strokeWidth="1.125"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <table className=" w-[1061px] ">
                <thead>
                  <tr className="  text-[14px] text-[#999] tracking-[0.9px] font-medium font-montserrat w-full h-[57px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>ID</th>
                    <th className="">Name</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th className=" ">Status</th>
                    <th className="">Reciept</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>01</th>
                    <th className="">Joe Biden</th>
                    <th>December 19, 2023</th>
                    <th>₦4000</th>
                    <th className="  items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#2ECC71] bg-opacity-10 bg-[#2ECC71] ">Successful</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]   gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>02</th>
                    <th className="">Amarachi Nkemdilichi</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#E74C3C] bg-opacity-10 bg-[#E74C3C] ">Canceled</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>03</th>
                    <th className="">Liji Joem</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#F39C12] bg-opacity-10 bg-[#F39C12] ">Pending</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>04</th>
                    <th className="">Liji Joem</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#2ECC71] bg-opacity-10 bg-[#2ECC71] ">Successful</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                  <tr className="text-[14px] text-[#33] tracking-[0.9px] font-medium font-montserrat w-full h-[77px] border-b-[0.7px] border-b-[#BDC3C7] ">
                    <th>05</th>
                    <th className="">Liji Joem</th>
                    <th>December 16, 2023</th>
                    <th>₦4000</th>
                    <th className="   items-center pt-[20px] flex justify-center ">
                      {" "}
                      <div className=" text-center w-[120px] h-[37px] flex justify-center items-center rounded-[20px] px-[20px] py-[10] text-[#F39C12] bg-opacity-10 bg-[#F39C12] ">Pending</div>
                    </th>
                    <th className=" pl-[30px] w-[120px]">
                      <div className="text-[#3498DB]  gap-[5px] flex   items-center">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3 14.4843V6.5407C3 4.40019 3 3.32994 3.65901 2.66497C4.31802 2 5.37868 2 7.5 2H10.5C12.6213 2 13.6819 2 14.341 2.66497C15 3.32994 15 4.40019 15 6.5407V14.4843C15 15.6181 15 16.185 14.6535 16.4081C14.0873 16.7728 13.2121 16.0081 12.7718 15.7305C12.4081 15.5011 12.2263 15.3864 12.0244 15.3798C11.8063 15.3726 11.6212 15.4826 11.2282 15.7305L9.795 16.6343C9.40838 16.878 9.2151 17 9 17C8.7849 17 8.59162 16.878 8.205 16.6343L6.77185 15.7305C6.40811 15.5011 6.22624 15.3864 6.0244 15.3798C5.80629 15.3726 5.6212 15.4826 5.22815 15.7305C4.78796 16.0081 3.91265 16.7728 3.34646 16.4081C3 16.185 3 15.6181 3 14.4843Z"
                            stroke="#3498DB"
                            strokeWidth="1.125"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.25 8.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.5 5.75H6" stroke="#3498DB" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        View
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </>
  );
};

export default TransactionUser;
