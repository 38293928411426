import { Link } from "react-router-dom";
import Header from "./Header";
import homepage from "../images/headerImg.png";
import Features from "./Features";
import HowItWorks from "./HowItWorks";
import ComingSoon from "./ComingSoon";
import Testimonial from "./Testimonial";
import Download from "./Download";
import Security from "./Security";
import Contact from "./Contact";
import NewsLetter from "./NewsLetter";
import Footer from "./Footer";
import { useRef } from "react";

const HomePage = () => {
  const home = useRef(null);
  const features = useRef(null);
  const howItWorks = useRef(null);
  const testimonial = useRef(null);
  const contact = useRef(null);
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header scrollToSection={scrollToSection} features={features} contact={contact} testimonial={testimonial} howItWorks={howItWorks} home={home} />
      <div className="mt-[150px]" ref={home}>
        {/* BIG SCREENS FROM 1280PX */}
        <div className=" 100:hidden  xl:flex w-full  h-[600px] justify-center items-center ">
          <div className="w-[95%] 3xl:max-w-[1400px] gap-[53px]  flex justify-between items-center">
            <div className=" w-[45%] 3xl:w-[40%] flex flex-col justify-between gap-[30px]">
              <h1 className=" text-[#333] leading-[75px] text-[50px] font-raleway tracking-[0.9px] font-semibold">
                Tap, Pay, Done - <span className="text-[#3498DB]">KOLeT:</span> Your Seamless Payment Companion
              </h1>
              <p className="text-[24px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[45px] ">Experience Effortless Transactions and Ultimate Convenience with KOLeT Tap and Pay. Your Wallet, Redefined.</p>
              <div className="w-[480px] flex justify-between items-center ">
                <Link onClick={() => window.scrollTo(0, 0)} to="/signup">
                  <button className="w-[230px] h-[44px] rounded-[4px] text-[18px] text-[#333] font-raleway border-[1px] tracking-[0.9px] border-solid border-[#3498DB] font-semibold ">Get Started</button>
                </Link>
                <button className="w-[230px] h-[44px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Download For Free</button>
              </div>
            </div>
            <img src={homepage} alt="KOLeT" />
          </div>
        </div>
        {/* SMALL SCREENS FROM 100PX TO 1279PX */}
        <div className=" 100:flex xl:hidden w-full mt-[60px] justify-center items-center ">
          <div className="flex max-w-[85%] md:w-[600px] gap-[30px] flex-col items-center text-center justify-between">
            <h1 className=" text-[#333] leading-[50px]  text-[32px] font-raleway tracking-[0.9px] font-semibold">
              Tap, Pay, Done - <span className="text-[#3498DB]">KOLeT:</span> Your Seamless Payment Companion
            </h1>
            <p className="text-[15px] text-[#999] font-montserrat font-normal tracking-[0.9px] leading-[30px] ">Experience Effortless Transactions and Ultimate Convenience with KOLeT Tap and Pay. Your Wallet, Redefined.</p>
            <div className=" w-[360px] flex justify-between items-center ">
              <Link onClick={() => window.scrollTo(0, 0)} to="/signup">
                <button className="w-[170px] h-[44px] rounded-[4px] text-[14px] text-[#333] font-raleway border-[1px] tracking-[0.9px] border-solid border-[#3498DB] font-semibold ">Get Started</button>
              </Link>
              <Link to="">
                <button className="w-[170px] h-[44px] rounded-[4px] text-[14px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Download For Free</button>
              </Link>
            </div>
            <img src={homepage} alt="KOLeT" />
          </div>
        </div>
      </div>
      <div ref={features}>
        <Features />
      </div>
      <div ref={howItWorks}>
        <HowItWorks />
      </div>

      <ComingSoon />
      <div ref={testimonial}>
        <Testimonial />
      </div>
      <Download />
      <Security />
      <div ref={contact}>
        <Contact />
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
};

export default HomePage;
