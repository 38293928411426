import { Link } from "react-router-dom";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import logo from "../images/KOLET LOGO.png";
import TermsOfAgreement from "../Policy&Terms/TermsOfAgreement";
import PrivacyPolicy from "../Policy&Terms/PrivacyPolicy";

export const SignUp = () => {
  const [border, setBorder] = useState(false);
  const [border2, setBorder2] = useState(false);
  const [border3, setBorder3] = useState(false);
  const [border4, setBorder4] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [terms, setTerms] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [hamburger, setHamburger] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [page, setPage] = useState(1);
  const [otp, setOtpBox] = useState("");
  const [otpBox2, setOtpBox2] = useState("");
  const [otpBox3, setOtpBox3] = useState("");
  const [otpBox4, setOtpBox4] = useState("");
  const [page1Fields, setPage1Fields] = useState({});
  const [page3Fields, setPage3Fields] = useState({});
  const [navBar, setNavBar] = useState(false);
  const [value, setValue] = useState("");

  function handleScrollBackground() {
    if (window.scrollY >= 20) {
      setNavBar(true);
    } else {
      setNavBar(false);
    }
  }
  window.addEventListener("scroll", handleScrollBackground);

  const handleFieldChange = (page, fieldName, value) => {
    // const inputValue = e.target.value.replace(/\D/g, "");
    // if (inputValue.length <= 1) {
    //   setOtp(inputValue);
    // }

    switch (page) {
      case 1:
        setPage1Fields({ ...page1Fields, [fieldName]: value });

        break;
      case 2:
        setOtpBox(value);
        break;
      case 3:
        setOtpBox2(value);
        break;
      case 4:
        setOtpBox3(value);
        break;
      case 5:
        setOtpBox4(value);
        break;
      case 6:
        setPage3Fields({ ...page3Fields, [fieldName]: value });
        break;
      default:
        break;
    }
  };

  const isPageCompleted = (page) => {
    switch (page) {
      case 1:
        return Object.keys(page1Fields).length === 5 && Object.values(page1Fields).every(Boolean);
      case 2:
        return String(otp).length === 1;
      case 3:
        return String(otpBox2).length === 1;
      case 4:
        return String(otpBox3).length === 1;
      case 5:
        return String(otpBox4).length === 1;
      case 6:
        return Object.keys(page3Fields).length === 2 && Object.values(page3Fields).every(Boolean);
      default:
        return false;
    }
  };
  // console.log(isPageCompleted(1));
  // Object.values(page3Fields).every(Boolean);
  // const handleInputChange2 = (e) => {
  //   const inputValue = e.target.value.replace(/\D/g, "");

  //   if (inputValue.length <= 1) {
  //     setOtpBox2(inputValue);
  //   }
  // };
  // const handleInputChange3 = (e) => {
  //   const inputValue = e.target.value.replace(/\D/g, "");

  //   if (inputValue.length <= 1) {
  //     setOtpBox3(inputValue);
  //   }
  // };
  // const handleInputChange4 = (e) => {
  //   const inputValue = e.target.value.replace(/\D/g, "");

  //   if (inputValue.length <= 1) {
  //     setOtpBox4(inputValue);
  //   }
  // };
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const openTerms = () => {
    setTerms(true);
  };
  const closeTerms = () => {
    setTerms(false);
  };

  const handleSvgClick = () => {
    setIsOpen(true);
    setBorder(false);
    setBorder2(false);
    setBorder3(false);
    setBorder4(false);
  };
  return (
    <>
      {/* BIG SCREENS FROM 1280PX */}
      <div className=" 100:hidden xl:flex h-fit pb-[50px] w-full px-[50px]  flex-col flex  items-center ">
        <div className={` fixed px-[50px] w-full items-center flex justify-between ${navBar ? "bg-[#fff] bg-opacity-40" : " bg-transparent"} h-[84.15px]`}>
          {page === 1 && (
            <>
              <Link onClick={() => window.scrollTo(0, 0)} to="*">
                <img src={logo} alt="Kolet" />
              </Link>
              <Link onClick={() => window.scrollTo(0, 0)} to="*">
                <h3 className="text-[18px] font-raleway font-semibold text-[#333] -tracking-tight">Home</h3>
              </Link>
              <Link onClick={() => window.scrollTo(0, 0)} to="/login">
                <div className="w-[163px] h-[38px] border-solid border-[1px] rounded-[4px] justify-center items-center inline-flex border-[#3498DB] text-[18px] text-[#333] tracking-tight text-center font-raleway font-semibold ">Sign In</div>
              </Link>
            </>
          )}
          {page !== 1 && (
            <>
              <Link
                onClick={() => {
                  setPage(page - 1);
                  window.scrollTo(0, 0);
                }}
              >
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.3335 17H23.6668" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M14.5001 21.1666C14.5001 21.1666 10.3335 18.0979 10.3335 16.9999C10.3335 15.9019 14.5002 12.8333 14.5002 12.8333" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </Link>
            </>
          )}
        </div>
        <div className=" w-[424px] h-fit mt-[200px] flex flex-col p-[30px] gap-[40px] justify-between items-center rounded-[20px] custom-box-shadow  ">
          {/* Card 1 */}
          {page === 1 && (
            <>
              <div className=" flex flex-col items-center text-center justify-between gap-[10px] w-[328px]">
                <h3 className="text-[25px] font-semibold tracking-[0.9px] text-[#333] font-raleway ">
                  Sign Up for <span className="text-[#3498DB]">KOLeT</span>
                </h3>
                <p className="text-[15px] text-[#777] tracking-[0.9px] font-montserrat font-normal leading-[25px] ">Unlock the future of convenient transactions with Kolet. Sign up now!</p>
              </div>
              <form className="flex flex-col  gap-[20px]">
                {/* Box 1 */}
                <div
                  onClick={() => {
                    setBorder(true);
                    setBorder2(false);
                    setBorder3(false);
                    setBorder4(false);
                    setIsOpen(false);
                  }}
                  className={
                    border
                      ? " flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[1px] border-[#3498DB] "
                      : "flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] "
                  }
                >
                  <input onChange={(e) => handleFieldChange(page, "firstName", e.target.value)} className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="text" placeholder="First Name" />
                  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.14448 7.74152C3.4511 8.15438 1.63311 8.9974 2.74039 10.0523C3.28129 10.5676 3.88371 10.9362 4.6411 10.9362H8.9629C9.7203 10.9362 10.3227 10.5676 10.8636 10.0523C11.9709 8.9974 10.1529 8.15438 9.45952 7.74152C7.83357 6.77333 5.77044 6.77333 4.14448 7.74152Z"
                      stroke="#999999"
                      stroke-width="0.735148"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.00757 3.33972C9.00757 4.55776 8.02017 5.54517 6.80213 5.54517C5.58409 5.54517 4.59668 4.55776 4.59668 3.33972C4.59668 2.12169 5.58409 1.13428 6.80213 1.13428C8.02017 1.13428 9.00757 2.12169 9.00757 3.33972Z"
                      stroke="#999999"
                      stroke-width="0.735148"
                    />
                  </svg>
                </div>
                {/* Box 2 */}
                <div
                  onClick={() => {
                    setBorder2(true);
                    setBorder(false);
                    setBorder3(false);
                    setBorder4(false);
                    setIsOpen(false);
                  }}
                  className={
                    border2
                      ? " flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[1px] border-[#3498DB] "
                      : "flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] "
                  }
                >
                  <input onChange={(e) => handleFieldChange(page, "lastName", e.target.value)} className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="text" placeholder="Last Name" />
                  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.14448 7.74152C3.4511 8.15438 1.63311 8.9974 2.74039 10.0523C3.28129 10.5676 3.88371 10.9362 4.6411 10.9362H8.9629C9.7203 10.9362 10.3227 10.5676 10.8636 10.0523C11.9709 8.9974 10.1529 8.15438 9.45952 7.74152C7.83357 6.77333 5.77044 6.77333 4.14448 7.74152Z"
                      stroke="#999999"
                      strokeWidth="0.735148"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.00757 3.33972C9.00757 4.55776 8.02017 5.54517 6.80213 5.54517C5.58409 5.54517 4.59668 4.55776 4.59668 3.33972C4.59668 2.12169 5.58409 1.13428 6.80213 1.13428C8.02017 1.13428 9.00757 2.12169 9.00757 3.33972Z"
                      stroke="#999999"
                      strokeWidth="0.735148"
                    />
                  </svg>
                </div>
                {/* Box 3 */}
                <div
                  onClick={() => {
                    setBorder3(true);
                    setBorder2(false);
                    setBorder(false);
                    setBorder4(false);
                    setIsOpen(false);
                  }}
                  className={
                    border3
                      ? " flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[1px] border-[#3498DB] "
                      : "flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] "
                  }
                >
                  <input
                    onChange={(e) => {
                      handleFieldChange(page, "phoneNumber", e.target.value);
                      const numericValue = e.target.value.replace(/[^0-9]/g, "");
                      setValue(numericValue);
                    }}
                    value={value}
                    className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] "
                    type="tel"
                    placeholder="Phone Number"
                  />
                  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2.68799 5.02981C2.68799 3.41257 2.68799 2.60395 3.1904 2.10154C3.69281 1.59912 4.50144 1.59912 6.11868 1.59912C7.73591 1.59912 8.54452 1.59912 9.04697 2.10154C9.54937 2.60395 9.54937 3.41257 9.54937 5.02981V7.97041C9.54937 9.58764 9.54937 10.3963 9.04697 10.8987C8.54452 11.4011 7.73591 11.4011 6.11868 11.4011C4.50144 11.4011 3.69281 11.4011 3.1904 10.8987C2.68799 10.3963 2.68799 9.58764 2.68799 7.97041V5.02981Z"
                      stroke="#999999"
                      strokeWidth="0.735148"
                      strokeLinecap="round"
                    />
                    <path d="M5.62866 9.93066H6.60886" stroke="#999999" strokeWidth="0.735148" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                      d="M4.64844 1.59912L4.69206 1.86084C4.78659 2.42802 4.83385 2.71161 5.02836 2.88418C5.23126 3.06419 5.5189 3.06942 6.11873 3.06942C6.71857 3.06942 7.00621 3.06419 7.20911 2.88418C7.40363 2.71161 7.45087 2.42802 7.54541 1.86084L7.58903 1.59912"
                      stroke="#999999"
                      strokeWidth="0.735148"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* Box 4 */}
                <div
                  onClick={() => {
                    setBorder4(true);
                    setBorder2(false);
                    setBorder(false);
                    setBorder3(false);
                    setIsOpen(false);
                  }}
                  className={
                    border4
                      ? " flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[1px] border-[#3498DB] "
                      : "flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] "
                  }
                >
                  <input onChange={(e) => handleFieldChange(page, "email", e.target.value)} className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="email" placeholder="Email (optional)" />
                  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.90088 3.09473L5.28894 5.01443C6.538 5.72215 7.06574 5.72215 8.3148 5.01443L11.7029 3.09473" stroke="#999999" stroke-width="0.735148" stroke-linejoin="round" />
                    <path
                      d="M1.90861 6.75842C1.94065 8.26086 1.95667 9.01204 2.51103 9.56855C3.06539 10.125 3.83693 10.1444 5.38001 10.1831C6.33103 10.207 7.27271 10.207 8.22374 10.1831C9.76682 10.1444 10.5383 10.125 11.0927 9.56855C11.6471 9.01204 11.6631 8.26086 11.6951 6.75842C11.7055 6.27533 11.7055 5.79513 11.6951 5.31204C11.6631 3.80962 11.6471 3.05841 11.0927 2.50194C10.5383 1.94546 9.76682 1.92608 8.22374 1.88731C7.27271 1.86341 6.33103 1.86341 5.38 1.8873C3.83693 1.92607 3.06539 1.94545 2.51103 2.50193C1.95666 3.0584 1.94065 3.80961 1.9086 5.31204C1.8983 5.79513 1.89831 6.27533 1.90861 6.75842Z"
                      stroke="#999999"
                      strokeWidth="0.735148"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* Box 5 */}
                <div
                  onClick={handleSvgClick}
                  className={
                    isOpen
                      ? " flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[1px] border-[#3498DB] "
                      : "flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] "
                  }
                >
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date, e) => {
                      setSelectedDate(date);
                      setIsOpen(false);
                      handleFieldChange(page, "date", date);
                    }}
                    className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] "
                    placeholderText="Date of Birth"
                    dateFormat="MM-dd-yyyy"
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={20}
                    open={isOpen}
                  />{" "}
                  <svg onClick={handleSvgClick} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.31209 6.52539H8.76259M4.8418 6.52539H4.8462M7.29229 8.48579H4.8418M8.76259 8.48579H8.75818" stroke="#999999" stroke-width="0.735148" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9.74252 1.13428V2.11448M3.86133 1.13428V2.11448" stroke="#999999" stroke-width="0.735148" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M2.146 6.15435C2.146 4.01886 2.146 2.9511 2.75966 2.28769C3.37332 1.62427 4.36099 1.62427 6.33634 1.62427H7.26753C9.24287 1.62427 10.2306 1.62427 10.8442 2.28769C11.4579 2.9511 11.4579 4.01886 11.4579 6.15435V6.40607C11.4579 8.54157 11.4579 9.6093 10.8442 10.2728C10.2306 10.9361 9.24287 10.9361 7.26753 10.9361H6.33634C4.36099 10.9361 3.37332 10.9361 2.75966 10.2728C2.146 9.6093 2.146 8.54157 2.146 6.40607V6.15435Z"
                      stroke="#999999"
                      stroke-width="0.735148"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path d="M2.39111 4.07495H11.2129" stroke="#999999" stroke-width="0.735148" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </form>
            </>
          )}
          {/* Card 2 */}
          {page === 2 && (
            <>
              <div className=" flex flex-col items-center text-center justify-between gap-[10px] w-[308px]">
                <h3 className="text-[25px] font-semibold tracking-[0.9px] text-[#333] font-raleway ">Verify Your Identity</h3>
                <p className="text-[15px] text-[#777] tracking-[0.9px] font-montserrat font-normal leading-[25px] ">Enter the OTP sent to your registered number.</p>
              </div>
              <form className=" flex flex-col justify-between items-center gap-[10px]">
                <div className=" flex items-center gap-[10px] justify-between">
                  <input
                    className="h-[50px] outline-[1px] font-montserrat outline-[#3498DB] tracking-[0.9px] font-medium text-[24px] text-center text-[#999] px-[10px] items-center w-[50px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7]"
                    type="tel"
                    maxLength="1"
                    value={otp}
                    onChange={(e) => {
                      handleFieldChange(2, e.target.value);
                      const inputValue = e.target.value.replace(/\D/g, "");
                      if (inputValue.length <= 1) {
                        setOtpBox(inputValue);
                      }
                    }}
                  />
                  <input
                    className="h-[50px] outline-[1px] font-montserrat outline-[#3498DB] tracking-[0.9px] font-medium text-[24px] text-center text-[#999] px-[10px] items-center w-[50px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7]"
                    type="tel"
                    maxLength="1"
                    value={otpBox2}
                    onChange={(e) => {
                      handleFieldChange(3, e.target.value);
                      const inputValue = e.target.value.replace(/\D/g, "");
                      if (inputValue.length <= 1) {
                        setOtpBox2(inputValue);
                      }
                    }}
                  />{" "}
                  <input
                    className="h-[50px] outline-[1px] font-montserrat outline-[#3498DB] tracking-[0.9px] font-medium text-[24px] text-center text-[#999] px-[10px] items-center w-[50px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7]"
                    type="tel"
                    maxLength="1"
                    value={otpBox3}
                    onChange={(e) => {
                      handleFieldChange(4, e.target.value);
                      const inputValue = e.target.value.replace(/\D/g, "");
                      if (inputValue.length <= 1) {
                        setOtpBox3(inputValue);
                      }
                    }}
                  />{" "}
                  <input
                    className="h-[50px] outline-[1px] font-montserrat outline-[#3498DB] tracking-[0.9px] font-medium text-[24px] text-center text-[#999] px-[10px] items-center w-[50px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7]"
                    type="tel"
                    maxLength="1"
                    value={otpBox4}
                    onChange={(e) => {
                      handleFieldChange(5, e.target.value);
                      const inputValue = e.target.value.replace(/\D/g, "");
                      if (inputValue.length <= 1) {
                        setOtpBox4(inputValue);
                      }
                    }}
                  />
                </div>
                <p className="text-[13px] text-[#333] font-normal font-montserrat">Remaining 00:30 Sec</p>
              </form>
            </>
          )}
          {/* Card 3 */}
          {page === 3 && (
            <>
              <div className=" flex flex-col items-center text-center justify-between gap-[10px] w-[268px]">
                <h3 className="text-[25px] font-semibold tracking-[0.9px] text-[#333] font-raleway ">Create Password</h3>
                <p className="text-[15px] text-[#777] tracking-[0.9px] font-montserrat font-normal leading-[25px] ">Secure your account with a strong password</p>
              </div>
              <form className="flex flex-col  gap-[20px]">
                <div className=" flex flex-col gap-[10px]">
                  <div
                    onClick={() => {
                      setBorder(true);
                      setBorder2(false);
                    }}
                    className={`flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[${border ? "1px" : "0.7px"}] border-[${border ? "#3498DB" : "#BDC3C7"}]  `}
                  >
                    <input
                      onChange={(e) => handleFieldChange(6, "createPassword", e.target.value)}
                      className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] "
                      type={showPassword ? "text" : "password"}
                      placeholder="Create Password"
                    />
                    <span onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11.4796 5.56714C11.6286 5.77607 11.7031 5.88056 11.7031 6.03519C11.7031 6.18981 11.6286 6.2943 11.4796 6.50323C10.8101 7.44206 9.10029 9.46588 6.80211 9.46588C4.50393 9.46588 2.79413 7.44206 2.12463 6.50323C1.97562 6.2943 1.90112 6.18981 1.90112 6.03519C1.90112 5.88056 1.97562 5.77607 2.12463 5.56714C2.79413 4.62833 4.50393 2.60449 6.80211 2.60449C9.10029 2.60449 10.8101 4.62833 11.4796 5.56714Z"
                            stroke="#999999"
                            strokeWidth="0.735148"
                          />
                          <path
                            d="M8.27238 6.03524C8.27238 5.22319 7.61413 4.56494 6.80208 4.56494C5.99004 4.56494 5.33179 5.22319 5.33179 6.03524C5.33179 6.84728 5.99004 7.50554 6.80208 7.50554C7.61413 7.50554 8.27238 6.84728 8.27238 6.03524Z"
                            stroke="#999999"
                            strokeWidth="0.735148"
                          />
                        </svg>
                      ) : (
                        <svg width="13" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.78 3.91992C10.78 3.91992 8.81998 6.85992 5.87998 6.85992C2.93998 6.85992 0.97998 3.91992 0.97998 3.91992" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" />
                          <path d="M7.3501 6.61499L8.0851 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9.80005 5.38989L10.78 6.36989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M0.97998 6.36989L1.95998 5.38989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M4.41005 6.61499L3.67505 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      )}
                    </span>
                  </div>
                  <div className="w-[324px] leading-[17px] font-montserrat font-normal text-[10px] tracking-[0.9px] text-[#999]  ">Password should be at least 8 characters long and include a mix of letters, numbers, and symbols.</div>
                </div>
                <div
                  onClick={() => {
                    setBorder2(true);
                    setBorder(false);
                  }}
                  className={`flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[${border2 ? "1px" : "0.7px"}] border-[${border2 ? "#3498DB" : "#BDC3C7"}]  `}
                >
                  <input
                    onChange={(e) => handleFieldChange(6, "confirmPassword", e.target.value)}
                    className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] "
                    type={showPassword2 ? "text" : "password"}
                    placeholder="Confirm Password"
                  />
                  <span onClick={() => setShowPassword2(!showPassword2)}>
                    {showPassword2 ? (
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.4796 5.56714C11.6286 5.77607 11.7031 5.88056 11.7031 6.03519C11.7031 6.18981 11.6286 6.2943 11.4796 6.50323C10.8101 7.44206 9.10029 9.46588 6.80211 9.46588C4.50393 9.46588 2.79413 7.44206 2.12463 6.50323C1.97562 6.2943 1.90112 6.18981 1.90112 6.03519C1.90112 5.88056 1.97562 5.77607 2.12463 5.56714C2.79413 4.62833 4.50393 2.60449 6.80211 2.60449C9.10029 2.60449 10.8101 4.62833 11.4796 5.56714Z"
                          stroke="#999999"
                          strokeWidth="0.735148"
                        />
                        <path
                          d="M8.27238 6.03524C8.27238 5.22319 7.61413 4.56494 6.80208 4.56494C5.99004 4.56494 5.33179 5.22319 5.33179 6.03524C5.33179 6.84728 5.99004 7.50554 6.80208 7.50554C7.61413 7.50554 8.27238 6.84728 8.27238 6.03524Z"
                          stroke="#999999"
                          strokeWidth="0.735148"
                        />
                      </svg>
                    ) : (
                      <svg width="13" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.78 3.91992C10.78 3.91992 8.81998 6.85992 5.87998 6.85992C2.93998 6.85992 0.97998 3.91992 0.97998 3.91992" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" />
                        <path d="M7.3501 6.61499L8.0851 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9.80005 5.38989L10.78 6.36989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M0.97998 6.36989L1.95998 5.38989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.41005 6.61499L3.67505 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    )}
                  </span>
                </div>
              </form>
            </>
          )}
          {/* Progress Bar */}
          <div className="gap-[8px] flex justify-between items-center ">
            <div className={`w-[50px] h-[7px]  rounded-[3px] ${isPageCompleted(1) ? "bg-[#3498DB] bg-opacity-40" : "bg-[#fff]"} `}></div>
            <div className={`w-[50px] h-[7px]  rounded-[3px] ${isPageCompleted(5) ? "bg-[#3498DB] bg-opacity-40" : "bg-[#fff]"} `}></div>
            <div className={`w-[50px] h-[7px]  rounded-[3px] ${isPageCompleted(6) ? "bg-[#3498DB] bg-opacity-40" : "bg-[#fff]"} `}></div>
          </div>

          <div className="flex flex-col  text-center w-[302px] justify-between items-center gap-[10px] ">
            {page === 1 && (
              <>
                <h3 className="text-[14px] leading-[24px] font-raleway font-medium text-[#333] tracking-[0.9px] ">
                  By signing up, you agree to our{" "}
                  <span
                    onClick={() => {
                      openTerms();
                      closeModal();
                    }}
                    className="text-[#3498DB] cursor-pointer"
                  >
                    Terms and Conditions
                  </span>{" "}
                  and{" "}
                  <span
                    onClick={() => {
                      openModal();
                      closeTerms();
                    }}
                    className="text-[#3498DB] cursor-pointer"
                  >
                    Privacy Policy
                  </span>{" "}
                </h3>
              </>
            )}
            {page === 2 && (
              <>
                <h3 className="text-[14px] leading-[24px] font-raleway font-medium text-[#333] tracking-[0.9px] ">
                  Didn't receive the code? Tap to resend it to your registered{" "}
                  <Link
                    onClick={() => {
                      setPage(page - 1);
                    }}
                    className="text-[#3498DB] cursor-pointer"
                  >
                    Number
                  </Link>{" "}
                  or{" "}
                  <Link
                    onClick={() => {
                      setPage(page - 1);
                    }}
                    className="text-[#3498DB] cursor-pointer"
                  >
                    Email.
                  </Link>{" "}
                </h3>
              </>
            )}
            {page !== 3 && (
              <Link
                onClick={() => {
                  setPage(page + 1);
                }}
                // to="/signupcreatepassword"
              >
                <button className="w-[243px] h-[38px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Next</button>
              </Link>
            )}
            {page === 3 && (
              <>
                <div className="flex flex-col  text-center w-[342px] justify-between items-center gap-[10px] ">
                  <h3 className="text-[14px] leading-[24px] font-raleway font-medium text-[#333] tracking-[0.9px] ">
                    By signing up, you agree to our{" "}
                    <span
                      onClick={() => {
                        openTerms();
                        closeModal();
                      }}
                      className="text-[#3498DB] cursor-pointer"
                    >
                      Terms and Conditions
                    </span>{" "}
                    and{" "}
                    <span
                      onClick={() => {
                        openModal();
                        closeTerms();
                      }}
                      className="text-[#3498DB] cursor-pointer"
                    >
                      Privacy Policy
                    </span>{" "}
                  </h3>

                  <Link to="/login">
                    <button className="w-[243px] h-[38px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Create Account</button>
                  </Link>
                </div>
              </>
            )}
            {page === 1 && (
              <h3 className=" font-raleway font-semibold text-[16px] text-[#333] tracking-[0.9px] ">
                Already a member?{" "}
                <Link className="text-[#2980B9]" to="/login">
                  Login
                </Link>
              </h3>
            )}
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1279PX */}
      <div className="100:flex w-full h-fit gap-[100px] flex-col px-[40px] py-[20px] xl:hidden justify-center items-center ">
        <div className={`top-0  fixed w-full items-center px-[40px] flex justify-between ${navBar ? "bg-[#fff] bg-opacity-40" : " bg-transparent"} h-[84.15px]`}>
          {page === 1 && (
            <>
              <Link to="*">
                <img src={logo} alt="Kolet" />
              </Link>
              <svg onClick={() => setHamburger(true)} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 12.1709H10" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20 5.1709H4" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20 19.1709H4" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </>
          )}
          {page !== 1 && (
            <>
              <Link onClick={() => setPage(page - 1)}>
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.3335 17H23.6668" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M14.5001 21.1666C14.5001 21.1666 10.3335 18.0979 10.3335 16.9999C10.3335 15.9019 14.5002 12.8333 14.5002 12.8333" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </Link>
            </>
          )}
        </div>
        <div className=" w-[95%] sm:w-[424px] mt-[150px] h-fit flex flex-col p-[30px] gap-[40px] justify-between items-center rounded-[20px] custom-box-shadow  ">
          {/* Card 1 */}
          {page === 1 && (
            <>
              <div className=" flex flex-col items-center text-center justify-between gap-[10px] w-full">
                <h3 className="text-[25px] font-semibold tracking-[0.9px] text-[#333] font-raleway ">
                  Sign Up for <span className="text-[#3498DB]">KOLeT</span>
                </h3>
                <p className="text-[15px] text-[#777] tracking-[0.9px] font-montserrat font-normal leading-[25px] ">Unlock the future of convenient transactions with Kolet. Sign up now!</p>
              </div>
              <form className="flex flex-col w-full  gap-[20px]">
                <div
                  onClick={() => {
                    setBorder(true);
                    setBorder2(false);
                    setBorder3(false);
                    setBorder4(false);
                    setIsOpen(false);
                  }}
                  className={
                    border
                      ? " flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[1px] border-[#3498DB] "
                      : "flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] "
                  }
                >
                  <input onChange={(e) => handleFieldChange(page, "firstName", e.target.value)} className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="text" placeholder="First Name" />
                  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.14448 7.74152C3.4511 8.15438 1.63311 8.9974 2.74039 10.0523C3.28129 10.5676 3.88371 10.9362 4.6411 10.9362H8.9629C9.7203 10.9362 10.3227 10.5676 10.8636 10.0523C11.9709 8.9974 10.1529 8.15438 9.45952 7.74152C7.83357 6.77333 5.77044 6.77333 4.14448 7.74152Z"
                      stroke="#999999"
                      stroke-width="0.735148"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.00757 3.33972C9.00757 4.55776 8.02017 5.54517 6.80213 5.54517C5.58409 5.54517 4.59668 4.55776 4.59668 3.33972C4.59668 2.12169 5.58409 1.13428 6.80213 1.13428C8.02017 1.13428 9.00757 2.12169 9.00757 3.33972Z"
                      stroke="#999999"
                      stroke-width="0.735148"
                    />
                  </svg>
                </div>
                <div
                  onClick={() => {
                    setBorder2(true);
                    setBorder(false);
                    setBorder3(false);
                    setBorder4(false);
                    setIsOpen(false);
                  }}
                  className={
                    border2
                      ? " flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[1px] border-[#3498DB] "
                      : "flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] "
                  }
                >
                  <input onChange={(e) => handleFieldChange(page, "lastName", e.target.value)} className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="text" placeholder="Last Name" />
                  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.14448 7.74152C3.4511 8.15438 1.63311 8.9974 2.74039 10.0523C3.28129 10.5676 3.88371 10.9362 4.6411 10.9362H8.9629C9.7203 10.9362 10.3227 10.5676 10.8636 10.0523C11.9709 8.9974 10.1529 8.15438 9.45952 7.74152C7.83357 6.77333 5.77044 6.77333 4.14448 7.74152Z"
                      stroke="#999999"
                      stroke-width="0.735148"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.00757 3.33972C9.00757 4.55776 8.02017 5.54517 6.80213 5.54517C5.58409 5.54517 4.59668 4.55776 4.59668 3.33972C4.59668 2.12169 5.58409 1.13428 6.80213 1.13428C8.02017 1.13428 9.00757 2.12169 9.00757 3.33972Z"
                      stroke="#999999"
                      stroke-width="0.735148"
                    />
                  </svg>
                </div>
                <div
                  onClick={() => {
                    setBorder3(true);
                    setBorder2(false);
                    setBorder(false);
                    setBorder4(false);
                    setIsOpen(false);
                  }}
                  className={
                    border3
                      ? " flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[1px] border-[#3498DB] "
                      : "flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] "
                  }
                >
                  <input onChange={(e) => handleFieldChange(page, "phoneNumber", e.target.value)} className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="tel" placeholder="Phone Number" />
                  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2.68799 5.02981C2.68799 3.41257 2.68799 2.60395 3.1904 2.10154C3.69281 1.59912 4.50144 1.59912 6.11868 1.59912C7.73591 1.59912 8.54452 1.59912 9.04697 2.10154C9.54937 2.60395 9.54937 3.41257 9.54937 5.02981V7.97041C9.54937 9.58764 9.54937 10.3963 9.04697 10.8987C8.54452 11.4011 7.73591 11.4011 6.11868 11.4011C4.50144 11.4011 3.69281 11.4011 3.1904 10.8987C2.68799 10.3963 2.68799 9.58764 2.68799 7.97041V5.02981Z"
                      stroke="#999999"
                      strokeWidth="0.735148"
                      strokeLinecap="round"
                    />
                    <path d="M5.62866 9.93066H6.60886" stroke="#999999" strokeWidth="0.735148" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                      d="M4.64844 1.59912L4.69206 1.86084C4.78659 2.42802 4.83385 2.71161 5.02836 2.88418C5.23126 3.06419 5.5189 3.06942 6.11873 3.06942C6.71857 3.06942 7.00621 3.06419 7.20911 2.88418C7.40363 2.71161 7.45087 2.42802 7.54541 1.86084L7.58903 1.59912"
                      stroke="#999999"
                      strokeWidth="0.735148"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div
                  onClick={() => {
                    setBorder4(true);
                    setBorder2(false);
                    setBorder(false);
                    setBorder3(false);
                    setIsOpen(false);
                  }}
                  className={
                    border4
                      ? " flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[1px] border-[#3498DB] "
                      : "flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] "
                  }
                >
                  <input onChange={(e) => handleFieldChange(page, "email", e.target.value)} className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type="email" placeholder="Email (optional)" />
                  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.90088 3.09473L5.28894 5.01443C6.538 5.72215 7.06574 5.72215 8.3148 5.01443L11.7029 3.09473" stroke="#999999" stroke-width="0.735148" stroke-linejoin="round" />
                    <path
                      d="M1.90861 6.75842C1.94065 8.26086 1.95667 9.01204 2.51103 9.56855C3.06539 10.125 3.83693 10.1444 5.38001 10.1831C6.33103 10.207 7.27271 10.207 8.22374 10.1831C9.76682 10.1444 10.5383 10.125 11.0927 9.56855C11.6471 9.01204 11.6631 8.26086 11.6951 6.75842C11.7055 6.27533 11.7055 5.79513 11.6951 5.31204C11.6631 3.80962 11.6471 3.05841 11.0927 2.50194C10.5383 1.94546 9.76682 1.92608 8.22374 1.88731C7.27271 1.86341 6.33103 1.86341 5.38 1.8873C3.83693 1.92607 3.06539 1.94545 2.51103 2.50193C1.95666 3.0584 1.94065 3.80961 1.9086 5.31204C1.8983 5.79513 1.89831 6.27533 1.90861 6.75842Z"
                      stroke="#999999"
                      stroke-width="0.735148"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div
                  onClick={handleSvgClick}
                  className={
                    isOpen
                      ? " flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[1px] border-[#3498DB] "
                      : "flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7] "
                  }
                >
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => {
                      setSelectedDate(date);
                      setIsOpen(false);
                      handleFieldChange(page, "date", date);
                    }}
                    className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] "
                    placeholderText="Date of Birth"
                    dateFormat="MM-dd-yyyy"
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={20}
                    open={isOpen}
                  />{" "}
                  <svg onClick={handleSvgClick} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.31209 6.52539H8.76259M4.8418 6.52539H4.8462M7.29229 8.48579H4.8418M8.76259 8.48579H8.75818" stroke="#999999" stroke-width="0.735148" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9.74252 1.13428V2.11448M3.86133 1.13428V2.11448" stroke="#999999" stroke-width="0.735148" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M2.146 6.15435C2.146 4.01886 2.146 2.9511 2.75966 2.28769C3.37332 1.62427 4.36099 1.62427 6.33634 1.62427H7.26753C9.24287 1.62427 10.2306 1.62427 10.8442 2.28769C11.4579 2.9511 11.4579 4.01886 11.4579 6.15435V6.40607C11.4579 8.54157 11.4579 9.6093 10.8442 10.2728C10.2306 10.9361 9.24287 10.9361 7.26753 10.9361H6.33634C4.36099 10.9361 3.37332 10.9361 2.75966 10.2728C2.146 9.6093 2.146 8.54157 2.146 6.40607V6.15435Z"
                      stroke="#999999"
                      stroke-width="0.735148"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path d="M2.39111 4.07495H11.2129" stroke="#999999" stroke-width="0.735148" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </form>
            </>
          )}
          {/* Card 2 */}
          {page === 2 && (
            <>
              <div className=" flex flex-col items-center text-center justify-between gap-[10px] w-[308px]">
                <h3 className="text-[25px] font-semibold tracking-[0.9px] text-[#333] font-raleway ">Verify Your Identity</h3>
                <p className="text-[15px] text-[#777] tracking-[0.9px] font-montserrat font-normal leading-[25px] ">Enter the OTP sent to your registered number.</p>
              </div>
              <form className=" flex flex-col justify-between items-center gap-[10px]">
                <div className=" flex items-center gap-[10px] justify-between">
                  <input
                    className="h-[50px] outline-[1px] font-montserrat outline-[#3498DB] tracking-[0.9px] font-medium text-[24px] text-center text-[#999] px-[10px] items-center w-[50px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7]"
                    type="tel"
                    maxLength="1"
                    value={otp}
                    onChange={(e) => {
                      handleFieldChange(2, e.target.value);
                      const inputValue = e.target.value.replace(/\D/g, "");
                      if (inputValue.length <= 1) {
                        setOtpBox(inputValue);
                      }
                    }}
                  />
                  <input
                    className="h-[50px] outline-[1px] font-montserrat outline-[#3498DB] tracking-[0.9px] font-medium text-[24px] text-center text-[#999] px-[10px] items-center w-[50px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7]"
                    type="tel"
                    maxLength="1"
                    value={otpBox2}
                    onChange={(e) => {
                      handleFieldChange(3, e.target.value);
                      const inputValue = e.target.value.replace(/\D/g, "");
                      if (inputValue.length <= 1) {
                        setOtpBox2(inputValue);
                      }
                    }}
                  />{" "}
                  <input
                    className="h-[50px] outline-[1px] font-montserrat outline-[#3498DB] tracking-[0.9px] font-medium text-[24px] text-center text-[#999] px-[10px] items-center w-[50px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7]"
                    type="tel"
                    maxLength="1"
                    value={otpBox3}
                    onChange={(e) => {
                      handleFieldChange(4, e.target.value);
                      const inputValue = e.target.value.replace(/\D/g, "");
                      if (inputValue.length <= 1) {
                        setOtpBox3(inputValue);
                      }
                    }}
                  />{" "}
                  <input
                    className="h-[50px] outline-[1px] font-montserrat outline-[#3498DB] tracking-[0.9px] font-medium text-[24px] text-center text-[#999] px-[10px] items-center w-[50px] rounded-[4px] bg-[#fff] border-solid border-[0.7px] border-[#BDC3C7]"
                    type="tel"
                    maxLength="1"
                    value={otpBox4}
                    onChange={(e) => {
                      handleFieldChange(5, e.target.value);
                      const inputValue = e.target.value.replace(/\D/g, "");
                      if (inputValue.length <= 1) {
                        setOtpBox4(inputValue);
                      }
                    }}
                  />
                </div>
                <p className="text-[13px] text-[#333] font-normal font-montserrat">Remaining 00:30 Sec</p>
              </form>
            </>
          )}
          {/* Card 3 */}
          {page === 3 && (
            <>
              <div className=" flex flex-col items-center text-center justify-between gap-[10px] w-[268px]">
                <h3 className="text-[25px] font-semibold tracking-[0.9px] text-[#333] font-raleway ">Create Password</h3>
                <p className="text-[15px] text-[#777] tracking-[0.9px] font-montserrat font-normal leading-[25px] ">Secure your account with a strong password</p>
              </div>
              <form className="flex flex-col w-full  gap-[20px]">
                <div className=" flex flex-col gap-[10px]">
                  <div
                    onClick={() => {
                      setBorder(true);
                      setBorder2(false);
                    }}
                    className={`flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[${border ? "1px" : "0.7px"}] border-[${border ? "#3498DB" : "#BDC3C7"}]  `}
                  >
                    <input
                      onChange={(e) => handleFieldChange(6, "createPassword", e.target.value)}
                      className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] "
                      type={showPassword ? "text" : "password"}
                      placeholder="Create Password"
                    />
                    <span onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11.4796 5.56714C11.6286 5.77607 11.7031 5.88056 11.7031 6.03519C11.7031 6.18981 11.6286 6.2943 11.4796 6.50323C10.8101 7.44206 9.10029 9.46588 6.80211 9.46588C4.50393 9.46588 2.79413 7.44206 2.12463 6.50323C1.97562 6.2943 1.90112 6.18981 1.90112 6.03519C1.90112 5.88056 1.97562 5.77607 2.12463 5.56714C2.79413 4.62833 4.50393 2.60449 6.80211 2.60449C9.10029 2.60449 10.8101 4.62833 11.4796 5.56714Z"
                            stroke="#999999"
                            strokeWidth="0.735148"
                          />
                          <path
                            d="M8.27238 6.03524C8.27238 5.22319 7.61413 4.56494 6.80208 4.56494C5.99004 4.56494 5.33179 5.22319 5.33179 6.03524C5.33179 6.84728 5.99004 7.50554 6.80208 7.50554C7.61413 7.50554 8.27238 6.84728 8.27238 6.03524Z"
                            stroke="#999999"
                            strokeWidth="0.735148"
                          />
                        </svg>
                      ) : (
                        <svg width="13" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.78 3.91992C10.78 3.91992 8.81998 6.85992 5.87998 6.85992C2.93998 6.85992 0.97998 3.91992 0.97998 3.91992" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" />
                          <path d="M7.3501 6.61499L8.0851 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9.80005 5.38989L10.78 6.36989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M0.97998 6.36989L1.95998 5.38989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M4.41005 6.61499L3.67505 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      )}
                    </span>
                  </div>
                  <div className="w-full leading-[17px] font-montserrat font-normal text-[10px] tracking-[0.9px] text-[#999]  ">Password should be at least 8 characters long and include a mix of letters, numbers, and symbols.</div>
                </div>
                <div
                  onClick={() => {
                    setBorder2(true);
                    setBorder(false);
                  }}
                  className={`flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[${border2 ? "1px" : "0.7px"}] border-[${border2 ? "#3498DB" : "#BDC3C7"}]  `}
                >
                  <input
                    onChange={(e) => handleFieldChange(6, "confirmPassword", e.target.value)}
                    className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] "
                    type={showPassword2 ? "text" : "password"}
                    placeholder="Confirm Password"
                  />
                  <span onClick={() => setShowPassword2(!showPassword2)}>
                    {showPassword2 ? (
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.4796 5.56714C11.6286 5.77607 11.7031 5.88056 11.7031 6.03519C11.7031 6.18981 11.6286 6.2943 11.4796 6.50323C10.8101 7.44206 9.10029 9.46588 6.80211 9.46588C4.50393 9.46588 2.79413 7.44206 2.12463 6.50323C1.97562 6.2943 1.90112 6.18981 1.90112 6.03519C1.90112 5.88056 1.97562 5.77607 2.12463 5.56714C2.79413 4.62833 4.50393 2.60449 6.80211 2.60449C9.10029 2.60449 10.8101 4.62833 11.4796 5.56714Z"
                          stroke="#999999"
                          strokeWidth="0.735148"
                        />
                        <path
                          d="M8.27238 6.03524C8.27238 5.22319 7.61413 4.56494 6.80208 4.56494C5.99004 4.56494 5.33179 5.22319 5.33179 6.03524C5.33179 6.84728 5.99004 7.50554 6.80208 7.50554C7.61413 7.50554 8.27238 6.84728 8.27238 6.03524Z"
                          stroke="#999999"
                          strokeWidth="0.735148"
                        />
                      </svg>
                    ) : (
                      <svg width="13" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.78 3.91992C10.78 3.91992 8.81998 6.85992 5.87998 6.85992C2.93998 6.85992 0.97998 3.91992 0.97998 3.91992" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" />
                        <path d="M7.3501 6.61499L8.0851 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9.80005 5.38989L10.78 6.36989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M0.97998 6.36989L1.95998 5.38989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.41005 6.61499L3.67505 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    )}
                  </span>
                </div>
              </form>
            </>
          )}
          {/* Progress Bar */}
          <div className="gap-[8px] flex justify-between items-center ">
            <div className={`w-[50px] h-[7px]  rounded-[3px] ${isPageCompleted(1) ? "bg-[#3498DB] bg-opacity-40" : "bg-[#fff]"} `}></div>
            <div className={`w-[50px] h-[7px]  rounded-[3px] ${isPageCompleted(5) ? "bg-[#3498DB] bg-opacity-40" : "bg-[#fff]"} `}></div>
            <div className={`w-[50px] h-[7px]  rounded-[3px] ${isPageCompleted(6) ? "bg-[#3498DB] bg-opacity-40" : "bg-[#fff]"} `}></div>
          </div>
          <div className="flex flex-col  text-center w-full justify-between items-center gap-[10px] ">
            {page === 1 && (
              <>
                <h3 className="text-[14px] leading-[24px] font-raleway font-medium text-[#333] tracking-[0.9px] ">
                  By signing up, you agree to our{" "}
                  <span
                    onClick={() => {
                      openTerms();
                      closeModal();
                    }}
                    className="text-[#3498DB] cursor-pointer"
                  >
                    Terms and Conditions
                  </span>{" "}
                  and{" "}
                  <span
                    onClick={() => {
                      openModal();
                      closeTerms();
                    }}
                    className="text-[#3498DB] cursor-pointer"
                  >
                    Privacy Policy
                  </span>{" "}
                </h3>
              </>
            )}
            {page === 2 && (
              <>
                <h3 className="text-[14px] leading-[24px] font-raleway font-medium text-[#333] tracking-[0.9px] ">
                  Didn't receive the code? Tap to resend it to your registered{" "}
                  <Link
                    onClick={() => {
                      setPage(page - 1);
                    }}
                    className="text-[#3498DB] cursor-pointer"
                  >
                    Number
                  </Link>{" "}
                  or{" "}
                  <Link
                    onClick={() => {
                      setPage(page - 1);
                    }}
                    className="text-[#3498DB] cursor-pointer"
                  >
                    Email.
                  </Link>{" "}
                </h3>
              </>
            )}
            {page !== 3 && (
              <Link
                onClick={() => {
                  setPage(page + 1);
                }}
                // to="/signupcreatepassword"
              >
                <button className="w-[200px] h-[38px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Next</button>
              </Link>
            )}
            {page === 3 && (
              <>
                <h3 className="text-[14px] leading-[24px] font-raleway font-medium text-[#333] tracking-[0.9px] ">
                  By signing up, you agree to our{" "}
                  <span
                    onClick={() => {
                      openTerms();
                      closeModal();
                    }}
                    className="text-[#3498DB] cursor-pointer"
                  >
                    Terms and Conditions
                  </span>{" "}
                  and{" "}
                  <span
                    onClick={() => {
                      openModal();
                      closeTerms();
                    }}
                    className="text-[#3498DB] cursor-pointer"
                  >
                    Privacy Policy
                  </span>{" "}
                </h3>

                <Link to="/login">
                  <button className="w-[200px] h-[38px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Create Account</button>
                </Link>
              </>
            )}
            {page === 1 && (
              <h3 className=" font-raleway font-semibold text-[16px] text-[#333] tracking-[0.9px] ">
                Already a member?{" "}
                <Link className="text-[#2980B9]" to="/login">
                  Login
                </Link>
              </h3>
            )}
          </div>
        </div>
      </div>
      {hamburger && (
        <div className="bg-[#000] top-0 left-0 flex justify-between    fixed bg-opacity-65 w-full h-[100%] ">
          <div className=" bg-[#ECF7FF]   pt-[40px] flex  gap-[50px] items-center flex-col w-[230px] h-full">
            <img className="w-[45px]" src={logo} alt="Kolet" />

            <Link to="*">
              <h3 className="text-[18px] font-raleway font-semibold text-[#333] -tracking-tight">Home</h3>
            </Link>
            <Link to="/login">
              <div className="w-[163px] h-[38px] border-solid border-[1px] rounded-[4px] justify-center items-center inline-flex border-[#3498DB] text-[18px] text-[#333] tracking-tight text-center font-raleway font-semibold ">Sign In</div>
            </Link>
          </div>
          <div onClick={() => setHamburger(false)} className=" cursor-pointer  flex justify-center items-center w-[60px] absolute right-[20px] top-[30px] h-[60px] bg-[#494B48] rounded-[80px]">
            <svg className=" " width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 5L5 19M5 5L19 19" stroke="#F5F5F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
        </div>
      )}
      {terms && <TermsOfAgreement closeTerms={closeTerms} />}
      {isModalOpen && <PrivacyPolicy closeModal={closeModal} />}
    </>
  );
};
