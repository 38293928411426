import { useState } from "react";
import { Link } from "react-router-dom";
const CreatePasswordForgotPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [border, setBorder] = useState(false);
  const [border2, setBorder2] = useState(false);

  return (
    <>
      {/* BIG SCREENS FROM 1280PX */}
      <div className=" 100:hidden xl:flex w-full px-[50px] gap-[100px] flex-col flex justify-center items-center ">
        <div className=" w-full items-center flex justify-between h-[84.15px]">
          <Link to="/otp">
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.3335 17H23.6668" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14.5001 21.1666C14.5001 21.1666 10.3335 18.0979 10.3335 16.9999C10.3335 15.9019 14.5002 12.8333 14.5002 12.8333" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Link>
        </div>
        <div className=" w-[424px] h-fit flex flex-col p-[30px] gap-[40px] justify-between items-center rounded-[20px] custom-box-shadow  ">
          <div className=" flex flex-col items-center text-center justify-between gap-[10px] w-[268px]">
            <h3 className="text-[25px] font-semibold tracking-[0.9px] text-[#333] font-raleway ">Create Password</h3>
            <p className="text-[15px] text-[#777] tracking-[0.9px] font-montserrat font-normal leading-[25px] ">Secure your account with a strong password</p>
          </div>
          <form className="flex flex-col  gap-[20px]">
            <div className=" flex flex-col gap-[10px]">
              <div
                onClick={() => {
                  setBorder(true);
                  setBorder2(false);
                }}
                className={`flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[${border ? "1px" : "0.7px"}] border-[${border ? "#3498DB" : "#BDC3C7"}]  `}
              >
                <input className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type={showPassword ? "text" : "password"} placeholder="Create Password" />
                <span onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.4796 5.56714C11.6286 5.77607 11.7031 5.88056 11.7031 6.03519C11.7031 6.18981 11.6286 6.2943 11.4796 6.50323C10.8101 7.44206 9.10029 9.46588 6.80211 9.46588C4.50393 9.46588 2.79413 7.44206 2.12463 6.50323C1.97562 6.2943 1.90112 6.18981 1.90112 6.03519C1.90112 5.88056 1.97562 5.77607 2.12463 5.56714C2.79413 4.62833 4.50393 2.60449 6.80211 2.60449C9.10029 2.60449 10.8101 4.62833 11.4796 5.56714Z"
                        stroke="#999999"
                        strokeWidth="0.735148"
                      />
                      <path
                        d="M8.27238 6.03524C8.27238 5.22319 7.61413 4.56494 6.80208 4.56494C5.99004 4.56494 5.33179 5.22319 5.33179 6.03524C5.33179 6.84728 5.99004 7.50554 6.80208 7.50554C7.61413 7.50554 8.27238 6.84728 8.27238 6.03524Z"
                        stroke="#999999"
                        strokeWidth="0.735148"
                      />
                    </svg>
                  ) : (
                    <svg width="13" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.78 3.91992C10.78 3.91992 8.81998 6.85992 5.87998 6.85992C2.93998 6.85992 0.97998 3.91992 0.97998 3.91992" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" />
                      <path d="M7.3501 6.61499L8.0851 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9.80005 5.38989L10.78 6.36989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M0.97998 6.36989L1.95998 5.38989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4.41005 6.61499L3.67505 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  )}
                </span>
              </div>
              <div className="w-[324px] leading-[17px] font-montserrat font-normal text-[10px] tracking-[0.9px] text-[#999]  ">Password should be at least 8 characters long and include a mix of letters, numbers, and symbols.</div>
            </div>
            <div
              onClick={() => {
                setBorder2(true);
                setBorder(false);
              }}
              className={`flex justify-between h-[43px] px-[16px] items-center w-[324px] rounded-[4px] bg-[#fff] border-solid border-[${border2 ? "1px" : "0.7px"}] border-[${border2 ? "#3498DB" : "#BDC3C7"}]  `}
            >
              <input className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type={showPassword2 ? "text" : "password"} placeholder="Confirm Password" />
              <span onClick={() => setShowPassword2(!showPassword2)}>
                {showPassword2 ? (
                  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.4796 5.56714C11.6286 5.77607 11.7031 5.88056 11.7031 6.03519C11.7031 6.18981 11.6286 6.2943 11.4796 6.50323C10.8101 7.44206 9.10029 9.46588 6.80211 9.46588C4.50393 9.46588 2.79413 7.44206 2.12463 6.50323C1.97562 6.2943 1.90112 6.18981 1.90112 6.03519C1.90112 5.88056 1.97562 5.77607 2.12463 5.56714C2.79413 4.62833 4.50393 2.60449 6.80211 2.60449C9.10029 2.60449 10.8101 4.62833 11.4796 5.56714Z"
                      stroke="#999999"
                      strokeWidth="0.735148"
                    />
                    <path
                      d="M8.27238 6.03524C8.27238 5.22319 7.61413 4.56494 6.80208 4.56494C5.99004 4.56494 5.33179 5.22319 5.33179 6.03524C5.33179 6.84728 5.99004 7.50554 6.80208 7.50554C7.61413 7.50554 8.27238 6.84728 8.27238 6.03524Z"
                      stroke="#999999"
                      strokeWidth="0.735148"
                    />
                  </svg>
                ) : (
                  <svg width="13" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.78 3.91992C10.78 3.91992 8.81998 6.85992 5.87998 6.85992C2.93998 6.85992 0.97998 3.91992 0.97998 3.91992" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" />
                    <path d="M7.3501 6.61499L8.0851 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.80005 5.38989L10.78 6.36989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.97998 6.36989L1.95998 5.38989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.41005 6.61499L3.67505 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                )}
              </span>
            </div>
          </form>

          <div className="flex flex-col  text-center w-[342px] justify-between items-center gap-[10px] ">
            <Link to="/login">
              <button className="w-[243px] h-[38px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Submit</button>
            </Link>
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1279PX */}
      <div className="100:flex w-full h-fit gap-[100px] flex-col px-[50px] pb-[40px] xl:hidden justify-center items-center ">
        <div className=" w-full items-center flex justify-between h-[84.15px]">
          <Link to="/otp">
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.3335 17H23.6668" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14.5001 21.1666C14.5001 21.1666 10.3335 18.0979 10.3335 16.9999C10.3335 15.9019 14.5002 12.8333 14.5002 12.8333" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Link>
        </div>
        <div className=" w-[90%] sm:w-[424px] h-fit flex flex-col py-[40px] px-[30px] gap-[40px] justify-between items-center rounded-[20px] custom-box-shadow  ">
          <div className=" flex flex-col items-center text-center justify-between gap-[10px] w-[268px]">
            <h3 className="text-[25px] font-semibold tracking-[0.9px] text-[#333] font-raleway ">Create Password</h3>
            <p className="text-[15px] text-[#777] tracking-[0.9px] font-montserrat font-normal leading-[25px] ">Secure your account with a strong password</p>
          </div>
          <form className="flex flex-col w-full  gap-[20px]">
            <div className=" flex flex-col gap-[10px]">
              <div
                onClick={() => {
                  setBorder(true);
                  setBorder2(false);
                }}
                className={`flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[${border ? "1px" : "0.7px"}] border-[${border ? "#3498DB" : "#BDC3C7"}]  `}
              >
                <input className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type={showPassword ? "text" : "password"} placeholder="Create Password" />
                <span onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.4796 5.56714C11.6286 5.77607 11.7031 5.88056 11.7031 6.03519C11.7031 6.18981 11.6286 6.2943 11.4796 6.50323C10.8101 7.44206 9.10029 9.46588 6.80211 9.46588C4.50393 9.46588 2.79413 7.44206 2.12463 6.50323C1.97562 6.2943 1.90112 6.18981 1.90112 6.03519C1.90112 5.88056 1.97562 5.77607 2.12463 5.56714C2.79413 4.62833 4.50393 2.60449 6.80211 2.60449C9.10029 2.60449 10.8101 4.62833 11.4796 5.56714Z"
                        stroke="#999999"
                        strokeWidth="0.735148"
                      />
                      <path
                        d="M8.27238 6.03524C8.27238 5.22319 7.61413 4.56494 6.80208 4.56494C5.99004 4.56494 5.33179 5.22319 5.33179 6.03524C5.33179 6.84728 5.99004 7.50554 6.80208 7.50554C7.61413 7.50554 8.27238 6.84728 8.27238 6.03524Z"
                        stroke="#999999"
                        strokeWidth="0.735148"
                      />
                    </svg>
                  ) : (
                    <svg width="13" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.78 3.91992C10.78 3.91992 8.81998 6.85992 5.87998 6.85992C2.93998 6.85992 0.97998 3.91992 0.97998 3.91992" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" />
                      <path d="M7.3501 6.61499L8.0851 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9.80005 5.38989L10.78 6.36989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M0.97998 6.36989L1.95998 5.38989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4.41005 6.61499L3.67505 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  )}
                </span>
              </div>
              <div className="w-full leading-[17px] font-montserrat font-normal text-[10px] tracking-[0.9px] text-[#999]  ">Password should be at least 8 characters long and include a mix of letters, numbers, and symbols.</div>
            </div>
            <div
              onClick={() => {
                setBorder2(true);
                setBorder(false);
              }}
              className={`flex justify-between h-[43px] px-[16px] items-center w-full rounded-[4px] bg-[#fff] border-solid border-[${border2 ? "1px" : "0.7px"}] border-[${border2 ? "#3498DB" : "#BDC3C7"}]  `}
            >
              <input className=" font-montserrat outline-none w-full tracking-[0.9px] font-normal text-[14px] text-[#999] " type={showPassword2 ? "text" : "password"} placeholder="Confirm Password" />
              <span onClick={() => setShowPassword2(!showPassword2)}>
                {showPassword2 ? (
                  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.4796 5.56714C11.6286 5.77607 11.7031 5.88056 11.7031 6.03519C11.7031 6.18981 11.6286 6.2943 11.4796 6.50323C10.8101 7.44206 9.10029 9.46588 6.80211 9.46588C4.50393 9.46588 2.79413 7.44206 2.12463 6.50323C1.97562 6.2943 1.90112 6.18981 1.90112 6.03519C1.90112 5.88056 1.97562 5.77607 2.12463 5.56714C2.79413 4.62833 4.50393 2.60449 6.80211 2.60449C9.10029 2.60449 10.8101 4.62833 11.4796 5.56714Z"
                      stroke="#999999"
                      strokeWidth="0.735148"
                    />
                    <path
                      d="M8.27238 6.03524C8.27238 5.22319 7.61413 4.56494 6.80208 4.56494C5.99004 4.56494 5.33179 5.22319 5.33179 6.03524C5.33179 6.84728 5.99004 7.50554 6.80208 7.50554C7.61413 7.50554 8.27238 6.84728 8.27238 6.03524Z"
                      stroke="#999999"
                      strokeWidth="0.735148"
                    />
                  </svg>
                ) : (
                  <svg width="13" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.78 3.91992C10.78 3.91992 8.81998 6.85992 5.87998 6.85992C2.93998 6.85992 0.97998 3.91992 0.97998 3.91992" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" />
                    <path d="M7.3501 6.61499L8.0851 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.80005 5.38989L10.78 6.36989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.97998 6.36989L1.95998 5.38989" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.41005 6.61499L3.67505 7.83999" stroke="#999999" strokeWidth="0.74" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                )}
              </span>
            </div>
          </form>

          <div className="flex flex-col  text-center w-full justify-between items-center gap-[10px] ">
            <Link to="/login">
              <button className="w-[200px] h-[38px] rounded-[4px] text-[18px] text-[#fff] font-raleway  bg-[#3498DB] tracking-[0.9px]  font-semibold ">Submit</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePasswordForgotPassword;
